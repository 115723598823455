import { useGroupsStore } from "@entities/groups";
import { Arrow, Dots } from "@shared/ui/assets";
import { AsideDropdown, LayoutWithAside } from "@widgets/admin";
import { Button, Divider, MenuProps, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./groupPage.scss";
import { ButtonStyled } from "@shared/ui/components";
import { FilterItem } from "@features/filterItem";
import { IFilterItem, ROUTES } from "@shared/interfaces";

import { getMenuPropsItems } from "@shared/common";
import { GroupItems } from "@widgets/admin/group";
import { GroupDeletePopup } from "@widgets/admin/group/ui/GroupDeletePopup";
import { GroupEditPopup } from "@widgets/admin/group/ui/GroupEditPopup";
import { GroupApplyNewPricesPopup } from "@widgets/admin/group/ui/GroupApplyNewPricesPopup";

const tabOptions = [
  {
    label: <h4 className="tab-title-page">Overview</h4>,
    key: "overview",
    disabled: true,
    children: <></>,
  },
  {
    label: <h4 className="tab-title-page">Items</h4>,
    key: "items",
    disabled: false,
    children: <GroupItems />,
  },
  {
    label: <h4 className="tab-title-page">Configuration</h4>,
    key: "configuration",
    disabled: true,
    children: <></>,
  },
];
type MenuItem = Required<MenuProps>["items"][number];

const GroupPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const groupName = useGroupsStore((state) => state.currentGroup.name);
  const { getGroup, clearGroup, clearStrategyInStore, setCompetitorsInGroup } =
    useGroupsStore((state) => state);

  const [isDeleteGroupPopupShow, setDeleteGroupPopupShow] =
    useState<boolean>(false);
  const [isEditGroupPopupShow, setEditGroupPopupShow] =
    useState<boolean>(false);
  const [isApplyNewPricesPopupShow, setApplyNewPricesPopupShow] =
    useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getGroup(id);
    }
  }, []);

  const itemsTopMenu: MenuItem[] = [
    getMenuPropsItems(
      "Rename group",
      "rename",
      null,
      undefined,
      false,
      false,
      () => setEditGroupPopupShow(true)
    ),
    getMenuPropsItems("Export as CSV", "export", null, undefined, true),
    getMenuPropsItems(
      "Delete group",
      "delete",
      null,
      undefined,
      false,
      true,
      () => setDeleteGroupPopupShow(true)
    ),
  ];

  const handleClick = () => {
    navigate(ROUTES.ADMIN_GROUPS);
    clearGroup();
    clearStrategyInStore();
    setCompetitorsInGroup([]);
  };

  const onCloseDeletePopupHandler = (flag: boolean) => {
    setDeleteGroupPopupShow(flag);
  };

  const handleOpenPopupApplyNewPrices = () => {
    setApplyNewPricesPopupShow(true);
  };

  const onOkApplyNewPrices = () => {
    alert("onOkApplyNewPrices");
    setApplyNewPricesPopupShow(false);
  };

  return (
    <>
      <GroupDeletePopup
        flag={isDeleteGroupPopupShow}
        onClose={onCloseDeletePopupHandler}
      />
      <GroupEditPopup
        flag={isEditGroupPopupShow}
        onClose={setEditGroupPopupShow}
      />
      <GroupApplyNewPricesPopup
        flag={isApplyNewPricesPopupShow}
        onClose={() => setApplyNewPricesPopupShow(false)}
        onOk={() => onOkApplyNewPrices()}
      />

      <LayoutWithAside>
        <div className="group-page">
          <div className="df ai-center jc-sb group-page-title">
            <div className="">
              <div className="back_to_container" onClick={handleClick}>
                <Arrow />
                <h5 className="">Back to Groups</h5>
              </div>
              <h1 className="">{groupName}</h1>
            </div>

            <div className="edit-title__actions">
              <ButtonStyled
                type="primary"
                text="Apply new prices (5)"
                htmlType="button"
                fill="gray-primary-900"
                className={`groups-empty__btn w-188 mr-16`}
                onClick={() => handleOpenPopupApplyNewPrices()}
                iconPosition="end"
              />

              <AsideDropdown
                collapsed={true}
                items={itemsTopMenu}
                placement="bottomRight"
                className="relu__menu"
              >
                <div className="dots-title__btn">
                  <Dots color="#212529" />
                </div>
              </AsideDropdown>
            </div>
          </div>
          <Divider />

          <div className="group-page-tabs">
            <Tabs
              defaultActiveKey="1"
              items={tabOptions.map(({ label, key, children }, ind) => {
                return {
                  disabled: true,
                  label: label,
                  key: ind.toString(),
                  children: children,
                };
              })}
            />
          </div>
        </div>
      </LayoutWithAside>
    </>
  );
};

export default GroupPage;
