import ReactDOM from "react-dom/client";
import "@shared/ui/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyleProvider } from "@ant-design/cssinjs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StyleProvider hashPriority="low">
    <App />
  </StyleProvider>
);
reportWebVitals();
