import './notificationItem.scss';
import { INotification } from "@entities/user";
import { FC } from "react";
import { Button, Typography, Divider, Badge } from "antd";
import moment from 'moment';
import { ButtonStyled } from '@shared/ui/components';

const { Title } = Typography;

interface IProps {
  notification: INotification,
  isLast: boolean;
}

const NotificationItem: FC<IProps> = ({ notification, isLast }: IProps) => {
  return (
    <>
      <div className="notification-item__container">
        <div className='notification-item__badge'>
          {!notification.isReaded && <Badge color={'red'}/>}
        </div>
        <div className='notification-item__content'>
          <div>
            <Title level={5} className='notification-item__text'>{notification.content}</Title>
            <Title level={5} className='notification-item__time'>{moment(notification.created_at).fromNow()}</Title>
          </div>
          <ButtonStyled
            type="text"
            text="Mark as read"
            className="notification-item__btn disabled"
            htmlType="button"
          />
        </div>
      </div>
      {!isLast &&  <Divider />}
    </>
    
  )
}

export default NotificationItem;