import "./checkboxStyled.scss";

import { FC } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
  text: string;
  className?: string;
  fill?: string;
  onChange: (event: CheckboxChangeEvent) => void;
  checked?: boolean;
}

const CheckboxStyled: FC<IProps> = ({
  text,
  className,
  fill,
  onChange,
  checked = false,
}: IProps) => {
  let classes = ["custom-checkbox"];

  if (fill === "gray-primary-900") {
    classes.push("gray-primary-900");
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      className={`${classes.join(" ")} ${className ? className : ""}`}
    >
      {text}
    </Checkbox>
  );
};

export default CheckboxStyled;
