import { LayoutWithAside } from "@widgets/admin";
import { Typography } from "antd";

const { Title } = Typography;

export const KnowledgePage = () => {
  return (
    <LayoutWithAside>
      <Title className="content__title pl-24 pt-30 pb-30" level={1}>
        Knowledge Base
      </Title>
    </LayoutWithAside>
  );
};

export default KnowledgePage;
