import "./signupForm.scss";
import { FC } from "react";

import { useForm } from "@shared/model";
import { IFormEmailState } from "@shared/interfaces";
import { ButtonStyled } from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";
import { Input } from "antd";

const user: IFormEmailState = {
  email: "",
};

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

const SignupForm: FC<IProps> = ({ onContinueClick }) => {
  const handlerBeforeSubmitForm = () => {
    onContinueClick({ type: SIGNUP_STEPS.CHECK_EMAIL });
  };

  const { formData, onChangeInput, onSubmitForm, resetFormData } =
    useForm<IFormEmailState>(user, handlerBeforeSubmitForm);

  return (
    <div>
      <form onSubmit={onSubmitForm} className="signup-form_wrapper">
        <div className="form__item">
          <label className="" htmlFor={"email"}>
            Email
          </label>

          <Input
            id="email"
            className="gray-primary-900 form-item__mb24"
            name="email"
            onChange={onChangeInput}
            type="email"
            value={formData.email}
          />
        </div>

        <ButtonStyled
          text="Continue"
          className=""
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />
      </form>
    </div>
  );
};

export default SignupForm;
