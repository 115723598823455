import "./auth-wrapper.scss";

interface IProps {
  children: React.ReactElement;
}

const AuthWrapper = ({ children }: IProps) => {
  return <div className="auth-wrapper__container">{children}</div>;
};

export default AuthWrapper;
