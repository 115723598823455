import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IItemInfoState, Period } from "./itemInfo.types";
import itemInfoService from "../api";

export const useItemInfoStore = create<IItemInfoState>()(
  devtools(
    immer((set, get) => ({
      itemCompetitors: [],
      datesOfGraphCompetitors: [],

      getItemCompetitors: async (productId: string) => {
        try {
          const { data } = await itemInfoService.getItemCompetitors(productId);

          set({
            itemCompetitors: data,
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      changeMonitoringStatus: async (id: number, monitoringStatus: boolean) => {
        try {
          await itemInfoService.changeMonitoringStatus(id, monitoringStatus);

          const newData = get().itemCompetitors.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                monitoringStatus,
              };
            }
            return item;
          });

          set({
            itemCompetitors: newData,
          });
        } catch (e) {
          console.log(e);
        }
      },
      getDatesOfGraphCompetitors: async (
        key: string,
        productId: string,
        competitorsIds: any[]
      ) => {
        const { data } = await itemInfoService.getDatesOfGraphCompetitors(
          key,
          productId,
          competitorsIds
        );
        set({
          datesOfGraphCompetitors: data,
        });
      },
      clearDatesOfGraphCompetitors: () => {
        set({
          datesOfGraphCompetitors: [],
        });
      },
    }))
  )
);
