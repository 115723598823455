export const cutStringToLimit = (string: string, limit: number = 50) => {
  const latestLetters = 4;

  if (string.length > limit) {
    const trimmedString = string.substring(0, limit - latestLetters);

    return `${trimmedString} ...`;
  }

  return string;
};
