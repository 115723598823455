import { FC } from "react";
import { Divider, Modal } from "antd";
import { Typography } from "antd";
import "./groupsEditPopup.scss";
import { GroupsEditForm } from "../../GroupsEditForm";

const { Title } = Typography;

interface IProps {
  flag: boolean;
  onClose: (flag: boolean) => void;
}

const GroupsEditPopup: FC<IProps> = ({ flag, onClose }: IProps) => {
  return (
    <Modal
      open={flag}
      onOk={() => {}}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      onCancel={() => onClose(false)}
    >
      <div className="groups__form-create">
        <h2 className="groups__form-title">Rename Group</h2>
        <Divider />
        <div className="groups__form-content">
          <GroupsEditForm onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default GroupsEditPopup;
