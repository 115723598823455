import { ApiService } from "@shared/utils";
import { url } from "@shared/utils/url.utils";
import { IDataTransfer, IQuery } from "@shared/interfaces";

class ProductService extends ApiService {
  productsURL: string;

  constructor() {
    super();

    this.productsURL = "/api/v1/products";
  }

  async getProducts(queryParams: IDataTransfer) {
    let params = url.generateQueryString(queryParams);

    const currentUrl = `${this.productsURL}?${params}`;

    return await this.baseServiceInstance.get(currentUrl);
  }

  async deleteProductsGroup(groupId: number, products: string[]) {
    const currentUrl = `${this.productsURL}/groups/${groupId}/delete`;

    const body = { products };

    return await this.baseServiceInstance.patch(currentUrl, body);
  }
}

const productsService = new ProductService();

export default productsService;
