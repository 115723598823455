interface IProps {
  color?: string;
}

const CheckOval = ({ color= '#198754' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M10.6156 13.8L8.46563 11.65C8.28229 11.4667 8.04896 11.375 7.76562 11.375C7.48229 11.375 7.24896 11.4667 7.06563 11.65C6.88229 11.8333 6.79063 12.0667 6.79063 12.35C6.79063 12.6333 6.88229 12.8667 7.06563 13.05L9.91563 15.9C10.1156 16.1 10.349 16.2 10.6156 16.2C10.8823 16.2 11.1156 16.1 11.3156 15.9L16.9656 10.25C17.149 10.0667 17.2406 9.83333 17.2406 9.55C17.2406 9.26667 17.149 9.03333 16.9656 8.85C16.7823 8.66667 16.549 8.575 16.2656 8.575C15.9823 8.575 15.749 8.66667 15.5656 8.85L10.6156 13.8ZM12.0156 22C10.6323 22 9.33229 21.7375 8.11562 21.2125C6.89896 20.6875 5.84063 19.975 4.94062 19.075C4.04062 18.175 3.32812 17.1167 2.80313 15.9C2.27812 14.6833 2.01562 13.3833 2.01562 12C2.01562 10.6167 2.27812 9.31667 2.80313 8.1C3.32812 6.88333 4.04062 5.825 4.94062 4.925C5.84063 4.025 6.89896 3.3125 8.11562 2.7875C9.33229 2.2625 10.6323 2 12.0156 2C13.399 2 14.699 2.2625 15.9156 2.7875C17.1323 3.3125 18.1906 4.025 19.0906 4.925C19.9906 5.825 20.7031 6.88333 21.2281 8.1C21.7531 9.31667 22.0156 10.6167 22.0156 12C22.0156 13.3833 21.7531 14.6833 21.2281 15.9C20.7031 17.1167 19.9906 18.175 19.0906 19.075C18.1906 19.975 17.1323 20.6875 15.9156 21.2125C14.699 21.7375 13.399 22 12.0156 22Z" fill={color}/>
  </svg>
);

export default CheckOval;