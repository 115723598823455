export const url = {
  getParams: (search: string, type: string): string | null => {
    const urlParams = new URLSearchParams(search);
    const myParam = urlParams.get(type);

    if (!myParam) {
      return null;
    }

    return myParam;
  },
  generateQueryString: (props: any) => {
    if (typeof props === "string" || props instanceof String) {
      return props.replace("?", "");
    }

    let queryString = "";
    let keysArray = [];

    if (Array.isArray(props)) {
      keysArray = props;
    } else {
      const keys = Object.keys(props);
      keys.forEach((key) => keysArray.push({ [key]: props[key] }));
    }

    keysArray.forEach((item, indx) => {
      const itemKey: any = Object.keys(item);

      queryString = queryString.concat(itemKey, "=", item[itemKey]);

      if (indx !== keysArray.length - 1) {
        queryString = queryString.concat("&");
      }
    });

    return queryString;
  },
  getSearchParams: (searchParams: any) => {
    return Object.fromEntries(searchParams.entries());
  },
};
