import {
  AlertType,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";

export interface IValidationExceptionItem {
  [key: string]: any;
  pricing?: boolean;
  rangeValidation?: boolean;
}

const useValidationStepper = () => {
  const strategy = useGroupsStore((state) => state.strategy);
  const { exceptionProducts } = useGroupsStore((state) => state);
  const {
    currentGroup: { name },
  } = useGroupsStore((state) => state);
  const {
    priceLimits: { max, min },
    priceRounding: { decimal, integer },
  } = strategy;

  const checkValidPriceLimitInputs = () => {
    const minValue =
      min?.active &&
      (min?.pricing === SelectLimitPricing.MARGIN ||
        min?.pricing === SelectLimitPricing.MSRP) &&
      min?.pricingValue;
    const maxValue =
      max?.active &&
      (max?.pricing === SelectLimitPricing.MARGIN ||
        max?.pricing === SelectLimitPricing.MSRP) &&
      max?.pricingValue;

    const isEqualPricing = max?.pricing === min?.pricing;

    if (minValue && maxValue && isEqualPricing) {
      return minValue >= maxValue;
    }
    return false;
  };

  const checkValidPriceLimitsMinInput = () => {
    return min?.active && min?.pricing && !min?.pricingValue;
  };

  const checkValidPriceLimitsMaxInput = () => {
    return max?.active && max?.pricing && !max?.pricingValue;
  };

  const checkValidPriceLimitsMinSelect = () => {
    return min?.active && !min?.pricing;
  };

  const checkValidPriceLimitsMaxSelect = () => {
    return max?.active && !max?.pricing;
  };

  const checkValidPriceLimitsExceptions = () => {
    return (exceptionProducts || [])?.reduce(
      (arrValidationExceptions: IValidationExceptionItem, el) => {
        const { pricing } = el.exceptionLimits || {};
        const { min, max } = el.exceptionLimits || {};

        const dataError = {
          pricing: !pricing,
          rangeValidation: !!pricing && !min?.value && !max?.value,
          id: el.id,
        };

        if (dataError.pricing || dataError.rangeValidation) {
          arrValidationExceptions.push(dataError);
        }

        return arrValidationExceptions;
      },
      []
    );
  };

  const checkValidPriceRoundingDecimalInput = () => {
    return decimal?.active && !decimal?.value;
  };
  const checkValidPriceRoundingIntegerInput = () => {
    return integer?.active && !integer?.value;
  };
  const checkValidNameSummaryInput = () => {
    return !name;
  };

  return {
    //       ===================  limits  ====================
    //input
    isErrorValidPriceLimitsMinInput: checkValidPriceLimitsMinInput(),
    isErrorValidPriceLimitsMaxInput: checkValidPriceLimitsMaxInput(),
    isErrorValidPriceLimitsInputs: checkValidPriceLimitInputs(),
    //select
    isErrorValidPriceLimitsMinSelect: checkValidPriceLimitsMinSelect(),
    isErrorValidPriceLimitsMaxSelect: checkValidPriceLimitsMaxSelect(),
    //exceptions
    arrValidationExceptions: checkValidPriceLimitsExceptions(),
    //       ===================  Rounding  ====================
    isErrorValidDecimalInput: checkValidPriceRoundingDecimalInput(),
    isErrorValidIntegerInput: checkValidPriceRoundingIntegerInput(),
    //       ===================  Summary  ====================
    isErrorValidNameSummaryInput: checkValidNameSummaryInput(),
  };
};

export default useValidationStepper;
