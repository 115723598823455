interface IProps {
  color?: string;
}

const Close = ({ color= '#6C757D' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M6 6L12 12M12 12L18 18M12 12L18 6M12 12L6 18" stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export default Close;