import { Close } from "@shared/ui/assets";
import { ButtonStyled, CheckboxStyled } from "@shared/ui/components";
import { Divider, Modal } from "antd";
import React, { FC } from "react";
import "./confirmMonitoringPopup.scss";

interface IProps {
  isOpenModal: boolean;
  onClose: () => void;
  competitorName: string;
  onOk: () => void;
}

const ConfirmMonitoringPopup: FC<IProps> = ({
  isOpenModal,
  onClose,
  competitorName,
  onOk,
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        width={500}
        open={isOpenModal}
        onOk={() => {}}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => onClose()}
      >
        <div>
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal-monitoring-title">
            Disable Competitor Price Monitoring
          </h2>
          <Divider />
        </div>
        <div className="modal__monitoring-content ">
          <div className="modal__monitoring-text">
            {`Disabling price monitoring for`} <b>{competitorName}</b>{" "}
            {`will update these
          sections within a few minutes: New price, Forecasts, Demand. Click
          'Proceed' to confirm or 'Cancel' to keep monitoring active.`}
          </div>
          <div>
            <CheckboxStyled
              onChange={() => {}}
              text="Auto-enable when competitor's item is available"
            />
          </div>
        </div>
        <Divider />
        <div className="modal__monitoring-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={() => onClose()}
            />

            <ButtonStyled
              text="Proceed"
              type="primary"
              fill="gray-primary-900"
              htmlType="button"
              className="w-84"
              onClick={() => onOk()}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmMonitoringPopup;
