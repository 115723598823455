import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

export const getMenuPropsItems = (
  label: React.ReactNode,
  key?: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  disabled?: boolean,
  danger?: boolean,
  onClick?: (event: any) => void
): MenuItem => {
  return {
    key,
    icon,
    label,
    children,
    disabled,
    danger,
    onClick,
  } as MenuItem;
};
