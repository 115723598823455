import React from "react";

const Error = ({ color = "#dc3545" }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0013 1.66602C5.4013 1.66602 1.66797 5.39935 1.66797 9.99935C1.66797 14.5993 5.4013 18.3327 10.0013 18.3327C14.6013 18.3327 18.3346 14.5993 18.3346 9.99935C18.3346 5.39935 14.6013 1.66602 10.0013 1.66602ZM10.0013 10.8327C9.54297 10.8327 9.16797 10.4577 9.16797 9.99935V6.66602C9.16797 6.20768 9.54297 5.83268 10.0013 5.83268C10.4596 5.83268 10.8346 6.20768 10.8346 6.66602V9.99935C10.8346 10.4577 10.4596 10.8327 10.0013 10.8327ZM10.8346 14.166H9.16797V12.4993H10.8346V14.166Z"
      fill={color}
    />
  </svg>
);

export default Error;
