import { Button } from "antd";
import { FC } from "react";
import "./buttonStyled.scss";

interface IProps {
  text: string;
  className?: string;
  fill?: string;
  type?: "primary" | "dashed" | "link" | "text" | "default";
  value?: any;
  onClick?: (payload: any) => void;
  htmlType?: "button" | "submit" | "reset" | undefined;
  icon?: React.ReactElement;
  iconPosition?: "start" | "end" | undefined;
  disabled?: boolean;
}

const ButtonStyled: FC<IProps> = ({
  text,
  type,
  fill = "",
  value,
  onClick,
  className,
  htmlType = "button",
  iconPosition,
  disabled,
  ...props
}: IProps) => {
  let classes = ["form_btn", fill];

  if (!type) {
    classes.push("default__btn");
  }

  const onButtonClick = () => {
    onClick && onClick(value);
  };

  return (
    <Button
      type={type}
      className={`${classes.join(" ")} ${className}`}
      onClick={onButtonClick}
      htmlType={htmlType}
      iconPosition={iconPosition}
      disabled={disabled}
      {...props}
    >
      {text}
    </Button>
  );
};

export default ButtonStyled;
