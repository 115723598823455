import { CurrentStep, PriceRoundingEnum, STEP_KEYS } from "@entities/groups";
import ReactDOMServer from "react-dom/server";

export const getItemWord = (count: number): string => {
  return count === 1 ? "item" : "items";
};

export const getCompetitorWord = (count: number): string => {
  return count === 1 ? "competitor" : "competitors";
};

export const filteredCompareQueryObj = (compareQueryObj: any) => {
  return Object.fromEntries(
    Object.entries(compareQueryObj).filter(
      ([key, value]) => value !== undefined && value !== null && value !== ""
    )
  );
};

interface IAjustPriceValue {
  decimalMain?: string | null;
  integerMain?: string | null;
  round?: PriceRoundingEnum;
  baseNum?: string;
}

export const adjustPriceValue = ({
  decimalMain = null,
  integerMain = null,
  round = PriceRoundingEnum.ROUND_DOWN,
  baseNum = "123.43",
}: IAjustPriceValue) => {
  const [integer, decimal] = baseNum.split(".");
  const isInteger = (num: any) => Number.isInteger(num);
  const roundedUp = (number: number) => Math.ceil(number / 10) * 10;
  const roundedDown = (number: number) => Math.round(number / 10) * 10;

  const chooseDecimal =
    decimalMain && decimalMain.length === 1
      ? `0${decimalMain}`
      : `${decimalMain}`;
  if (
    decimalMain &&
    isInteger(+decimalMain) &&
    integerMain &&
    isInteger(+integerMain)
  ) {
    // zeros
    if (
      (decimalMain === "0" || decimalMain === "00") &&
      integerMain === "0" &&
      round === PriceRoundingEnum.ROUND_DOWN
    ) {
      return `${roundedDown(+integer)}.00`;
    }

    if (
      (decimalMain === "0" || decimalMain === "00") &&
      integerMain === "0" &&
      round === PriceRoundingEnum.ROUND_UP
    ) {
      return `${roundedUp(+integer)}.00`;
    }

    // other numbs
    if (round === PriceRoundingEnum.ROUND_DOWN) {
      if (+integerMain < +integer[2]) {
        return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
      }
      if (+integerMain > +integer[2]) {
        return `${integer[0]}${+integer[1] - 1}${integerMain}.${chooseDecimal}`;
      }
    }

    if (round === PriceRoundingEnum.ROUND_UP) {
      if (+integerMain < +integer[2]) {
        return `${integer[0]}${+integer[1] + 1}${integerMain}.${chooseDecimal}`;
      }
      if (+integerMain > +integer[2]) {
        return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
      }
    }

    // isEqual

    if (integerMain === integer[2]) {
      if (round === PriceRoundingEnum.ROUND_DOWN) {
        if (+decimalMain > +decimal) {
          return `${integer[0]}${
            +integer[1] - 1
          }${integerMain}.${chooseDecimal}`;
        }
        if (+decimalMain < +decimal) {
          return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
        }
      }

      if (round === PriceRoundingEnum.ROUND_UP) {
        if (+decimalMain > +decimal) {
          return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
        }
        if (+decimalMain < +decimal) {
          return `${integer[0]}${
            +integer[1] + 1
          }${integerMain}.${chooseDecimal}`;
        }
      }
    }
  }

  if (integerMain && round === PriceRoundingEnum.ROUND_DOWN) {
    if (+integerMain < +integer[2]) {
      return `${integer[0]}${+integer[1]}${integerMain}.${decimal}`;
    }
    if (+integerMain > +integer[2]) {
      return `${integer[0]}${+integer[1] - 1}${integerMain}.${decimal}`;
    }
    if (integerMain === integer[2]) {
      return baseNum;
    }
  }

  if (integerMain && round === PriceRoundingEnum.ROUND_UP) {
    if (+integerMain < +integer[2]) {
      return `${integer[0]}${+integer[1] + 1}${integerMain}.${decimal}`;
    }
    if (+integerMain > +integer[2]) {
      return `${integer[0]}${integer[1]}${integerMain}.${decimal}`;
    }
    if (integerMain === integer[2]) {
      return baseNum;
    }
  }

  if (decimalMain && round === PriceRoundingEnum.ROUND_DOWN) {
    if (decimalMain < decimal) {
      return `${+integer}.${chooseDecimal}`;
    }
    if (decimalMain > decimal) {
      return `${+integer - 1}.${chooseDecimal}`;
    }
  }

  if (decimalMain && round === PriceRoundingEnum.ROUND_UP) {
    if (decimalMain < decimal) {
      return `${+integer + 1}.${chooseDecimal}`;
    }
    if (decimalMain > decimal) {
      return `${integer}.${chooseDecimal}`;
    }
  }
  return baseNum;
};

interface LoadingState {
  isLoading: boolean;
  error: string | null;
}

export async function loadStateWrapper<T>(
  set: any,
  asyncAction: Promise<T>
): Promise<T> {
  set({ isLoading: true, error: null });
  try {
    const res = await asyncAction;
    return res;
  } catch (error) {
    throw error;
  } finally {
    set({ isLoading: false });
  }
}

export const htmlToString = (html: React.ReactNode) => {
  return ReactDOMServer.renderToString(html);
};

export const sortAlertsToStrategy = (strategy: any) => {
  const modifiedStrategy: { [key: string]: any } = {};

  Object.keys(strategy).forEach((key: string) => {
    const currentKey = strategy[key];
    const alerts = currentKey?.alerts;

    if (!alerts) {
      modifiedStrategy[key] = currentKey;
    } else {
      modifiedStrategy[key] = {
        ...strategy[key],
        alerts: alerts.sort((a: any, b: any) => a.priority - b.priority),
      };
    }
  });
  return modifiedStrategy;
};

export const arrayToQueryParams = (arr: any[], key: string) => {
  return arr
    .map((brand: any) => `${key}=${encodeURIComponent(brand)}`)
    .join("&");
};
