interface IProps {
  color?: string;
}

const Refresh = ({ color= '#6C757D' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_8231_4928)">
    <path d="M10.0007 3.33344V1.84177C10.0007 1.46677 9.55065 1.28344 9.29232 1.55011L6.95898 3.87511C6.79232 4.04177 6.79232 4.30011 6.95898 4.46677L9.28398 6.79177C9.55065 7.05011 10.0007 6.86677 10.0007 6.49177V5.00011C12.759 5.00011 15.0007 7.24177 15.0007 10.0001C15.0007 10.6584 14.8757 11.3001 14.634 11.8751C14.509 12.1751 14.6007 12.5168 14.8257 12.7418C15.2507 13.1668 15.9673 13.0168 16.1923 12.4584C16.5007 11.7001 16.6673 10.8668 16.6673 10.0001C16.6673 6.31677 13.684 3.33344 10.0007 3.33344ZM10.0007 15.0001C7.24232 15.0001 5.00065 12.7584 5.00065 10.0001C5.00065 9.34177 5.12565 8.70011 5.36732 8.12511C5.49232 7.82511 5.40065 7.48344 5.17565 7.25844C4.75065 6.83344 4.03398 6.98344 3.80898 7.54177C3.50065 8.30011 3.33398 9.13344 3.33398 10.0001C3.33398 13.6834 6.31732 16.6668 10.0007 16.6668V18.1584C10.0007 18.5334 10.4507 18.7168 10.709 18.4501L13.034 16.1251C13.2007 15.9584 13.2007 15.7001 13.034 15.5334L10.709 13.2084C10.4507 12.9501 10.0007 13.1334 10.0007 13.5084V15.0001Z" fill="#212529"/>
    </g>
    <defs>
    <clipPath id="clip0_8231_4928">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default Refresh;