import "./verifyForm.scss";
import { FC } from "react";

import { useForm } from "@shared/model";
import { IFormCode } from "@shared/interfaces";
import { ButtonStyled } from "@shared/ui/components";
import { SIGNUP_STEPS } from "@widgets/interfaces";

import { GetProps, Input, Typography } from "antd";
import { VerifyCodeItem } from "@features/index";

const { Title } = Typography;

const code: IFormCode = {
  "1": "",
  "2": "",
  "3": "",
  "4": "",
  "5": "",
  "6": "",
};

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

type OTPProps = GetProps<typeof Input.OTP>;

const VerifyForm: FC<IProps> = ({ onContinueClick }) => {
  const handlerBeforeSubmitForm = () => {
    onContinueClick({ type: SIGNUP_STEPS.SET_PASSWORD });
  };

  const { formData, onChangeInput, onSubmitForm } = useForm<IFormCode>(
    code,
    handlerBeforeSubmitForm
  );

  const onChange: OTPProps["onChange"] = (text) => {
    console.log("onChange:", text);
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  return (
    <div>
      <form onSubmit={onSubmitForm} className="verify-form_wrapper">
        <Title level={4} className="verify__subtext">
          6-digit verification code
        </Title>
        <div className="verify-code__container">
          {/* {Object.keys(code).map((index: string) => (
            <VerifyCodeItem
              key={index}
              value={formData[index]}
              name={index}
              onChangeInput={onChangeInput}
            />
          ))} */}
          <Input.OTP formatter={(str) => str.toUpperCase()} {...sharedProps} />
        </div>
        <ButtonStyled
          text="Verify email"
          className="verify__btn"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />

        <Title level={4} className="verify-form__text verify__subtext-styling">
          Didn’t receive a code?
        </Title>
        <ButtonStyled
          text="Click to resend"
          className="resend__btn"
          type="primary"
          htmlType="button"
          fill="gray-primary-900"
        />
      </form>
    </div>
  );
};

export default VerifyForm;
