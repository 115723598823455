import { ApiService } from "@shared/utils";
import {
  CurrentStep,
  IGroup,
  IGroupFormCreate,
  IGroupFormEdit,
  IProductException,
  StrategyKeys,
} from "../model/groups.types";
import { GroupDTO } from "../model/group.dto";
import { url } from "@shared/utils/url.utils";
import { IDataTransfer, IQuery } from "@shared/interfaces";

class GroupsService extends ApiService {
  groupsURL: string;
  categoriesURL: string;
  subCategoriesURL: string;
  brandsURL: string;
  constructor() {
    super();

    this.baseURL = "/api/v1";
    this.groupsURL = "/api/v1/groups";
    this.categoriesURL = "/api/v1/categories";
    this.subCategoriesURL = "/api/v1/subCategories";
    this.brandsURL = "/api/v1/brands";
  }

  async getConfig() {
    return await this.baseServiceInstance.get(`${this.groupsURL}/config`);
  }

  async getGroups(queryParams: IQuery) {
    let params = url.generateQueryString(queryParams);

    const currentUrl = params
      ? `${this.groupsURL}?${params}`
      : `${this.groupsURL}`;

    return await this.baseServiceInstance.get(currentUrl);
  }

  async getGroupsFilters(queryParams: IQuery) {
    let params = url.generateQueryString(queryParams);

    const currentUrl = params
      ? `${this.groupsURL}/filters?${params}`
      : `${this.groupsURL}/filters`;

    return await this.baseServiceInstance.get(currentUrl);
  }

  async creteGroup() {
    return await this.baseServiceInstance.get(`${this.groupsURL}/strategy`);
  }

  async updateGroup(groupData: any) {
    return await this.baseServiceInstance.patch(
      `${this.groupsURL}/${groupData.id}`,
      {
        name: groupData.name,
      }
    );
  }

  async addProductToGroup(data: GroupDTO, groupId: string | null) {
    const isCompareUrl = groupId
      ? `/products/?groupId=${groupId}`
      : "/products";
    return await this.baseServiceInstance.patch(
      `${this.groupsURL}${isCompareUrl}`,
      data
    );
  }

  async getGroup(groupId: string) {
    return await this.baseServiceInstance.get(`${this.groupsURL}/${groupId}`);
  }

  async getCategories() {
    return await this.baseServiceInstance.get(`${this.categoriesURL}`);
  }
  async getSubCategories() {
    return await this.baseServiceInstance.get(`${this.subCategoriesURL}`);
  }
  async getBrands() {
    return await this.baseServiceInstance.get(`${this.brandsURL}`);
  }
  async getBrandsFromGroup(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.brandsURL}/groups/${groupId}`
    );
  }

  async deleteGroup(groupId: string) {
    return await this.baseServiceInstance.delete(
      `${this.groupsURL}/${groupId}`
    );
  }

  async updateGroupPrices(configId: number) {
    return await this.baseServiceInstance.patch(
      `${this.groupsURL}/updatePrices/${configId}`
    );
  }

  // Strategy Rule
  async getAllCompetitors(
    groupId: string,
    queryParams: IQuery,
    strategyId: string
  ) {
    let params = url.generateQueryString({ ...queryParams, groupId });
    return await this.baseServiceInstance.get(
      `${this.baseURL}/competitors/strategy/${strategyId}?${params}`
    );
  }
  async getMonthlyCategoryTraffic(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/categories/products?groupId=${groupId}`
    );
  }
  async updateStrategy(strategyId: string, data: any) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/strategies/${strategyId}`,
      data
    );
  }
  async getStrategy(strategyId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/strategies/${strategyId}`
    );
  }
  async addCompetitorsToGroup(selectedCompetitors: any[], strategyId: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/strategies/competitors?strategyId=${strategyId}`,
      {
        selectedCompetitors,
      }
    );
  }
  async getCompetitorsInGroup(
    groupId: string,
    strategyId: string,
    queryParams: IDataTransfer
  ) {
    let params = url.generateQueryString({ ...queryParams, groupId });
    return await this.baseServiceInstance.get(
      `${this.baseURL}/strategies/${strategyId}/competitors?${params}`
    );
  }

  async getProductsInGroup(data: IDataTransfer) {
    const { groupId, ...allParams } = data;
    let paramsBrands = "";

    if (data.brand) {
      paramsBrands = data.brand
        .map((brand: any) => `brand=${encodeURIComponent(brand)}`)
        .join("&");
    }

    delete allParams.brand;

    let params = url.generateQueryString(allParams || {});

    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/products?${params}&${paramsBrands}`
    );
  }

  async deleteProductsInGroup(groupId: number, products: string[]) {
    const currentUrl = `${this.baseURL}/products/groups/${groupId}/delete`;

    const body = { products };

    return await this.baseServiceInstance.patch(currentUrl, body);
  }

  async addExceptionToGroup(selectedProductId: string, groupId: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/${groupId}/exception`,
      { selectedProductId }
    );
  }
  async getExceptions(groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/exceptions`
    );
  }
  async removeExceptionById(id: string) {
    return await this.baseServiceInstance.delete(
      `${this.baseURL}/groups/exceptions/${id}`
    );
  }
  async removeCompetitors(
    selectedCompetitorsIds: string[],
    strategyId: string
  ) {
    return await this.baseServiceInstance.delete(
      `${this.baseURL}/strategies/${strategyId}/competitors`,
      { data: { selectedCompetitorsIds } }
    );
  }
  async getAllExceptions(params: IDataTransfer) {
    let paramsRes = url.generateQueryString(params || {});

    return await this.baseServiceInstance.get(
      `${this.baseURL}/products/exceptions?${paramsRes}`
    );
  }
  async saveExceptionChange(productId: string, exceptionLimits: any) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/exceptions/${productId}`,
      { exceptionLimits }
    );
  }
  async removeProductsInGroup(groupId: string) {
    return await this.baseServiceInstance.delete(
      `${this.baseURL}/groups/${groupId}/relations`
    );
  }
  async fetchCompleteCreateGroup(groupId: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/${groupId}/complete`
    );
  }
  async fetchSaveCurrentStep(keyOfStrategy: CurrentStep, groupId: string) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/groups/${groupId}/step`,
      {
        currentStep: keyOfStrategy,
      }
    );
  }
  async getWarningInfoToLimits(strategyId: string, groupId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/strategies/${strategyId}/alerts?groupId=${groupId}`
    );
  }
  async fetchChangeLockProductMode(productId: string, isLocked: boolean) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/products/${productId}/state`,
      {
        state: isLocked,
      }
    );
  }
}

const groupsService = new GroupsService();

export default groupsService;
