import * as Yup from "yup";

export const validationSchema = Yup.object({
  pricing: Yup.string().required("Pricing is required"),
  min: Yup.object({
    value: Yup.string(),
  }),
  max: Yup.object({
    value: Yup.string(),
  })
    .test(
      "at-least-one",
      "At least one of Min or Max value must be filled",
      function () {
        const { min = {}, max = {} } = this.parent || {};
        const minValue = min.value || "";
        const maxValue = max.value || "";
        return minValue.trim() !== "" || maxValue.trim() !== "";
      }
    )
    .test(
      "min-less-than-max",
      "Min value cannot be greater than Max value",
      function () {
        const { min = {}, max = {} } = this.parent || {};
        const minValue = parseFloat(min.value);
        const maxValue = parseFloat(max.value);

        if (!isNaN(minValue) && !isNaN(maxValue)) {
          return minValue <= maxValue;
        }

        return true;
      }
    ),
});
