interface IProps {
  color?: string;
}

const ArrowRight = ({ color= '#F8F9FA' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M3.61198 8.65999L11.0586 8.65999L7.80531 11.9133C7.54531 12.1733 7.54531 12.6 7.80531 12.86C8.06531 13.12 8.48531 13.12 8.74531 12.86L13.1386 8.46666C13.3986 8.20666 13.3986 7.78666 13.1386 7.52666L8.74531 3.13333C8.48531 2.87333 8.06531 2.87333 7.80531 3.13333C7.54531 3.39333 7.54531 3.81333 7.80531 4.07333L11.0586 7.32666L3.61198 7.32666C3.24531 7.32666 2.94531 7.62666 2.94531 7.99333C2.94531 8.35999 3.24531 8.65999 3.61198 8.65999Z" fill={color}/>
</svg>
);

export default ArrowRight;