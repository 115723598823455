const KnowledgeBase = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_7091_15909)">
      <path
        d="M5.00125 13.18V15.99C5.00125 16.72 5.40125 17.4 6.04125 17.75L11.0413 20.48C11.6413 20.81 12.3612 20.81 12.9613 20.48L17.9612 17.75C18.6012 17.4 19.0012 16.72 19.0012 15.99V13.18L12.9613 16.48C12.3612 16.81 11.6413 16.81 11.0413 16.48L5.00125 13.18ZM11.0413 3.52003L2.61125 8.12003C1.92125 8.50003 1.92125 9.50003 2.61125 9.88003L11.0413 14.48C11.6413 14.81 12.3612 14.81 12.9613 14.48L21.0012 10.09V16C21.0012 16.55 21.4513 17 22.0012 17C22.5512 17 23.0012 16.55 23.0012 16V9.59003C23.0012 9.22003 22.8012 8.89003 22.4812 8.71003L12.9613 3.52003C12.3612 3.20003 11.6413 3.20003 11.0413 3.52003Z"
        fill="#ADB5BD"
      />
    </g>
    <defs>
      <clipPath id="clip0_7091_15909">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default KnowledgeBase;
