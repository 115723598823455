import "./profilePopover.scss";
import { IUser } from "@entities/user";
import { Typography } from "antd";
import { FC } from "react";
const { Title } = Typography;

interface IProps {
  user: Partial<IUser>,
  logOut: () => void,
}
const ProfilePopoverContent: FC<IProps> = ({ user, logOut }: IProps) => {
  return (
    <div className="popover-profile__content">
      <div className="df ai-center popover-profile__info">
        <Title level={4} className="popover-profile__name">{`${user.firstName} ${user.lastName}`}</Title>
        <Title level={5} className="popover-profile__role">{user.role}</Title>
      </div>
      <Title level={5} className="popover-profile__email">{user.email}</Title>
      <Title level={4} className="popover-content__item disabled">Personal Info</Title>
      <Title level={4} className="popover-content__item disabled">Password & Security</Title>
      <Title level={4} className="popover-content__item aside-popover__logout" onClick={() => logOut()}>Logout</Title>
    </div>
  )
}

export default ProfilePopoverContent;