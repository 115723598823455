import { IFormLoginState } from "@shared/interfaces";
import { ApiService } from "@shared/utils";

class UserService extends ApiService {
  passwordURL: string;
  authURL: string;
  notificationsURL: string;

  constructor() {
    super();

    this.baseURL = "/api/v1/user";
    this.authURL = "/api/v1/auth";
    this.notificationsURL = "/api/v1/notifications";
    this.passwordURL = `${this.baseURL}/password`;
  }

  async getToketCsrf() {
    return await this.baseServiceInstance.get("/token");
  }

  async checkAuth() {
    return await this.baseServiceInstance.get(`${this.authURL}/check`);
  }

  async refreshToken() {
    return await this.baseServiceInstance.get(`${this.authURL}/refresh`);
  }

  async login(formData: IFormLoginState) {
    return await this.baseServiceInstance.post(
      `${this.authURL}/login`,
      formData
    );
  }

  async logout(id: string) {
    return await this.baseServiceInstance.delete(
      `${this.authURL}/logout/${id}`
    );
  }

  async recoveryPassword(formData: any) {
    return await this.baseServiceInstance.post(
      `${this.passwordURL}/recovery`,
      formData
    );
  }

  async resetPassword(formData: any) {
    return await this.baseServiceInstance.post(
      `${this.passwordURL}/reset`,
      formData
    );
  }

  async updatePassword(formData: any) {
    //return await this.baseServiceInstance.post(`${this.passwordUrl}/update`, formData);
  }

  async getNotifications(userId: string) {
    return await this.baseServiceInstance.get(`${this.notificationsURL}`);
  }
}

const userService = new UserService();

export default userService;
