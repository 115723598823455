import "./groupsItemsWidget.scss";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { AlertCustom, ButtonStyled } from "@shared/ui/components";
import { Delete, Error, Plus } from "@shared/ui/assets";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ItemsPopup } from "../../../ItemsPopup";
import { useShallow } from "zustand/react/shallow";
import { IProduct } from "@entities/products";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import { generateTextColumn, Pagination } from "@shared/common";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import { Checkbox, Modal } from "antd";
import {
  filteredCompareQueryObj,
  getItemWord,
  htmlToString,
} from "@shared/utils";
import {
  AlertTarget,
  AlertType,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import useStrategy from "@shared/model/hooks/useStrategy";
import { useUserSettingsStore } from "@entities/userSettings";

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  STORE = "store",
  BRAND = "brand",
  SUBCATEGORY = "subcategory",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
}

const GroupsItemsWidget = () => {
  let { id } = useParams();
  const [tableWidth, setTableWidth] = useState<number>(500);
  const [isItemsPopupShow, setItemsPopupShow] = useState<boolean>(false);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState<boolean>(false);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const { selectedCategory } = useGroupsStore((state) => state.currentGroup);

  const {
    strategy: { type },
  } = useGroupsStore((state) => state);

  const {
    selectProductInGroup,
    selectAllProductsInGroup,
    deleteProductsInGroup,
    setProductsInGroup,
  } = useGroupsStore(useShallow((state) => state));
  const {
    getProductsInGroup,
    addAlertToStore,
    removeSelectProductsIdsInGroup,
    updateStrategy,
  } = useGroupsStore((state) => state);
  const productsInGroupSelectedIds = useGroupsStore(
    useShallow((state) => state.productsInGroupSelectedIds)
  );
  const { asideCollapsed } = useUserSettingsStore((state) => state);

  const recordsCount = useGroupsStore(
    useShallow((state) => state.recordsCountInGroup)
  );
  const { getStrategy } = useGroupsStore((state) => state);
  const { checkIsMoreStepThen } = useStrategy();
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);

  const isAllSelected = productsInGroup.every((product) =>
    productsInGroupSelectedIds.includes(product.id)
  );

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | null;
  }>({
    key: "",
    direction: null,
  });

  useEffect(() => {
    const data = {
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [LIMIT_PARAM]: currentLimit,
      [OFFSET_PARAM]: currentOffset,
      [GROUP_ID_PARAM]: id,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;
    if (!isItemsPopupShow) {
      getProductsInGroup(filteredData);
    }
  }, [sortConfig, isItemsPopupShow, currentLimit, currentOffset]);

  useEffect(() => {
    return () => {
      setProductsInGroup([]);
      removeSelectProductsIdsInGroup();
    };
  }, []);

  useEffect(() => {
    const drawerCloseWidth = 72;
    const drawerOpenWidth = 248;
    const stepperWidth = 264 + 16 + 10;

    if (asideCollapsed) {
      setTableWidth(
        window.innerWidth -
          drawerOpenWidth -
          stepperWidth -
          window.innerWidth / 100 -
          8
      );
    } else {
      setTableWidth(window.innerWidth - drawerCloseWidth - stepperWidth - 32);
    }
  }, [asideCollapsed, productsInGroup]);

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const deleteSelectedProducts = async () => {
    if (!id) {
      return;
    }
    await deleteProductsInGroup(+id);
    removeSelectProductsIdsInGroup();
    const params = {
      [GROUP_ID_PARAM]: id,
    };

    await getProductsInGroup({ ...params });
    if (checkIsMoreStepThen(CurrentStep.PRICE_LIMITS)) {
      getStrategy();
    }
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!productsInGroup.length}
          onChange={(event) => {
            selectAllProductsInGroup(event.target.checked);
          }}
        ></Checkbox>
      ),
      className: "table-w-48",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71",
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Subcategory",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.SUBCATEGORY === sortConfig.key,
      }),
      className: "table-w-140",
      sortKey: THEAD_SORT_KEYS.SUBCATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: IProduct) => (
        <Checkbox
          checked={productsInGroupSelectedIds.some((el) => el === id)}
          onChange={(event) => {
            selectProductInGroup(id, event?.target.checked);
          }}
        ></Checkbox>
      ),
    },
    {
      content: ({ article, name }: IProduct) => {
        const titleWidth = Math.round(tableWidth * 0.4);
        return (
          <>
            <h5 className="product__subtitle">{article}</h5>
            <h5 className="product__title" style={{ width: titleWidth }}>
              {name}
            </h5>
          </>
        );
      },
      className: "w-430",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div>
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${currentPrice}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => <h5>{currentMargin}%</h5>,
      className: "ta-right",
    },
    {
      content: ({ cost }: IProduct) => <h5>${cost}</h5>,
      className: "ta-right",
    },
    {
      content: ({ brand }: IProduct) => <h5>{brand}</h5>,
    },
    {
      content: ({ subcategory }: IProduct) => <h5>{subcategory}</h5>,
    },
    {
      content: ({ store }: IProduct) => <h5>{store}</h5>,
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productsInGroupSelectedIds.some((id) => id === item.id),
      className: el.className,
      item: item,
    }));
  });

  const addItemsBtn = () => {
    setItemsPopupShow(true);
  };

  const textError = (
    <>Product items have been removed. Please add items to the group.</>
  );

  const textErrorStrategyRule = (
    <>
      Settings reset due to product items removal. Please review and reconfigure
      the Strategy Rule.
    </>
  );

  const handleOk = async () => {
    try {
      await deleteSelectedProducts();
      setIsModalInfoOpen(false);
      if (productsInGroupSelectedIds.length === recordsCount) {
        await useGroupsStore.setState((state) => ({
          ...state,
          currentGroup: {
            ...state.currentGroup,
            selectedCategory: "",
          },
          strategy: {
            ...state.strategy,
            [CurrentStep.STRATEGY_RULE]: {
              ...state.strategy[CurrentStep.STRATEGY_RULE],
              pricesFormula: {
                ...state.strategy[CurrentStep.STRATEGY_RULE].pricesFormula,
                value: "",
                rate: "dollar",
                metric: "plus",
                type: "",
              },
              competitors: [],
            },
          },
        }));

        if (checkIsMoreStepThen(CurrentStep.STRATEGY_TYPE)) {
          addAlertToStore(CurrentStep.PRODUCT_ITEM, {
            type: AlertType.ERROR,
            message: htmlToString(<>{textError}</>),
            priority: 2,
            target: AlertTarget.PRODUCT_ITEMS__DELETE_ALL_PRODUCTS,
          });
          await updateStrategy(CurrentStep.PRODUCT_ITEM);
        }

        if (
          checkIsMoreStepThen(CurrentStep.PRICE_LIMITS) &&
          type === StrategyTypeEnum.COMPETITION
        ) {
          addAlertToStore(CurrentStep.STRATEGY_RULE, {
            type: AlertType.ERROR,
            message: htmlToString(<>{textErrorStrategyRule}</>),
            priority: 0,
            target: AlertTarget.STRATEGY_RULE__DELETE_ALL_PRODUCTS,
          });
          await updateStrategy(CurrentStep.STRATEGY_RULE);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalInfoOpen(false);
  };

  const handleChange = async () => {
    if (checkIsMoreStepThen(CurrentStep.STRATEGY_TYPE)) {
      setIsModalInfoOpen(true);
    } else {
      await deleteSelectedProducts();

      if (productsInGroupSelectedIds.length === recordsCount) {
        useGroupsStore.setState((state) => ({
          ...state,
          currentGroup: {
            ...state.currentGroup,
            selectedCategory: "",
          },
        }));
      }
    }
  };

  const infoTextList = [
    {
      text: "Remove the items from the current group and leave them ungrouped",
    },
    {
      text: 'Potentially affect your current settings in the "Strategy Rule" and "Price Limits" tabs',
    },
  ];

  const modalInfoRender = () => (
    <Modal
      title={<div className="modal__title">Remove Items</div>}
      open={isModalInfoOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      closable={true}
      maskClosable={false}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={handleCancel}
            />
            <ButtonStyled
              text="Remove"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={handleOk}
            />
          </div>
        </div>,
      ]}
    >
      <div className="confirm-modal-body-info">
        Are you sure yo u want to remove
        <b>
          {` ${productsInGroupSelectedIds.length}`} selected{" "}
          {getItemWord(productsInGroupSelectedIds.length)}
        </b>
        ?
        <div>
          <span>This action will:</span>
          <ul>
            {infoTextList.map((el, ind) => (
              <li key={ind}>{el.text}</li>
            ))}
          </ul>
        </div>
        After removing the items, you may need to review and adjust these
        settings.
      </div>
    </Modal>
  );

  const renderChoose = () => {
    if (!productsInGroup.length) {
      return (
        <DefaultNoItemsValue
          isItemsPopupShow={isItemsPopupShow}
          setItemsPopupShow={setItemsPopupShow}
        />
      );
    }
    return (
      <>
        <ItemsPopup
          flag={isItemsPopupShow}
          setItemsPopupShow={setItemsPopupShow}
        />
        {modalInfoRender()}
        <div className="groups-edite__wrapper">
          <h3 className="groups-edite__title">Product Items</h3>
          <h5 className="groups-strategy__subtitle">Add items to your group</h5>

          <div style={{ width: tableWidth }}>
            <div className="groups-header df jc-sb">
              <div>
                <b>{`${recordsCount} ${getItemWord(
                  productsInGroup.length
                )}`}</b>
                {` from 
        ${selectedCategory} category`}
              </div>
              <div>
                <ButtonStyled
                  icon={<Plus color="#212529" />}
                  text="Add items"
                  htmlType="button"
                  onClick={() => addItemsBtn()}
                />
              </div>
            </div>
            <div className="groups-edite-table">
              <TableCustom
                headData={headDataTable}
                bodyData={dataTableBody}
                handleSort={handleSort}
              />
            </div>
          </div>
          <>
            <div className="pagination__container">
              {productsInGroupSelectedIds.length ? (
                <div className="selected__block mr-24">
                  <span className="selected__count">{`${productsInGroupSelectedIds.length}`}</span>
                  <div className="selected__text">
                    {`${getItemWord(
                      productsInGroupSelectedIds.length
                    )} selected`}
                  </div>

                  <div className="groups-block-delete__btn">
                    <ButtonStyled
                      type="default"
                      icon={<Delete />}
                      text="Remove"
                      htmlType="button"
                      fill="danger_300"
                      className="groups-delete__btn"
                      onClick={handleChange}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <Pagination
                prevPage={definePrefPage}
                nextPage={defineNextPage}
                onChange={onChangeItemsCount}
                recordsCount={definePageCount()}
                currentLimit={defineCurrentLimit()}
                currentSelectLimit={currentLimit.toString()}
                currentOffset={defineCurrentOffset()}
              />
            </div>
          </>
        </div>
      </>
    );
  };

  return <>{renderChoose()}</>;
};

const DefaultNoItemsValue = ({
  isItemsPopupShow,
  setItemsPopupShow,
}: {
  isItemsPopupShow: boolean;
  setItemsPopupShow: (bool: boolean) => void;
}) => {
  const alerts =
    useGroupsStore((state) => state.strategy?.productItems?.alerts) || [];
  const addItems = () => {
    setItemsPopupShow(true);
  };

  const firstAlert = alerts[0] || {};

  return (
    <>
      {firstAlert?.type === AlertType.ERROR ? (
        <div className="groups__alert">
          <AlertCustom
            type="error"
            message={firstAlert.message}
            icon={<Error />}
            className=""
            closable={false}
          />
        </div>
      ) : null}
      <div className="groups-empty__wrapper">
        <ItemsPopup
          flag={isItemsPopupShow}
          setItemsPopupShow={setItemsPopupShow}
        />

        <h4 className="groups-empty__title">No items added</h4>
        <h4 className="groups-empty__subtitle">
          Add items to start implementing pricing strategies
        </h4>

        <ButtonStyled
          type="primary"
          icon={<Plus />}
          text="Add items"
          htmlType="button"
          fill="gray-primary-900"
          className="groups-empty__btn"
          onClick={addItems}
        />
      </div>
    </>
  );
};

export default GroupsItemsWidget;
