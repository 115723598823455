import { EGroupStatus } from "@entities/groups";
import { FC } from "react";
import "./status.scss";
import { Typography } from "antd";

const { Title } = Typography;

interface IProps {
  type: EGroupStatus,
}

const Status: FC<IProps> = ({ type }: IProps) => {
  let classes = ["status__container"];
  let text = "N/A";

  if (type === EGroupStatus.COMING_SOON){
    classes.push("status-coming");
    text = "Coming soon";
  }

  if (type === EGroupStatus.REPRICE_READY){
    classes.push("status-reprice");
    text = "Reprice ready";
  }

  if (type === EGroupStatus.FAILED){
    classes.push("status-failed");
    text = "Failed";
  }

  if (type === EGroupStatus.IN_PROGRESS){
    classes.push("status-inprogres");
    text = "In progress";
  }

  if (type === EGroupStatus.COMPLETED){
    classes.push("status-completed");
    text = "Completed";
  }

  return (
    <div className={classes.join(" ")}>
      <Title level={5} className="">
       {text}
      </Title>
    </div>
  )
}

export default Status;