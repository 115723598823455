import { ButtonStyled, InputCustom } from "@shared/ui/components";
import "./itemsPopupBulkAction.scss";
import { ArrowBorder, Search } from "@shared/ui/assets";
import { FC, useEffect, useState } from "react";
import { Modal, Select } from "antd";
import {
  AlertTarget,
  AlertType,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import { useProductsStore } from "@entities/products";
import { DEFAULT_OPTION_SELECT_STRING } from "@shared/constants";
import { getItemWord, htmlToString } from "@shared/utils";
import useStrategy from "@shared/model/hooks/useStrategy";

interface IProps {
  setSearchValue: (str: string) => void;
  searchValue: string;
  brand: string;
  subcategory: string;
  setCurrentBrand: (str: string) => void;
  setCurrentSubCategory: (str: string) => void;
  setCurrentCategory: (str: string) => void;
  currentCategory: string;
}

const ItemsPopupBulkAction: FC<IProps> = (props) => {
  const {
    searchValue,
    setSearchValue,
    brand,
    subcategory,
    setCurrentBrand,
    setCurrentSubCategory,
    setCurrentCategory,
    currentCategory,
  } = props;
  const categories = useGroupsStore((state) => state.categories);
  const brands = useGroupsStore((state) => state.brands);
  const subcategories = useGroupsStore((state) => state.subcategories);
  const type = useGroupsStore((state) => state.strategy.type);
  const {
    getCategories,
    getBrands,
    getSubCategories,
    removeProductsInGroup,
    addAlertToStore,
    updateStrategy,
  } = useGroupsStore((state) => state);
  const { productsInGroup } = useGroupsStore((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const { checkIsMoreStepThen } = useStrategy();

  const productsSelectedIds = useProductsStore(
    (state) => state.productsSelectedIds
  );
  const { setProductsSelectedIds } = useProductsStore((state) => state);
  const [selectedValue, setSelectedValue] = useState("");

  const adaptedCategories = categories?.map((category) => ({
    label: category.name,
    value: category.name,
  }));

  useEffect(() => {
    getCategories();
    getBrands();
    getSubCategories();
  }, [getCategories, getBrands, getSubCategories]);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const adaptedBrands = brands?.map((category) => ({
    label: category.name,
    value: category.name,
  }));

  const adaptedSubCategories = subcategories?.map((category) => ({
    label: category.name,
    value: category.name,
  }));

  const defaultSelectItem = {
    label: DEFAULT_OPTION_SELECT_STRING,
    value: DEFAULT_OPTION_SELECT_STRING,
  };
  const onChange = (value: string) => {
    setCurrentCategory(value);
  };
  const handleOk = () => {
    selectedValue && onChange(selectedValue);
    setIsModalOpen(false);
    setProductsSelectedIds([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleConfirmCancel = () => {
    setIsModalConfirmOpen(false);
  };

  const handleChange = (value: string) => {
    if (productsInGroup.length) {
      setIsModalConfirmOpen(true);
      setSelectedValue(value);
      return;
    }
    if (productsSelectedIds.length) {
      setIsModalOpen(true);
      setSelectedValue(value);
      return;
    }

    onChange(value);
    useGroupsStore.setState((state) => ({
      ...state,
      currentGroup: {
        ...state.currentGroup,
        selectedCategory: value,
      },
    }));
  };

  const isDisabledItems = !currentCategory ? "disabled" : "";
  const textErrorItems =
    "Product items have been removed due to category change. Please add items to the group.";
  const textErrorStrategyRule =
    "Settings reset due to product items changes. Please review and reconfigure the Strategy Rule.";

  const handleConfirmOk = () => {
    removeProductsInGroup();
    selectedValue && onChange(selectedValue);
    setIsModalConfirmOpen(false);
    // setProductsSelectedIds([]);
    // setProductsInGroup([]);
    useGroupsStore.setState((state) => ({
      ...state,
      currentGroup: {
        ...state.currentGroup,
        selectedCategory: selectedValue,
      },
      productsInGroup: [],
    }));

    if (checkIsMoreStepThen(CurrentStep.PRICE_LIMITS)) {
      addAlertToStore(CurrentStep.PRODUCT_ITEM, {
        type: AlertType.ERROR,
        message: htmlToString(<>{textErrorItems}</>),
        priority: 2,
        target: AlertTarget.PRODUCT_ITEMS__CHANGE_CATEGORY,
      });
      updateStrategy(CurrentStep.PRODUCT_ITEM);
      ///

      if (type === StrategyTypeEnum.COMPETITION) {
        addAlertToStore(CurrentStep.STRATEGY_RULE, {
          type: AlertType.ERROR,
          message: htmlToString(<>{textErrorStrategyRule}</>),
          priority: 2,
          target: AlertTarget.STRATEGY_RULE__CHANGE_CATEGORY,
        });
        updateStrategy(CurrentStep.STRATEGY_RULE);
      }
    }
  };

  return (
    <>
      <Modal
        title={<div className="modal__title">Change Category</div>}
        open={isModalConfirmOpen}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        centered={true}
        closable={true}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={handleConfirmCancel}
              />

              <ButtonStyled
                text="Remove and change"
                type="primary"
                fill="red-600"
                htmlType="button"
                className="w-188"
                onClick={handleConfirmOk}
              />
            </div>
          </div>,
        ]}
      >
        <div className="confirm-modal-body">
          {`Changing the category will remove all ${
            productsInGroup.length
          } ${getItemWord(productsInGroup.length)} you have already added
          from the current category. All configuration on other steps will be
          reset. You cannot undo this action.`}
        </div>
      </Modal>

      <Modal
        title={<div className="modal__title">Change Category</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        closable={true}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={handleCancel}
              />

              <ButtonStyled
                text="Delete"
                type="primary"
                fill="red-600"
                htmlType="button"
                className="w-84"
                onClick={handleOk}
              />
            </div>
          </div>,
        ]}
      >
        <div className="confirm-modal-body">
          Changing categories will clear your current selection.
        </div>
      </Modal>

      <div className="ml-24 mr-24 items-bulk-block">
        <div className="items-bulk__container">
          <div className="mr-32">
            <InputCustom
              type="text"
              value={searchValue}
              name="items-search"
              placeholder="Search for items"
              onChangeInput={onSearchChange}
              fill="gray-primary-900"
              iconRight={<Search />}
              className={`${isDisabledItems}`}
            />
          </div>
          <div className={`items-select__container`}>
            <h5 className="items-select__title">Category</h5>
            <Select
              onChange={(value) => {
                handleChange(value);
              }}
              suffixIcon={<ArrowBorder />}
              value={currentCategory || null}
              defaultValue={"Select category"}
              optionFilterProp="label"
              options={adaptedCategories}
              placeholder="Select category"
              className={`gray-primary-900 items-select__wrapper`}
            />
          </div>
          <div className={`items-select__container ${isDisabledItems}`}>
            <h5 className="items-select__title">Subcategory</h5>
            <Select
              onChange={(value) => setCurrentSubCategory(value)}
              suffixIcon={<ArrowBorder />}
              value={subcategory || null}
              defaultValue={"Subcategory"}
              optionFilterProp="label"
              options={[defaultSelectItem, ...adaptedSubCategories]}
              placeholder="Select subcategory"
              className={`gray-primary-900 items-select__wrapper `}
            />
          </div>
          <div className={`items-select__container ${isDisabledItems}`}>
            <h5 className="items-select__title">Brand</h5>
            <Select
              onChange={(value) => setCurrentBrand(value)}
              suffixIcon={<ArrowBorder />}
              value={brand || null}
              defaultValue={"Brands"}
              optionFilterProp="label"
              options={[defaultSelectItem, ...adaptedBrands]}
              placeholder="Select brand"
              className={`gray-primary-900 items-select__wrapper`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemsPopupBulkAction;
