import "./groupEditForm.scss";
import { useEffect, useState } from "react";
import { IFormGroup } from "@shared/interfaces";
import { useForm } from "@shared/model";
import { ButtonStyled } from "@shared/ui/components";
import onGroupFormValid from "@widgets/admin/groups/model/groupValidationHook";
import { Input, Typography } from "antd";
import { errorHandler } from "@shared/utils";
import { Close } from "@shared/ui/assets";
import { IGroupFormEdit, useGroupsStore } from "@entities/groups";
import { useFormik } from "formik";

const { Title } = Typography;

interface IProps {
  onClose: (flag: boolean) => void;
}

const GroupsEditForm = ({ onClose }: IProps) => {
  const currentGroup = useGroupsStore((state) => state.currentGroup);
  const { updateGroupName } = useGroupsStore((state) => state);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setValues({ name: currentGroup?.name || "" });
  }, [currentGroup]);

  const initialValues = {
    name: currentGroup?.name || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: {},
    onSubmit: async (values) => {
      try {
        const res = await updateGroupName(values.name.trim());
        onClose(false);
      } catch (error: any) {
        console.log(error, "error");
        setErrorText(error.text);
      }
    },
  });

  const { setFieldValue, setValues, isValid, values } = formik;

  const closePopUp = () => {
    formik.resetForm();
    onClose(false);
    setErrorText("");
  };

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit();
        e.preventDefault();
      }}
      className="loginform__wrapper"
    >
      <div onClick={closePopUp} className="login-close__icon">
        <Close />
      </div>
      <div className="form__item form-item__mb24">
        <label className="" htmlFor={"groupName"}>
          Group name
        </label>
        <Input
          id="groupName"
          className={
            errorText
              ? "error-validation-input gray-primary-900"
              : "gray-primary-900"
          }
          name="name"
          type="text"
          value={values.name}
          onChange={({ target }) => {
            setFieldValue("name", target.value);
            setErrorText("");
          }}
        />
        {errorText && <span className="error__text">{errorText}</span>}
      </div>

      <div className="df jc-fe">
        <ButtonStyled
          text="Cancel"
          htmlType="button"
          className="w-84 mr-16"
          onClick={closePopUp}
        />

        <ButtonStyled
          disabled={!isValid}
          text="Save"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
          className="w-84"
        />
      </div>
    </form>
  );
};

export default GroupsEditForm;
