import { FC, useEffect, useState } from "react";
import { ConfigProvider } from "antd";

import { RouterBuilder } from "./config/routes";
import { ErrorBoundary } from "react-error-boundary";
import { useUserStore } from "@entities/user";
import { useShallow } from "zustand/react/shallow";
import { themeDefault } from "@shared/ui/theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import useSocket from "@shared/model/hooks/useSocket";

const App = () => {
  const userId = useUserStore(useShallow((state) => state.user?.id));
  const [isInit, setInit] = useState(false);
  const { checkAuth } = useUserStore(useShallow((state) => state));
  let routes = new RouterBuilder().build();
  // const { isConnected } = useSocket();

  // useEffect(() => {
  //   if (isConnected) {
  //     console.log("Application connected to socket server");
  //   } else {
  //     console.log("Application disconnected from socket server");
  //   }
  // }, [isConnected]);

  useEffect(() => {
    document.title = "ReLU";
    async function fetchAPI() {
      const accessToken = localStorage.getItem("_accessToken");

      if (accessToken) {
        await checkAuth();
      }

      setInit(true);
    }

    fetchAPI();
  }, []);

  if (!isInit) {
    return <></>;
  }

  if (!userId) {
    routes = new RouterBuilder().addAuthRouters().build();
  }

  const router = createBrowserRouter(routes);

  return (
    <div className="main">
      <RouterProvider router={router} />
    </div>
  );
};

const AppWithProvider: FC = () => {
  return (
    <div className="">
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <ConfigProvider theme={themeDefault}>
          <App />
        </ConfigProvider>
      </ErrorBoundary>
    </div>
  );
};

export default AppWithProvider;
