import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IUserSettingsState } from "./userSettings.types";

export const useUserSettingsStore = create<IUserSettingsState>()(
  devtools(
    immer((set, get) => ({
      asideCollapsed: false,

      setAsideCollapsed: (value: boolean) => {
        set({ asideCollapsed: value });
      },
    }))
  )
);
