import "./SignupPersonalInfoWidget.scss";
import { SetInfoForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { FC } from "react";

const { Title } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

const SignupPersonalInfoWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup-setinfo__container">
      <Title level={1} className="signup-setinfo__title">
        The Relu
      </Title>
      <Title level={1} className="signup-setinfo__subtitle">
        Almost done!
      </Title>
      <Title level={4} className="signup-setinfo__text">
        Please fill your personal data
      </Title>
      <Title level={4} className="signup-setinfo__text">
        to provide you a better experience
      </Title>
      <div className="signup-setinfo__form">
        <SetInfoForm onContinueClick={onContinueClick} />
      </div>
    </div>
  );
};

export default SignupPersonalInfoWidget;
