import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IDataTransfer, IQuery } from "@shared/interfaces";
import {
  AlertTarget,
  AlertType,
  AutoRepricingEnum,
  CurrentStep,
  EGroupStatus,
  EGroupType,
  IAlert,
  ICompetitor,
  IGroup,
  IGroupFormEdit,
  IGroupState,
  IProductException,
  IStrategyModel,
  IValidationKey,
  LimitMSRP,
  Metric,
  PriceRoundingEnum,
  Priority,
  Rate,
  StrategyKeys,
  StrategyTypeEnum,
} from "./groups.types";
import groupsService from "../api";
import { GroupDTO } from "./group.dto";
import {
  errorHandler,
  loadStateWrapper,
  sortAlertsToStrategy,
} from "@shared/utils";
import { LS_IS_WARNING_LIMITS } from "@shared/constants";

const initStrategy: IStrategyModel = {
  id: "",
  type: null,
  productItems: {
    alerts: [],
  },
  currentStep: CurrentStep.PRODUCT_ITEM,
  strategyRule: {
    alerts: [],
    competitors: [],
    pricesFormula: {
      type: null,
      value: "",
      metric: Metric.PLUS,
      rate: Rate.DOLLAR,
      metricToGrow: "",
      metricToMaintain: "",
    },
  },
  priceLimits: {
    alerts: [],
    min: {
      active: true,
      pricing: null,
      pricingValue: 0,
      metric: LimitMSRP.PLUS,
    },
    max: {
      active: false,
      pricing: null,
      pricingValue: 0,
      metric: LimitMSRP.PLUS,
    },
  },
  exceptionProducts: [],
  priceRounding: {
    metric: PriceRoundingEnum.ROUND_DOWN,
    decimal: {
      active: false,
      value: "",
    },
    integer: {
      active: false,
      value: "",
    },
  },

  updatePolicy: {
    value: AutoRepricingEnum.AUTO_REPRICING_OFF,
  },
};

export const initStateGroup: IGroup = {
  id: "",
  name: "",
  totalItems: 0,
  repricedItems: 0,
  isSelected: false,
  type: EGroupType.DRAFT,
  status: EGroupStatus.COMING_SOON,
  currentStep: CurrentStep.PRODUCT_ITEM,
};

export const useGroupsStore = create<IGroupState>()(
  devtools(
    immer((set, get) => ({
      brands: [],
      groups: [],
      filters: {},
      categories: [],
      recordsCount: 0,
      subcategories: [],
      configId: undefined,
      newPricesAvailable: [],
      lastPricesUpdate: undefined,
      currentGroup: initStateGroup,
      isUpdatePriceInProcess: false,
      strategy: initStrategy,
      keyOfActiveStep: CurrentStep.PRODUCT_ITEM,
      competitorsAll: [],
      competitorsInGroup: [],
      competitorsAllSelectedIds: [],
      competitorsInGroupSelectedIds: [],
      productsInGroup: [],
      productsInGroupSelectedIds: [],
      recordsCountInGroup: 0,
      productInGroupIdRadioSelected: "",
      exceptionProducts: [],
      validationErrors: {},
      allExceptions: [],
      isLoading: false,
      isUpdateStrategy: false,
      countExceptionsExist: 0,

      getConfig: async () => {
        try {
          const { data } = await groupsService.getConfig();

          set({
            lastPricesUpdate: data.lastGroupsPricesUpdated,
            configId: data.id,
          });
        } catch (e) {
          console.log(e);
        }
      },

      getGroups: async (queryParams: IQuery) => {
        if (queryParams?.filterBy?.toUpperCase() === "ALL") {
          queryParams.filterBy = "";
        }

        try {
          const { data } = await loadStateWrapper(
            set,
            groupsService.getGroups(queryParams)
          );

          set({
            groups: data.groups,
            recordsCount: data.count,
            newPricesAvailable: data.groupsReadyForRepricing,
          });
        } catch (e) {
          console.log(e);
        }
      },
      setGroups: (groups: IGroup[]) => {
        set({ groups });
      },
      setGroupName: (name: string) => {
        set({ currentGroup: { ...get().currentGroup, name } });
      },
      getGroupsFilters: async (queryParams: IQuery) => {
        try {
          const { data } = await groupsService.getGroupsFilters(queryParams);

          set({ filters: data });
        } catch (e) {
          console.log(e);
        }
      },
      getGroup: async (groupId: string) => {
        try {
          const { data } = await groupsService.getGroup(groupId);
          const { strategies, ...groupInfo } = data;
          const modifiedStrategy = sortAlertsToStrategy(strategies[0]);
          set({
            strategy: modifiedStrategy as IStrategyModel,
            currentGroup: groupInfo,
            keyOfActiveStep: strategies[0].currentStep,
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      getCategories: async () => {
        try {
          const { data } = await groupsService.getCategories();
          set({ categories: data });
        } catch (e) {
          console.log(e);
        }
      },
      getSubCategories: async () => {
        try {
          const { data } = await groupsService.getSubCategories();
          set({ subcategories: data });
        } catch (e) {
          console.log(e);
        }
      },
      getBrands: async () => {
        try {
          const { data } = await groupsService.getBrands();
          set({ brands: data });
        } catch (e) {
          console.log(e);
        }
      },
      getBrandsFromGroup: async () => {
        const groupId = get().currentGroup.id;
        if (groupId) {
          try {
            const { data } = await groupsService.getBrandsFromGroup(groupId);
            set({ brands: data });
          } catch (e) {
            console.log(e);
          }
        }
      },
      createGroup: async () => {
        try {
          const { data }: { data: IGroup } = await groupsService.creteGroup();
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      updateGroup: async (formData: IGroupFormEdit) => {
        const groupId = formData.id;
        const name = formData.name;
        try {
          await groupsService.updateGroup({
            id: groupId,
            name: name,
          });

          const groups = get().groups;

          const updatedGroups = groups.map((group) => {
            if (group.id === groupId) {
              group.name = name;
            }

            return group;
          });

          set({
            groups: updatedGroups,
            currentGroup: initStateGroup,
          });
        } catch (e) {
          console.log(e);
        }
      },
      updateGroupName: async (value: string) => {
        const currentGroupId = get().currentGroup.id;
        try {
          const res = await groupsService.updateGroup({
            id: get().currentGroup.id,
            name: value,
          });

          if (res?.status !== 200) {
            throw res.data;
          }

          if (currentGroupId) {
            set({ currentGroup: { ...get().currentGroup, name: value } });
          }
          return res.data;
        } catch (e) {
          const customError: any = errorHandler.isResponseHandled(e);

          if (customError) {
            throw customError;
          }
        }
      },
      updatePrices: async () => {
        const configId = get().configId;

        if (!configId) {
          return;
        }

        set({ isUpdatePriceInProcess: true });

        try {
          const { data } = await groupsService.updateGroupPrices(configId);

          set({
            lastPricesUpdate: data.lastGroupsPricesUpdated,
            isUpdatePriceInProcess: false,
          });
        } catch (e) {
          console.log(e);
        }
      },
      deleteGroup: async (group: IGroup, queryParams: IQuery) => {
        try {
          await groupsService.deleteGroup(group.id);

          await get().getGroupsFilters(queryParams);
          await get().getGroups(queryParams);
        } catch (e) {
          console.log(e);
        }
      },
      removeGroup: async (id: string) => {
        try {
          await groupsService.deleteGroup(id);
        } catch (e) {
          console.log(e);
        }
      },
      selectGroup: (groupId: string, flag: boolean) => {
        const groups = get().groups;

        const groupsWithSelected = groups.map((group) => {
          if (group.id === groupId) {
            group.isSelected = flag;
          }

          return group;
        });

        set({ groups: groupsWithSelected });
      },
      selectAllGroup: (flag: boolean) => {
        const groups = get().groups;

        const groupsWithSelected = groups.map((group) => {
          group.isSelected = flag;

          return group;
        });

        set({ groups: groupsWithSelected });
      },

      showUpdatePriceAlert: (arr: []) => {
        set({ newPricesAvailable: arr });
      },

      setCurrentGroup: (group: IGroup) => {
        set({ currentGroup: group });
      },
      saveEmptyGroupWithProducts: async (
        selectedProductsIds: string[],
        groupId: string | null,
        selectedCategory: string
      ) => {
        const recordsCount = get().recordsCount;

        const groupDto = new GroupDTO({
          name: `Draft Group ${recordsCount + 1}`,
          selectedProductsIds: selectedProductsIds,
          selectedCategory: selectedCategory,
        }).create();

        try {
          const { data } = await groupsService.addProductToGroup(
            groupDto,
            groupId
          );
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      getAllCompetitors: async (groupId: string, queryParams: IQuery) => {
        const strategyId = get().strategy.id;

        try {
          const { data } = await loadStateWrapper(
            set,
            groupsService.getAllCompetitors(groupId, queryParams, strategyId)
          );

          set({ competitorsAll: data });
        } catch (e) {
          console.log(e);
        }
      },
      getCompetitorsInGroup: async (groupId: string, params: IDataTransfer) => {
        const strategyId = get().strategy.id;
        try {
          const { data } = await groupsService.getCompetitorsInGroup(
            groupId,
            strategyId,
            params
          );
          const strategy = get().strategy;
          const competitorsIds = data.map(
            (competitor: ICompetitor) => competitor.id
          );

          set({
            competitorsInGroup: data,
            strategy: {
              ...strategy,
              strategyRule: {
                ...strategy.strategyRule,
                competitors: competitorsIds,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
      selectCompetitorsAll: (flag: boolean) => {
        const competitorsAll = get().competitorsAll;
        const competitorsAllSelectedIds = get().competitorsAllSelectedIds;

        if (flag) {
          set({
            competitorsAllSelectedIds: Array.from(
              new Set([
                ...competitorsAllSelectedIds.concat(
                  competitorsAll.map((competitor) => competitor.id)
                ),
              ])
            ),
          });
        } else {
          set({
            competitorsAllSelectedIds: competitorsAllSelectedIds.filter(
              (id) => !competitorsAll.find((product) => product.id === id)
            ),
          });
        }
      },
      selectCompetitorsInGroup: (flag: boolean) => {
        const competitorsInGroup = get().competitorsInGroup;
        const competitorsInGroupSelectedIds =
          get().competitorsInGroupSelectedIds;

        if (flag) {
          set({
            competitorsInGroupSelectedIds: Array.from(
              new Set([
                ...competitorsInGroupSelectedIds.concat(
                  competitorsInGroup.map((competitor) => competitor.id)
                ),
              ])
            ),
          });
        } else {
          set({
            competitorsInGroupSelectedIds: competitorsInGroupSelectedIds.filter(
              (id) =>
                !competitorsInGroup.find((competitor) => competitor.id === id)
            ),
          });
        }
      },
      selectCompetitor: (competitorId: string) => {
        const competitorsAllSelectedIds = get().competitorsAllSelectedIds;
        const indexOfSelectedProduct = competitorsAllSelectedIds.findIndex(
          (id) => id === competitorId
        );

        if (indexOfSelectedProduct !== -1) {
          const updatedProductsSelectedIds = [
            ...competitorsAllSelectedIds.slice(0, indexOfSelectedProduct),
            ...competitorsAllSelectedIds.slice(indexOfSelectedProduct + 1),
          ];
          set({ competitorsAllSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            competitorsAllSelectedIds: [
              ...competitorsAllSelectedIds,
              competitorId,
            ],
          });
        }
      },
      selectCompetitorInGroup: (competitorId: string) => {
        const competitorsInGroupSelectedIds =
          get().competitorsInGroupSelectedIds;
        const indexOfSelectedCompetitors =
          competitorsInGroupSelectedIds.findIndex((id) => id === competitorId);

        if (indexOfSelectedCompetitors !== -1) {
          const updatedProductsSelectedIds = [
            ...competitorsInGroupSelectedIds.slice(
              0,
              indexOfSelectedCompetitors
            ),
            ...competitorsInGroupSelectedIds.slice(
              indexOfSelectedCompetitors + 1
            ),
          ];
          set({ competitorsInGroupSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            competitorsInGroupSelectedIds: [
              ...competitorsInGroupSelectedIds,
              competitorId,
            ],
          });
        }
      },
      getMonthlyCategoryTraffic: async (groupId: string) => {
        try {
          const { data } = await groupsService.getMonthlyCategoryTraffic(
            groupId
          );
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      updateStrategy: async (keyOfStrategy: CurrentStep) => {
        const strategy = get().strategy;

        const chooseKey =
          keyOfStrategy === CurrentStep.STRATEGY_TYPE ? "type" : keyOfStrategy;
        const dataRequest = {
          [chooseKey]: strategy[chooseKey],
        };

        try {
          if (strategy.id) {
            const { data } = await groupsService.updateStrategy(
              strategy.id,
              dataRequest
            );
            const modifiedStrategy = sortAlertsToStrategy(data);
            set({ strategy: modifiedStrategy as IStrategyModel });
            return data;
          }
        } catch (error) {
          console.log(error);
        }
      },
      getStrategy: async () => {
        const strategyId = get().strategy.id;
        const { data } = await groupsService.getStrategy(strategyId);

        const modifiedStrategy = sortAlertsToStrategy(data);

        set({ strategy: modifiedStrategy as IStrategyModel });
      },

      setStrategyType: (type: StrategyTypeEnum) => {
        set({ strategy: { ...get().strategy, type } });
      },
      setKeyOfActiveStep: (keyOfActiveStep: CurrentStep) => {
        set({ keyOfActiveStep });
      },
      addCompetitorsToGroup: async () => {
        const competitorsAll = get().competitorsAll;
        const competitorsAllSelectedIds = get().competitorsAllSelectedIds;

        const selectedCompetitors = competitorsAllSelectedIds.reduce(
          (acc: any, id) => {
            const competitor = competitorsAll.find(
              (product) => product.id === id
            );
            if (competitor) {
              acc.push({
                competitorId: competitor?.id,
                products: competitor?.products.map((product) => product.id),
              });
            }
            return acc;
          },
          []
        );

        try {
          const { data } = await groupsService.addCompetitorsToGroup(
            selectedCompetitors,
            get().strategy.id
          );
          return data;
        } catch (error) {
          console.log(error);
        }
      },
      setCompetitorsAllSelectedIds: () => {
        try {
          set({
            competitorsAllSelectedIds: [],
          });
        } catch (error) {
          console.log(error);
        }
      },
      setCompetitorsInGroup: (arr: ICompetitor[]) => {
        set({ competitorsInGroup: arr });
      },
      getProductsInGroup: async (params: IDataTransfer) => {
        try {
          const { data } = await loadStateWrapper(
            set,
            groupsService.getProductsInGroup(params)
          );
          set({
            productsInGroup: data.items,
            recordsCountInGroup: data.count,
          });
        } catch (e) {
          console.log(e);
        }
      },
      deleteProductsInGroup: async (groupId: number) => {
        try {
          const productsInGroupSelectedIds = get().productsInGroupSelectedIds;

          await groupsService.deleteProductsInGroup(
            groupId,
            productsInGroupSelectedIds
          );
          set({ productsInGroupSelectedIds: [] });
        } catch (e) {
          console.log(e);
        }
      },
      selectProductInGroup: (productId: string, flag: boolean) => {
        const productsInGroupSelectedIds = get().productsInGroupSelectedIds;
        const indexOfSelectedProduct = productsInGroupSelectedIds.findIndex(
          (id) => id === productId
        );

        if (indexOfSelectedProduct !== -1) {
          const updatedProductsSelectedIds = [
            ...productsInGroupSelectedIds.slice(0, indexOfSelectedProduct),
            ...productsInGroupSelectedIds.slice(indexOfSelectedProduct + 1),
          ];
          set({ productsInGroupSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            productsInGroupSelectedIds: [
              ...productsInGroupSelectedIds,
              productId,
            ],
          });
        }
      },
      selectAllProductsInGroup: (flag: boolean) => {
        const productsInGroup = get().productsInGroup;
        const productsInGroupSelectedIds = get().productsInGroupSelectedIds;

        if (flag) {
          set({
            productsInGroupSelectedIds: Array.from(
              new Set([
                ...productsInGroupSelectedIds.concat(
                  productsInGroup.map((product) => product.id)
                ),
              ])
            ),
          });
        } else {
          set({
            productsInGroupSelectedIds: productsInGroupSelectedIds.filter(
              (id) => !productsInGroup.find((product) => product.id === id)
            ),
          });
        }
      },
      removeSelectProductsIdsInGroup: () => {
        set({ productsInGroupSelectedIds: [] });
      },
      setProductsInGroup: (value: []) => {
        set({ productsInGroup: value });
      },
      removeProductsInGroup: async () => {
        await groupsService.removeProductsInGroup(get().currentGroup.id);
      },

      clearStrategyKey: (key: StrategyKeys) => {
        set({ strategy: { ...get().strategy, [key]: initStrategy[key] } });
      },
      addAlertToStore: (key: StrategyKeys, alert) => {
        const partOfStrategy = get().strategy[key] ?? {};
        set({
          strategy: {
            ...get().strategy,
            [key]: {
              ...partOfStrategy,
              alerts: [...(partOfStrategy.alerts ?? []), alert],
            },
          },
        });
      },
      setProductInGroupIdRadioSelected: (value: string) => {
        set({
          productInGroupIdRadioSelected: value,
        });
      },
      addExceptionToGroup: async () => {
        const groupId = get().currentGroup.id;
        await groupsService.addExceptionToGroup(
          get().productInGroupIdRadioSelected,
          groupId
        );
      },
      filterAllExceptions: async () => {
        const radio = get().productInGroupIdRadioSelected;
        const filteredExceptions = get().allExceptions.filter(
          (el) => el.id !== radio
        );
        set({ allExceptions: filteredExceptions });
      },
      getExceptions: async () => {
        const groupId = get().currentGroup.id;
        const { data } = await groupsService.getExceptions(groupId);
        set({ exceptionProducts: data });
      },
      removeExceptionById: async (id: string) => {
        await groupsService.removeExceptionById(id);
      },
      removeLocalExceptionById: async (id: string) => {
        const filteredProductsInGroup = get().productsInGroup.map((el) => {
          if (el.id === id) {
            return { ...el, exceptionLimits: null };
          }
          return el;
        });

        set({ productsInGroup: filteredProductsInGroup });
      },
      getAllExceptions: async (params: IDataTransfer) => {
        const { data } = await groupsService.getAllExceptions(params);
        set({ allExceptions: data });
        return data;
      },
      removeCompetitors: async () => {
        await groupsService.removeCompetitors(
          get().competitorsInGroupSelectedIds,
          get().strategy.id
        );

        const competitorsInGroupSelectedIdsLocal =
          get().competitorsInGroupSelectedIds;

        const strategy = get().strategy;

        const filteredCompetitors = strategy.strategyRule.competitors.filter(
          (el) => !competitorsInGroupSelectedIdsLocal.includes(el)
        );

        set({
          competitorsInGroup: get().competitorsInGroup.filter(
            (el) => !competitorsInGroupSelectedIdsLocal.includes(el.id)
          ),
          competitorsInGroupSelectedIds: [],
          strategy: {
            ...strategy,
            strategyRule: {
              ...strategy.strategyRule,
              competitors: filteredCompetitors,
            },
          },
        });
      },
      clearCompetitorsAll: () => {
        set({
          competitorsAll: [],
        });
      },
      setValidationError: (error: {
        [key: keyof IStrategyModel]: IValidationKey | null;
      }) => {
        set({ validationErrors: { ...get().validationErrors, ...error } });
      },
      editException: (productId: string, obj: any, mode?: string) => {
        const exceptionProducts = get().exceptionProducts;
        const newExceptionProducts = exceptionProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                exceptionLimits: mode
                  ? { ...obj }
                  : { ...product.exceptionLimits, ...obj },
              }
            : product
        );
        set({ exceptionProducts: newExceptionProducts });
      },
      saveExceptionChange: async (productId: string) => {
        const exceptionLimits = get().exceptionProducts.find(
          (el) => el.id === productId
        )?.exceptionLimits;

        const dataTransfer: { [key: string]: any } = {};
        if (exceptionLimits?.min?.value) {
          dataTransfer.min = {
            ...exceptionLimits?.min,
            value: exceptionLimits.min,
          };
        }
        if (exceptionLimits?.max?.value) {
          dataTransfer.max = {
            ...exceptionLimits?.max,
            value: exceptionLimits.max,
          };
        }
        if (exceptionLimits) {
          await groupsService.saveExceptionChange(productId, exceptionLimits);
        }
      },

      modifyException: async (productId: string, exceptionLimits: any) => {
        const { data } = await groupsService.saveExceptionChange(
          productId,
          exceptionLimits
        );

        const productsInGroup = get().productsInGroup.map((el) => {
          if (el.id === productId) {
            return data;
          }
          return el;
        });

        set({ productsInGroup });
        return data;
      },
      clearAlertToStore: async (key: StrategyKeys, updatedAlerts: IAlert[]) => {
        set({
          strategy: {
            ...get().strategy,
            [key]: {
              ...get().strategy[key],
              alerts: updatedAlerts,
            },
          },
        });
      },
      clearStrategyInStore: () => {
        set({ validationErrors: {} });
        set({ strategy: initStrategy });
      },
      fetchCompleteCreateGroup: async () => {
        const groupId = get().currentGroup.id;
        const { data } = await groupsService.fetchCompleteCreateGroup(groupId);
        return data;
      },

      fetchSaveCurrentStep: async (keyOfStrategy: CurrentStep) => {
        const groupId = get().currentGroup.id;
        if (keyOfStrategy && groupId) {
          const { data } = await groupsService.fetchSaveCurrentStep(
            keyOfStrategy,
            groupId
          );
          set({
            currentGroup: {
              ...get().currentGroup,
              currentStep: data.currentStep,
            },
          });
        }
      },
      clearGroup: () => {
        set({
          currentGroup: initStateGroup,
        });
      },
      fetchChangeLockProductMode: async (
        productId: string,
        isLocked: boolean
      ) => {
        const { data } = await groupsService.fetchChangeLockProductMode(
          productId,
          isLocked
        );

        const updatedProductsInGroup = get().productsInGroup.map((el) => {
          if (el.id !== data.id) {
            return el;
          }
          return data;
        });

        set({
          productsInGroup: updatedProductsInGroup,
        });
      },
      removeSelectedCompetitorsIds: () => {
        set({
          competitorsInGroupSelectedIds: [],
        });
      },
    }))
  )
);
//
