interface IProps {
  color?: string;
}

const Dots = ({color='#ADB5BD'}:IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
  >
    <circle cx="2" cy="2" r="2" fill={color} />
    <circle cx="9" cy="2" r="2" fill={color} />
    <circle cx="16" cy="2" r="2" fill={color} />
  </svg>
);

export default Dots;
