import { Checkbox, Select } from "antd";
import { FC, useState } from "react";
import "./multipleSelect.scss";
import { ArrowBorder } from "@shared/ui/assets";

interface IProps {
  value?: string[];
  onChange: (value: string[]) => void;
  options: { label: React.ReactElement; value: string }[];
  selectedItems: string[];
  placeholder?: string;
  className?: string;
  noItems?: React.ReactElement;
}

const allDEfaultValue = "all";

const MultipleSelect: FC<IProps> = (props) => {
  const {
    onChange,
    options,
    selectedItems,
    placeholder = "Select options",
    className = "",
    noItems,
  } = props;

  const optionsCompare = [
    { label: <>Select all</>, value: allDEfaultValue },
  ].concat(options);

  const allOptionValues = options
    .filter((option) => option.value !== allDEfaultValue)
    .map((option) => option.value);

  const handleChange = (value: string[]) => {
    const filteredSelects = value.filter((item) => item !== allDEfaultValue);

    if (
      value.includes(allDEfaultValue) &&
      filteredSelects.length !== allOptionValues.length
    ) {
      onChange(allOptionValues);
    } else {
      onChange(filteredSelects);
    }
  };

  const isAllSelected = selectedItems.length === allOptionValues.length;
  const isIndeterminate =
    selectedItems.length > 0 && selectedItems.length < allOptionValues.length;

  return (
    <div className="multiple-select">
      <Select
        mode="multiple"
        value={selectedItems}
        suffixIcon={<ArrowBorder color="#212527" />}
        className={`custom-arrow ${className}`}
        onChange={handleChange}
        showSearch={false}
        placeholder={
          selectedItems.length === 0 ? (
            <span style={{ color: "black" }}>None</span>
          ) : (
            placeholder
          )
        }
        maxTagCount={0}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        maxTagPlaceholder={() => {
          if (selectedItems.length === 0) {
            return "No data";
          }
          return isAllSelected
            ? "All selected"
            : `${selectedItems.length} selected`;
        }}
        dropdownRender={(menu) => (
          <div>
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isAllSelected}
              onChange={(e) => {
                const newValue = e.target.checked ? allOptionValues : [];
                handleChange(newValue);
              }}
            >
              <span>Select all</span>
            </Checkbox>
            <div className="multiple-select__content">
              {optionsCompare
                .filter((option) => option.value !== allDEfaultValue)
                .map((option) => (
                  <Checkbox
                    key={option.value}
                    checked={selectedItems.includes(option.value)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...selectedItems, option.value]
                        : selectedItems.filter((item) => item !== option.value);
                      handleChange(newValue);
                    }}
                  >
                    {option.label}
                  </Checkbox>
                ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default MultipleSelect;
