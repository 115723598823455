import React from "react";
import "./loaderBlock.scss";
import { Spiner } from "@features/spiner";

const LoaderBlock = () => {
  return (
    <div className="loader-block-main">
      <Spiner />
    </div>
  );
};

export default LoaderBlock;
