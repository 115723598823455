import "./groupsEditTitle.scss";
import { Arrow } from "@shared/ui/assets";
import { ROUTES } from "@shared/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { useState } from "react";
import { ButtonStyled } from "@shared/ui/components";
import { GroupsOnCancelNotificationPopup } from "../../GroupsOnCancelNotificationPopup";

const GroupsEditTitle = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { clearStrategyInStore, removeGroup, clearGroup } = useGroupsStore(
    (state) => state
  );
  const currentGroup = useGroupsStore(
    useShallow((state) => state.currentGroup)
  );
  const [isCancelConfirmationShow, setCancelConfirmationShow] =
    useState<boolean>(false);

  const handleClick = () => {
    setCancelConfirmationShow(true);
  };

  const saveGroup = async () => {
    clearStrategyInStore();
    clearGroup();
    return navigate(ROUTES.ADMIN_GROUPS);
  };

  if (!currentGroup) {
    return <></>;
  }

  const cancelApprove = async () => {
    if (id) {
      await removeGroup(id);
      clearStrategyInStore();
      clearGroup();
      navigate(ROUTES.ADMIN_GROUPS);
    }
  };

  return (
    <>
      {
        <GroupsOnCancelNotificationPopup
          flag={isCancelConfirmationShow}
          onClose={() => setCancelConfirmationShow(false)}
          onOk={cancelApprove}
        />
      }
      <div className="content__title df jc-sb ai-center">
        <div>
          <div className="back_to_container" onClick={handleClick}>
            <Arrow />
            <h5 className="">Back to Groups</h5>
          </div>
          <h1 className="">Create Group</h1>
        </div>

        <div className="edit-title__actions">
          <ButtonStyled
            text="Save as a draft"
            htmlType="button"
            onClick={saveGroup}
          />
        </div>
      </div>
    </>
  );
};

export default GroupsEditTitle;
