import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { FC } from "react";

interface IProps {
  title: string;
  arrow?: boolean;
  className?: string;
  placement?: TooltipPlacement;
  children: React.ReactElement;
}

const CustomTooltip: FC<IProps> = ({
  title,
  placement = "top",
  arrow = true,
  children,
  className: customClasses,
}: IProps) => {
  const classes = ["df", "ai-center"];

  if (customClasses) {
    classes.push(customClasses);
  }
  return (
    <Tooltip
      title={title}
      arrow={arrow}
      placement={placement}
      color={"#495057"}
      overlayStyle={{ fontSize: "12px" }}
    >
      <div className={classes.join(" ")}>{children}</div>
    </Tooltip>
  );
};

export default CustomTooltip;
