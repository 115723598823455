import React from "react";
import "./itemPriceElasticity.scss";
import {
  ButtonStyled,
  CustomTooltip,
  InputWithIcons,
} from "@shared/ui/components";
import { Arrow, Info } from "@shared/ui/assets";
import CustomGraphElasticity from "../CustomGraphElasticity/CustomGraphElasticity";
import { useFormik } from "formik";

const titleTooltip = (
  <CustomTooltip
    title={"View competitors’ pricing history data."}
    placement="right"
  >
    <Info />
  </CustomTooltip>
);

const ItemPriceElasticity = () => {
  const formik = useFormik({
    initialValues: { newPrice: "" },
    // validationSchema: {},
    onSubmit: async (values) => {
      alert(values.newPrice);
    },
  });

  const {
    touched,
    errors,
    setFieldValue,
    isValid,
    values,
    handleBlur,
    setValues,
  } = formik;

  const optionImpact = [
    {
      label: "Margin",
      valueBefore: "10%",
      valueAfter: "9%",
      profit: "+45.84%",
    },
    {
      label: "Sales",
      valueBefore: "120",
      valueAfter: "113",
      profit: "-5.83%",
    },
    {
      label: "Profit",
      valueBefore: "$1799",
      valueAfter: "$2824",
      profit: "+56.98%",
    },
    {
      label: "Revenue",
      valueBefore: "$8.39k",
      valueAfter: "$9.04k",
      profit: "+7.75%",
    },
  ];

  return (
    <div className="item-price-elasticity">
      <section className="block-elasticity ">
        <h3 className="elasticity-title">
          Price Elasticity
          <span>{titleTooltip}</span>
        </h3>
      </section>
      <section>
        <CustomGraphElasticity newPriceValue={values.newPrice} />
      </section>

      <section className="section-price">
        <div className="df ai-center">
          <span className="price-title">Price</span>
          <div className="df ai-center">
            <form
              className="item-price-elasticity-form"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <InputWithIcons
                classNameInput="item-price-elasticity-input"
                value={values.newPrice}
                onChange={(value: string) => setFieldValue("newPrice", value)}
              />
              <span className="item-price-elasticity-input-label">
                Platform suggested price $79.99
              </span>
              <ButtonStyled
                type="primary"
                text="Save"
                htmlType="submit"
                fill="gray-primary-900"
                className={`save-price-btn`}
              />
            </form>
          </div>
        </div>
      </section>

      <section className="section-impact">
        <h3 className="impact-title">
          Price Change Impact (30-Day)
          <span>{titleTooltip}</span>
        </h3>
        <div className="impact-items">
          {optionImpact.map((el, ind) => {
            return (
              <div className="impact-item" key={ind}>
                <div className="df jc-sb mb-8">
                  <div className="item-label">{el.label}</div>
                  <div
                    className={
                      el.profit.includes("-")
                        ? `item-profit-down`
                        : `item-profit-up`
                    }
                  >
                    {el.profit}
                  </div>
                </div>

                <div className="df jc-sb ai-center">
                  <div className="item-before">{el.valueBefore}</div>
                  <div className="item-arrow">
                    <Arrow />
                  </div>
                  <div className="item-after">{el.valueAfter}</div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ItemPriceElasticity;
