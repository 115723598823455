import { ROUTES } from "@shared/interfaces";
import { AuthWrapper } from "@widgets/auth/index";
import { Typography } from "antd";

const { Title, Link } = Typography;

const NotFoundPage = () => {
  return (
    <AuthWrapper>
      <div>
        <Title level={2}>404</Title>
        <Link href={ROUTES.LOGIN}>LOGIN</Link>
      </div>
    </AuthWrapper>
  );
};

export default NotFoundPage;
