interface IProps {
  color?: string;
  onClick?: ()=> void; 
}

const ArrowBorder = ({ color= '#6C757D', onClick }: IProps) => (
  <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  < path d="M9.35781 12.3955L9.35781 12.3955L9.36001 12.3933L14.3181 7.43521C14.3601 7.41425 14.4075 7.40545 14.4547 7.41019C14.5133 7.41607 14.5679 7.44247 14.609 7.48472C14.65 7.52696 14.6748 7.58235 14.679 7.64111C14.6826 7.69042 14.6714 7.73946 14.6472 7.78212L10.2417 12.2174L9.89172 12.5698L10.2417 12.9221L14.6352 17.3453C14.6527 17.3843 14.66 17.4274 14.6561 17.4704C14.6511 17.5261 14.6274 17.5786 14.589 17.6193C14.5506 17.6601 14.4996 17.6867 14.4442 17.695C14.4054 17.7008 14.3659 17.6974 14.3291 17.6853L9.39001 12.7462L9.37897 12.7352L9.36726 12.7248C9.34275 12.7032 9.32292 12.6768 9.30898 12.6473L8.85676 12.8606L9.30898 12.6473C9.29607 12.6199 9.28846 12.5903 9.28653 12.5602C9.28728 12.5307 9.29346 12.5015 9.30479 12.4743L8.84299 12.2826L9.30479 12.4743C9.31704 12.4447 9.33507 12.418 9.35781 12.3955Z" stroke={color}/>
  </svg>
);

export default ArrowBorder;