import React, { useEffect, useState } from "react";
import {
  AlertCustom,
  ButtonStyled,
  CustomTooltip,
  InputCustom,
  RadioCustom,
  SwitcherCustom,
} from "@shared/ui/components";
import {
  ArrowBorder,
  Delete,
  Error,
  Info,
  Plus,
  Warning,
} from "@shared/ui/assets";
import { StrategyCompetitionPopup } from "@widgets/admin/groups";
import { Checkbox } from "antd";
import { generateTextColumn, TableCustom } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import {
  Rate,
  Metric,
  AlertType,
  CurrentStep,
  ICompetitor,
  PriceFormulaType,
  useGroupsStore,
  AlertTarget,
} from "@entities/groups";
import {
  filteredCompareQueryObj,
  getCompetitorWord,
  getItemWord,
  htmlToString,
} from "@shared/utils";
import { useParams } from "react-router-dom";
import "./competition.scss";
import { DIRECTION_PARAM, ORDER_BY_PARAM } from "@shared/constants";
import useStrategy from "@shared/model/hooks/useStrategy";

enum THEAD_SORT_KEYS {
  NAME = "name",
  TRAFFIC = "categoryTraffic",
}

const dataSign = [
  {
    key: Metric.PLUS,
    content: <>+</>,
  },
  {
    key: Metric.MINUS,
    content: <>-</>,
  },
];

const dataRate = [
  {
    key: Rate.DOLLAR,
    content: <>$</>,
  },
  {
    key: Rate.PERCENTAGE,
    content: <>%</>,
  },
];

const Competition = () => {
  const { id } = useParams();
  const [isShowModal, setIsShowModal] = useState(false);
  const {
    competitorsInGroup,
    competitorsInGroupSelectedIds,
    getCompetitorsInGroup,
    selectCompetitorInGroup,
    selectCompetitorsInGroup,
    strategy,
    removeCompetitors,
    updateStrategy,
    addAlertToStore,
    removeSelectedCompetitorsIds,
  } = useGroupsStore((state) => state);
  const { clearAlertToStore } = useGroupsStore((state) => state);
  const { checkIsMoreStepThen } = useStrategy();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const isAllSelected = competitorsInGroup.every((competitor) =>
    competitorsInGroupSelectedIds.includes(competitor.id)
  );
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: "",
    direction: "",
  });
  const type = strategy.strategyRule.pricesFormula.type;
  const valueInput = strategy.strategyRule.pricesFormula.value;
  const metric = strategy.strategyRule.pricesFormula.metric;
  const rate = strategy.strategyRule.pricesFormula.rate;
  const alerts = strategy.strategyRule?.alerts;
  const [value, setValue] = useState(valueInput);

  const options = [
    {
      element: "The highest price among competitors",
      checked: false,
      key: PriceFormulaType.HIGHEST,
    },
    {
      element: (
        <div className="df ">
          <span className="mr-8">The average price among competitors</span>
          <CustomTooltip
            title={"The average price is calculated as the arithmetic mean"}
            placement="right"
            arrow={true}
          >
            <Info />
          </CustomTooltip>
        </div>
      ),
      checked: false,
      key: PriceFormulaType.AVERAGE,
    },
    {
      element: "The lowest price among competitors",
      checked: false,
      key: PriceFormulaType.LOWEST,
    },
  ];

  const combineSaveToStore = (key: string, value: string) => {
    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        strategyRule: {
          ...state.strategy.strategyRule,
          alerts: [],
          pricesFormula: {
            ...state.strategy.strategyRule.pricesFormula,
            [key]: value,
          },
        },
      },
    }));
  };

  const handleClick = (option: any) => {
    combineSaveToStore("type", option.key);
    setValue("");
    const filteredAlerts = alerts?.filter(
      (alert) => alert.target !== AlertTarget.STRATEGY_RULE__DELETE_ALL_PRODUCTS
    );

    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        strategyRule: {
          ...state.strategy.strategyRule,
          alerts: filteredAlerts,
          pricesFormula: {
            ...state.strategy.strategyRule.pricesFormula,
            value: "",
            rate: "dollar",
            metric: "plus",
          },
        },
      },
    }));
    updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const onSearchChange = (target: any) => {
    setValue(target.value);
  };

  const handleBlur = () => {
    let valueLocal = value;

    if (value) {
      if (value.includes(".")) {
        const [integerPart, decimalPart] = value.split(".");
        const truncatedDecimal = decimalPart.substring(0, 2);
        valueLocal = `${integerPart}.${truncatedDecimal.padEnd(2, "0")}`;
      } else {
        valueLocal = `${value}.00`;
      }
      setValue(valueLocal);
      combineSaveToStore("value", valueLocal);
      updateStrategy(CurrentStep.STRATEGY_RULE);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key) && isNaN(Number(e.key)) && e.key !== ".") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const data = {
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
    };

    const queryObject = filteredCompareQueryObj(data) as IDataTransfer;
    if (!isShowModal && id) {
      getCompetitorsInGroup(id, queryObject);
    }
    return () => {
      removeSelectedCompetitorsIds();
    };
  }, [isShowModal, sortConfig]);

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!competitorsInGroup.length}
          onChange={(event) => selectCompetitorsInGroup(event.target.checked)}
        />
      ),
      className: "table-w-48 ",
    },
    {
      content: <div></div>,
      className: "table-w-32",
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Competitor name",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
          })}
        </>
      ),
      className: "table-w-492 ",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Category traffic",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.TRAFFIC === sortConfig.key,
            tooltipText: "Traffic per month",
          })}
        </>
      ),
      className: "table-w-180",
      sortKey: THEAD_SORT_KEYS.TRAFFIC,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: ICompetitor) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={competitorsInGroupSelectedIds.some((el) => el === id)}
              onChange={() => {
                selectCompetitorInGroup(id);
              }}
            ></Checkbox>
          </div>
        );
      },
      className: "df jc-c p-16",
    },
    {
      content: ({ id }: any) => {
        return (
          <>
            {expandedRowKeys.includes(id) ? (
              <div className="arrow__border-top">
                <ArrowBorder />
              </div>
            ) : (
              <div className="arrow__border-bottom">
                <ArrowBorder />
              </div>
            )}
          </>
        );
      },
      className: "",
    },
    {
      content: ({ name, externalLink, products }: ICompetitor) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <a href={externalLink} target="_blank">
              {name}
            </a>
            <span className="expanded__list-count">
              ({products.length} {getItemWord(products.length)})
            </span>
          </span>
        );
      },
      className: "table-item-title",
    },
    {
      content: ({ products }: ICompetitor) => {
        const count = products.reduce(
          (acc, el) => (acc = +el.productTraffic),
          0
        );
        return <div className="table-item-traffic">{count}</div>;
      },
      className: "table-item-title",
    },
  ];

  const handleExpanded = (item: string) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(item)) {
        return prevKeys.filter((key) => key !== item);
      } else {
        return [...prevKeys, item];
      }
    });
  };

  const dataTableBody = competitorsInGroup.map((item: ICompetitor) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      className: el.className,
      isSelected: competitorsInGroupSelectedIds.some((id) => id === item.id),
      isExpanded: expandedRowKeys.includes(item.id),
      item: item.id,
    }));
  });

  const isShowContentWithItems =
    strategy.strategyRule.competitors.length || competitorsInGroup.length;

  const metricActiveIndex =
    dataSign.findIndex((el) => el.key === metric) >= 0
      ? dataSign.findIndex((el) => el.key === metric)
      : 0;

  const rateActiveIndex =
    dataRate.findIndex((el) => el.key === rate) >= 0
      ? dataRate.findIndex((el) => el.key === rate)
      : 0;

  const onCloseWarning = () => {
    const { alerts } = strategy.strategyRule;
    const filteredAlerts = alerts?.filter(
      (el) => el.target !== AlertTarget.STRATEGY_RULE_ADD_NEW_PRODUCT
    );
    clearAlertToStore(CurrentStep.STRATEGY_RULE, filteredAlerts);
    updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const handleAddCompetitors = () => {
    setIsShowModal(true);
    onCloseWarning();
  };

  const textError =
    "Settings reset due to competitors removal. Please review and reconfigure the Strategy Rule.";

  const handleRemoveCompetitors = () => {
    removeCompetitors();
    if (competitorsInGroupSelectedIds.length === competitorsInGroup.length) {
      useGroupsStore.setState((state) => ({
        strategy: {
          ...state.strategy,
          strategyRule: {
            alerts: [],
            competitors: [],
            pricesFormula: {
              type: null,
              value: "",
              metric: Metric.PLUS,
              rate: Rate.DOLLAR,
              metricToGrow: "",
              metricToMaintain: "",
            },
          },
        },
      }));
      if (checkIsMoreStepThen(CurrentStep.PRICE_LIMITS)) {
        addAlertToStore(CurrentStep.STRATEGY_RULE, {
          type: AlertType.ERROR,
          message: htmlToString(<>{textError}</>),
          priority: 2,
          target: AlertTarget.STRATEGY_RULE__DELETE_ALL_COMPETITORS,
        });
        updateStrategy(CurrentStep.STRATEGY_RULE);
      }
    }
  };

  const alertError = alerts.find((alert) => alert.type === AlertType.ERROR);
  const alertWarning = alerts.find((alert) => alert.type === AlertType.WARNING);

  const getDynamicHeight = () => {
    const height = window.innerHeight;
    const alertHeight = 58;
    const defaultMinusValue = 560;
    const paddings = 14;

    const isErrorAlert = alerts.some((el) => {
      return el.type === AlertType.ERROR;
    });

    const isWarningAlert = alerts.some((el) => {
      return el.type === AlertType.WARNING;
    });

    // +20 -20

    const checkIsError = isErrorAlert ? alertHeight : 0;
    const checkIsWarning = isWarningAlert ? alertHeight : 0;

    const isAlert = isErrorAlert || isWarningAlert;
    const minusValue = isAlert ? 0 : 20;

    return (
      height -
      defaultMinusValue -
      checkIsError -
      checkIsWarning -
      paddings +
      18 +
      minusValue
    );
  };

  const render = () => {
    return isShowContentWithItems ? (
      <div className="groups-strategy-rule__wrapper">
        <h3 className="groups-strategy-rule__title">Strategy Rule</h3>
        <h5 className="groups-strategy-rule__subtitle">
          Configure the key strategy rule
        </h5>

        <div>
          {alertError ? (
            <AlertCustom
              type="error"
              message={alertError?.message}
              icon={<Error />}
              className="groups-strategy-rule__alert-error"
              closable={false}
            />
          ) : null}
        </div>

        <div className="groups-strategy-rule__content">
          {options.map((option, index) => (
            <div key={index} className="groups-strategy-rule__radio">
              <div className="groups-strategy-rule__radio-icon">
                <RadioCustom
                  checked={type === option.key}
                  onChange={() => handleClick(option)}
                />
              </div>
              <div
                className="groups-strategy-rule__radio-text"
                onClick={() => handleClick(option)}
              >
                {option.element}
              </div>

              {type === option.key &&
              option.key !== PriceFormulaType.AVERAGE ? (
                <div className="groups-strategy-rules__wrapper">
                  <SwitcherCustom
                    activeIndex={metricActiveIndex}
                    data={dataSign}
                    onClick={(metric) => {
                      combineSaveToStore("metric", metric);
                      updateStrategy(CurrentStep.STRATEGY_RULE);
                    }}
                  />
                  <InputCustom
                    onBlur={() => handleBlur()}
                    className="groups-strategy-rule__input"
                    type="string"
                    value={value}
                    name="search"
                    placeholder="0.00"
                    onChangeInput={({ target }) => {
                      onSearchChange(target);
                    }}
                    fill="gray-primary-900"
                    onKeyDown={handleKeyDown}
                  />
                  <SwitcherCustom
                    activeIndex={rateActiveIndex}
                    data={dataRate}
                    onClick={(rate) => {
                      combineSaveToStore("rate", rate);
                      updateStrategy(CurrentStep.STRATEGY_RULE);
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))}

          <div className="groups-strategy-rule__alert-warning">
            {alertWarning ? (
              <AlertCustom
                type="warning"
                onClose={onCloseWarning}
                message={alertWarning?.message}
                icon={<Warning />}
                closable={true}
              />
            ) : null}
          </div>

          <div>
            <div className="groups-strategy-rule__info-table">
              <h4 className="groups-strategy-rule__text dc">
                {competitorsInGroup.length}{" "}
                {getCompetitorWord(competitorsInGroup.length)}
              </h4>

              <ButtonStyled
                text="Add competitors"
                className="groups-strategy-rule__btn"
                icon={<Plus color="#000" />}
                onClick={handleAddCompetitors}
              />
            </div>
          </div>

          <div
            className="groups-strategy-rule__table"
            style={{ height: getDynamicHeight() }}
          >
            <TableCustom
              headData={headDataTable}
              bodyData={dataTableBody}
              onRowEvent={(item) => {
                handleExpanded(item);
              }}
              handleSort={handleSort}
              expandedRowRender={(rowItem: string) => {
                const expandedRow = competitorsInGroup.find(
                  (el) => el.id === rowItem
                );
                if (expandedRow) {
                  return expandedRowKeys.includes(rowItem) ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td colSpan={2} className="expanded__list-container">
                        <ul>
                          {expandedRow.products.map((product) => {
                            return (
                              <li key={product.id} className="expanded__list">
                                <a href={product.externalLink} target="_blank">
                                  <h5 className="expanded__list-item">
                                    {product.name}
                                  </h5>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                  ) : null;
                }
                return null;
              }}
            />
            {competitorsInGroupSelectedIds.length > 0 && (
              <div className="groups-strategy-rule__footer">
                <div className="selected__container">
                  <div className="selected__block mr-24">
                    <span className="selected__count">
                      {competitorsInGroupSelectedIds.length}
                    </span>
                    <span className="selected__text">selected</span>
                    <div className="groups-block-delete__btn">
                      <ButtonStyled
                        type="default"
                        icon={<Delete />}
                        text="Remove"
                        htmlType="button"
                        fill="danger_300"
                        className="groups-delete__btn"
                        onClick={handleRemoveCompetitors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* {competitorsInGroupSelectedIds.length > 0 && (
            <div className="groups-strategy-rule__footer">
              <div className="selected__container">
                <div className="selected__block mr-24">
                  <span className="selected__count">
                    {competitorsInGroupSelectedIds.length}
                  </span>
                  <span className="selected__text">selected</span>
                  <div className="groups-block-delete__btn">
                    <ButtonStyled
                      type="default"
                      icon={<Delete />}
                      text="Remove"
                      htmlType="button"
                      fill="danger_300"
                      className="groups-delete__btn"
                      onClick={handleRemoveCompetitors}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    ) : (
      <>
        <div className="groups-strategy-rule__wrapper">
          <h3 className="groups-strategy-rule__title">Strategy Rule</h3>
          <h5 className="groups-strategy-rule__subtitle">
            Configure the key pricing strategy rule
          </h5>

          <div className="groups-strategy-rule__content">
            {alertError ? (
              <AlertCustom
                type="error"
                message={alertError?.message}
                icon={<Error />}
                className="groups-strategy-rule__alert-error"
                closable={false}
              />
            ) : null}

            <div className="groups-strategy-rule__text">
              Add competitors first to start configuring the strategy rule
            </div>

            <div>
              <ButtonStyled
                type="primary"
                icon={<Plus />}
                text="Add competitors"
                htmlType="button"
                fill="gray-primary-900"
                className="groups-strategy-rule__btn"
                onClick={() => {
                  setIsShowModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <StrategyCompetitionPopup
        flag={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
      {render()}
    </>
  );
};

export default Competition;
