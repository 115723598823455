import axios from "axios";
import { getCookie } from "../cookie.utils";
import { ERROR_STATUS } from "@shared/interfaces";
import userService from "@entities/user/api";

class BaseService {
  baseURL: string | undefined;
  baseServiceInstance;
  isRetry: boolean = false;

  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_URL;

    this.baseServiceInstance = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
    });

    this.baseServiceInstance.defaults.withCredentials = true;
  }
}

export class ApiService extends BaseService {
  constructor() {
    super();

    this.baseServiceInstance.interceptors.request.use(this.authInterceptor);
    this.baseServiceInstance.interceptors.response.use(
      this.handleResponseInterceptor,
      this.handleResponseInterceptorError
    );
  }

  authInterceptor(config: any) {
    const setHeaders = () => {
      const contentType = "application/json";

      const isFormData =
        config.headers["Content-Type"] === "multipart/form-data" && true;

      const headers: any = {
        Authorization: `Bearer ${localStorage.getItem("_accessToken")}`,
        //["CSRF-Token"]: `${getCookie("XSRF-TOKEN") || ""}`,
      };

      if (!isFormData) {
        headers["Accept"] = contentType;
        headers["Content-Type"] = contentType;
      }

      return headers;
    };

    config.headers = setHeaders();

    return config;
  }

  handleResponseInterceptor(response: any) {
    return response;
  }

  handleResponseInterceptorError = async (error: any) => {
    const response = error.response;
    const status = error.status;

    const originalRequest = error.config;

    if (
      status === ERROR_STATUS.UNAUTHORIZED_401 &&
      error.config &&
      !this.isRetry
    ) {
      this.isRetry = true;

      try {
        const { data } = await this.baseServiceInstance.get(
          "/api/v1/auth/refresh"
        );

        this.isRetry = false;

        localStorage.setItem("_accessToken", data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

        return await axios.request(originalRequest);
      } catch (e) {
        localStorage.removeItem("_accessToken");
        console.log("Refresh error");
        window.location.replace("/");

        return;
      }
    }

    throw error;
  };
}
