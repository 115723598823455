import { AuthWrapper, LoginWidget } from "@widgets/auth/index";

const LogInPage = () => {
  return (
    <AuthWrapper>
      <LoginWidget />
    </AuthWrapper>
  );
};

export default LogInPage;
