import { CustomTooltip } from "@shared/ui/components";
import "./updatePolicy.scss";

import { Switch } from "antd";

import { Info } from "@shared/ui/assets";
import {
  AutoRepricingEnum,
  CurrentStep,
  useGroupsStore,
} from "@entities/groups";

const UpdatePolicy = () => {
  const { updatePolicy } = useGroupsStore((state) => state.strategy);
  const { updateStrategy } = useGroupsStore((state) => state);
  const chooseCheckValue = () => {
    if (updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_ON) {
      return true;
    }

    if (updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_OFF) {
      return false;
    }

    return false;
  };

  const checked = (flag: boolean) => {
    const autoRepricingChooseValue = flag
      ? AutoRepricingEnum.AUTO_REPRICING_ON
      : AutoRepricingEnum.AUTO_REPRICING_OFF;

    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        [CurrentStep.UPDATE_POLICY]: {
          value: autoRepricingChooseValue,
        },
      },
    }));
    updateStrategy(CurrentStep.UPDATE_POLICY);
  };

  const onTextClick = () => {
    let flag = true;

    if (updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_ON) {
      flag = false;
    }

    if (updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_OFF) {
      flag = true;
    }

    checked(flag);
  };

  return (
    <div className="groups-policy__wrapper">
      <h3 className="groups-policy__title">Update Policy</h3>
      <h5 className="groups-policy__subtitle">New price update details</h5>

      <h5 className="groups-policy__text">
        New price recommendations are updated daily at 00:01.
      </h5>
      <h5 className="groups-policy__text">
        All new prices require your approval before application.
      </h5>

      <div className="groups-switch">
        <Switch onChange={checked} checked={chooseCheckValue()} />
        <h5 onClick={() => onTextClick()}>Auto repricing</h5>
        <CustomTooltip
          title={
            "If enable auto repricing new prices will be applied automatically. This feature is currently in beta mode. Customization coming soon."
          }
          placement="right"
          arrow={true}
        >
          <Info />
        </CustomTooltip>
      </div>
    </div>
  );
};

export default UpdatePolicy;
