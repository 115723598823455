import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import productService from "../api";
import { IProduct, IProductState } from "./product.types";
import { IDataTransfer, IQuery } from "@shared/interfaces";

export const useProductsStore = create<IProductState>()(
  devtools(
    immer((set, get) => ({
      products: [],
      recordsCount: 0,
      productsSelectedIds: [],
      getProducts: async (params: IDataTransfer) => {
        try {
          const { data } = await productService.getProducts(params);
          set({
            products: data.items,
            recordsCount: data.count,
          });
        } catch (e) {
          console.log(e);
        }
      },

      setProducts: async (products: IProduct[], recordsCount: number) => {
        try {
          set({
            products,
            recordsCount: recordsCount,
          });
        } catch (e) {
          console.log(e);
        }
      },
      selectProduct: (productId: string) => {
        const productsSelectedIds = get().productsSelectedIds;
        const indexOfSelectedProduct = productsSelectedIds.findIndex(
          (id) => id === productId
        );

        if (indexOfSelectedProduct !== -1) {
          const updatedProductsSelectedIds = [
            ...productsSelectedIds.slice(0, indexOfSelectedProduct),
            ...productsSelectedIds.slice(indexOfSelectedProduct + 1),
          ];
          set({ productsSelectedIds: updatedProductsSelectedIds });
        } else {
          set({ productsSelectedIds: [...productsSelectedIds, productId] });
        }
      },
      setProductsSelectedIds: (arr: string[]) => {
        set({ productsSelectedIds: arr });
      },
      selectAllProducts: (flag: boolean) => {
        const products = get().products;
        const productsSelectedIds = get().productsSelectedIds;

        if (flag) {
          set({
            productsSelectedIds: Array.from(
              new Set([
                ...productsSelectedIds.concat(
                  products.map((product) => product.id)
                ),
              ])
            ),
          });
        } else {
          set({
            productsSelectedIds: productsSelectedIds.filter(
              (id) => !products.find((product) => product.id === id)
            ),
          });
        }
      },
    }))
  )
);
