import { InputCustom } from "@shared/ui/components";
import "./groupsBulkAction.scss";
import { Search } from "@shared/ui/assets";
import { GroupsFilters } from "@widgets/admin/groups";
import { useDebouncedCallback } from "use-debounce";
import { NavLink, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Alert } from "antd";
import { useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { ROUTES } from "@shared/interfaces";

const GroupsBulkAction = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");
  const { newPricesAvailable, showUpdatePriceAlert, setGroups } =
    useGroupsStore((state) => state);

  const debounced = useDebouncedCallback(async (value) => {
    searchParams.set("search", value);

    if (!value || value === "") {
      searchParams.delete("search");
    }

    setSearchParams(searchParams);
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
    debounced(value);
  };

  const alertText = (
    <h5 className="alert__content">
      New prices are available for your{" "}
      <NavLink
        to={`${ROUTES.ADMIN_GROUPS}/create/${
          newPricesAvailable[0]?.id || null
        }`}
      >
        {newPricesAvailable[0]?.name}.
      </NavLink>
      Review and confirm these changes to keep your pricing strategy
      competitive.
    </h5>
  );

  return (
    <div className="ml-24 mr-24">
      {newPricesAvailable.length ? (
        <Alert
          showIcon
          closable
          type="info"
          message={alertText}
          className="mt-12 mb-12"
          onClose={() => showUpdatePriceAlert([])}
        />
      ) : null}

      <div className="groups-bulk__container">
        <div className="mr-32">
          <InputCustom
            type="text"
            value={searchValue}
            name="group-search"
            placeholder="Search for group"
            onChangeInput={onSearchChange}
            fill="gray-primary-900"
            iconRight={<Search />}
          />
        </div>
        <GroupsFilters />
      </div>
    </div>
  );
};

export default GroupsBulkAction;
