import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import { SortParams } from "@shared/interfaces";
import { ArrowBorder, Close, Search } from "@shared/ui/assets";
import { Checkbox, Divider, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import "./strategyCompetitionPopup.scss";
import { generateTextColumn } from "@shared/common";
import { ISortParams } from "@shared/interfaces";
import { ButtonStyled, InputCustom, LoaderBlock } from "@shared/ui/components";
import { CurrentStep, ICompetitor, useGroupsStore } from "@entities/groups";
import { useParams } from "react-router-dom";
import { filteredCompareQueryObj, getItemWord } from "@shared/utils";

import { useDebounce } from "use-debounce";
import {
  CATEGORY_PARAM,
  DIRECTION_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";

interface IProps {
  flag: boolean;
  onClose: () => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  TRAFFIC = "categoryTraffic",
}

const StrategyCompetitionPopup: FC<IProps> = (props) => {
  const { flag, onClose } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedValue] = useDebounce(searchValue, 500);
  const [monthlyCategoryTraffic, setMonthlyCategoryTraffic] =
    useState<number>(0);

  const { id } = useParams();
  const {
    getAllCompetitors,
    selectCompetitorsAll,
    selectCompetitor,
    getMonthlyCategoryTraffic,
    addCompetitorsToGroup,
    setCompetitorsAllSelectedIds,
    clearCompetitorsAll,
    isLoading,
  } = useGroupsStore((state) => state);
  const competitorsAll = useGroupsStore((state) => state.competitorsAll);
  const competitorsAllSelectedIds = useGroupsStore(
    (state) => state.competitorsAllSelectedIds
  );
  const isAllSelected = (competitorsAll || [])?.every((competitor) =>
    competitorsAllSelectedIds.includes(competitor.id)
  );

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: "",
    direction: "",
  });

  useEffect(() => {
    const compareQueryObj = {
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
      [SEARCH_PARAM]: debouncedValue,
    };
    const queryObject = filteredCompareQueryObj(compareQueryObj);
    if (id && flag) {
      getAllCompetitors(id, queryObject);
    }

    return () => {
      setCompetitorsAllSelectedIds([]);
      clearCompetitorsAll();
    };
  }, [flag, sortConfig, debouncedValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id && flag) {
          const data = await getMonthlyCategoryTraffic(id);
          setMonthlyCategoryTraffic(data.monthlyTraffic);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

    return () => {
      setSearchValue("");
    };
  }, [flag]);

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!competitorsAll.length}
          onChange={(event) => selectCompetitorsAll(event.target.checked)}
        />
      ),
      className: "table-w-32 table__checkbox",
    },
    {
      content: <div></div>,
      className: "table-w-16 table__arrow",
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Competitor name",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
          })}
        </>
      ),
      className: "table-w-544",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: (
        <>
          {generateTextColumn({
            text: "Category traffic",
            isSorted: true,
            sortDirection: sortConfig.direction,
            isActiveSort: THEAD_SORT_KEYS.TRAFFIC === sortConfig.key,
            tooltipText: "Traffic per month",
          })}
        </>
      ),
      className: "table-w-180",
      sortKey: THEAD_SORT_KEYS.TRAFFIC,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: ICompetitor) => {
        return (
          <div onClick={(e) => e.stopPropagation()} className="">
            <Checkbox
              checked={competitorsAllSelectedIds.some((el) => el === id)}
              onChange={(event) => {
                selectCompetitor(id);
              }}
            ></Checkbox>
          </div>
        );
      },
      className: "table-w-32 table__checkbox",
    },
    {
      content: ({ id }: any) => {
        return (
          <>
            {expandedRowKeys.includes(id) ? (
              <div className="arrow__border-top">
                <ArrowBorder />
              </div>
            ) : (
              <div className="arrow__border-bottom">
                <ArrowBorder />
              </div>
            )}
          </>
        );
      },
      className: "table__arrow",
    },
    {
      content: ({ name, externalLink, products }: ICompetitor) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <a href={externalLink} target="_blank">
              {name}
            </a>
            <span className="expanded__list-count">
              ({products.length} {getItemWord(products.length)})
            </span>
          </span>
        );
      },
      className: "table-item-title",
    },
    {
      content: ({ products }: ICompetitor) => {
        const count = products.reduce(
          (acc, el) => (acc = +el.productTraffic),
          0
        );
        return <div className="table-item-traffic">{count}</div>;
      },
      className: "table-item-title",
    },
  ];

  const handleExpanded = (item: string) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(item)) {
        return prevKeys.filter((key) => key !== item);
      } else {
        return [...prevKeys, item];
      }
    });
  };

  const dataTableBody = competitorsAll.map((item: ICompetitor | any) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      className: el.className,
      isSelected: competitorsAllSelectedIds.some((id) => id === item.id),
      isExpanded: expandedRowKeys.includes(item.id),
      item: item.id,
    }));
  });

  const handleChangeInput = (target: any) => {
    const value = target.value;
    setSearchValue(value);
  };

  const handleClickAdd = async () => {
    await addCompetitorsToGroup();
    onClose();
  };

  const isDisableAddBtnClass = competitorsAllSelectedIds.length
    ? ""
    : "disabled";

  const chooseShowNoEmptyData = !competitorsAll.length ? (
    <>
      <div>You have already added all suggested direct competitors.</div>
      <div>No more available to add at this time.</div>
    </>
  ) : (
    ""
  );

  return (
    <Modal
      open={flag}
      onOk={() => {}}
      className="modal__competition"
      maskClosable={false}
      closeIcon={false}
      centered={true}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onClose}
            />

            <ButtonStyled
              type="primary"
              text="Add"
              htmlType="button"
              fill="gray-primary-900"
              className={`w-84 ${isDisableAddBtnClass}`}
              // icon={<ArrowRight />}
              onClick={handleClickAdd}
              // iconPosition="end"
            />
          </div>
        </div>,
      ]}
    >
      <div className="modal__container">
        <div onClick={() => onClose()} className="modal-close__icon">
          <Close />
        </div>
        <h2 className="modal__title">
          Add competitors
          <p className="groups-strategy__subtitle">
            View direct competitors offering identical product items
          </p>
        </h2>
        <Divider />
        <div className="modal__content">
          <div className="modal__body modal__body-competition">
            <div className="modal__search-competitor-block">
              <InputCustom
                type="text"
                value={searchValue}
                name="items-search"
                placeholder="Search for competitors"
                onChangeInput={({ target }) => handleChangeInput(target)}
                fill="gray-primary-900"
                iconRight={<Search />}
                // className={`${isDisabledItems}`}
              />
              <div>
                Your monthly category traffic is <b>{monthlyCategoryTraffic}</b>
              </div>
            </div>
            <div className="modal__table">
              {isLoading ? (
                <>
                  <LoaderBlock />
                </>
              ) : competitorsAll.length ? (
                <TableCustom
                  isModalMode={true}
                  // noDataText={chooseShowNoEmptyData}
                  headData={headDataTable}
                  bodyData={dataTableBody}
                  onRowEvent={(item) => {
                    handleExpanded(item);
                  }}
                  handleSort={handleSort}
                  expandedRowRender={(rowItem: string) => {
                    const expandedRow = competitorsAll.find(
                      (el) => el.id === rowItem
                    );
                    if (expandedRow) {
                      return expandedRowKeys.includes(rowItem) ? (
                        <tr>
                          <td></td>
                          <td className="disable-padding"></td>
                          <td colSpan={2} className="expanded__list-container">
                            <ul>
                              {expandedRow.products.map((product) => {
                                return (
                                  <li
                                    key={product.id}
                                    className="expanded__list"
                                  >
                                    <a
                                      className="expanded__list-link"
                                      href={product.externalLink}
                                      target="_blank"
                                    >
                                      <h5 className="expanded__list-item">
                                        {product.name}
                                      </h5>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                        </tr>
                      ) : null;
                    }
                    return null;
                  }}
                />
              ) : (
                <div className="df jc-c ai-center no__data">
                  <div className="ta-center">{chooseShowNoEmptyData}</div>
                </div>
              )}
            </div>
            <div className="selected__container">
              <div className="selected__block mr-24">
                <span className="selected__count">
                  {competitorsAllSelectedIds.length || 0}
                </span>
                <span className="selected__text">selected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StrategyCompetitionPopup;
