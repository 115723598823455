interface IProps {
  color?: string;
}

const Lock = ({ color = "#DC3545" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M15.0007 7.16658H14.1673V5.49992C14.1673 3.19992 12.3007 1.33325 10.0007 1.33325C7.70065 1.33325 5.83398 3.19992 5.83398 5.49992V7.16658H5.00065C4.08398 7.16658 3.33398 7.91658 3.33398 8.83325V17.1666C3.33398 18.0833 4.08398 18.8333 5.00065 18.8333H15.0007C15.9173 18.8333 16.6673 18.0833 16.6673 17.1666V8.83325C16.6673 7.91658 15.9173 7.16658 15.0007 7.16658ZM10.0007 14.6666C9.08398 14.6666 8.33398 13.9166 8.33398 12.9999C8.33398 12.0833 9.08398 11.3333 10.0007 11.3333C10.9173 11.3333 11.6673 12.0833 11.6673 12.9999C11.6673 13.9166 10.9173 14.6666 10.0007 14.6666ZM7.50065 7.16658V5.49992C7.50065 4.11659 8.61732 2.99992 10.0007 2.99992C11.384 2.99992 12.5007 4.11659 12.5007 5.49992V7.16658H7.50065Z"
      fill={color}
    />
  </svg>
);

export default Lock;
