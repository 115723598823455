import "./strategyRule.scss";
import Competition from "./Competition/Competition";
import Elasticity from "./Elasticity/Elasticity";
import {
  AlertTarget,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import { useEffect } from "react";

const StrategyRule = () => {
  const typeOfRule = useGroupsStore((state) => state.strategy.type);
  const strategyRule = useGroupsStore((state) => state.strategy.strategyRule);
  const { clearAlertToStore, updateStrategy } = useGroupsStore(
    (state) => state
  );

  useEffect(() => {
    const {
      competitors,
      alerts,
      pricesFormula: { metricToGrow, type },
    } = strategyRule;
    if ((competitors.length && type) || metricToGrow) {
      const targetChangeStrategy = AlertTarget.STRATEGY_RULE__CHANGE_STRATEGY;
      const targetDeleteAllCompetitors =
        AlertTarget.STRATEGY_RULE__DELETE_ALL_COMPETITORS;
      if (
        (alerts.length &&
          alerts.some((el) => el.target === targetChangeStrategy)) ||
        alerts.some((el) => el.target === targetDeleteAllCompetitors)
      ) {
        clearAlertToStore(
          CurrentStep.STRATEGY_RULE,
          alerts.filter(
            (el) =>
              el.target !== targetChangeStrategy &&
              el.target !== targetDeleteAllCompetitors
          )
        );

        updateStrategy(CurrentStep.STRATEGY_RULE);
      }
    }
  }, [strategyRule]);

  const renderObj = {
    [StrategyTypeEnum.ELASTICITY]: <Elasticity />,
    [StrategyTypeEnum.COMPETITION]: <Competition />,
  };

  if (!typeOfRule) {
    return <></>;
  }

  return <>{renderObj[typeOfRule]}</>;
};

export default StrategyRule;
