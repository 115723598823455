import { ROUTES } from "@shared/interfaces";
import { Typography } from "antd";
import { AuthWrapper } from "@widgets/auth/index";

const { Title, Link } = Typography;

const ForgotPasswordPage = () => {
  return (
    <AuthWrapper>
      <div>
        <Title level={2}>ForgotPasswordPage</Title>
        <Link href={ROUTES.LOGIN}>LOGIN</Link>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
