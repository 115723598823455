interface IProps {
  color?: string;
}

const Arrow = ({ color= '#6C757D' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_7740_3530)">
    <path d="M12.667 7.33323H5.22031L8.47365 4.0799C8.73365 3.8199 8.73365 3.39323 8.47365 3.13323C8.21365 2.87323 7.79365 2.87323 7.53365 3.13323L3.14031 7.52657C2.88031 7.78657 2.88031 8.20657 3.14031 8.46657L7.53365 12.8599C7.79365 13.1199 8.21365 13.1199 8.47365 12.8599C8.73365 12.5999 8.73365 12.1799 8.47365 11.9199L5.22031 8.66657H12.667C13.0336 8.66657 13.3336 8.36657 13.3336 7.9999C13.3336 7.63323 13.0336 7.33323 12.667 7.33323Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_7740_3530">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default Arrow;