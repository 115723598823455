interface IProps {
  color?: string;
}

const Unlock = ({ color = "#198754" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5.00065 7.16659H12.5007V5.49992C12.5007 4.80547 12.2576 4.2152 11.7715 3.72909C11.2854 3.24297 10.6951 2.99992 10.0007 2.99992C9.30621 2.99992 8.71593 3.24297 8.22982 3.72909C7.74371 4.2152 7.50065 4.80547 7.50065 5.49992H5.83398C5.83398 4.34714 6.24023 3.3645 7.05273 2.552C7.86523 1.7395 8.84787 1.33325 10.0007 1.33325C11.1534 1.33325 12.1361 1.7395 12.9486 2.552C13.7611 3.3645 14.1673 4.34714 14.1673 5.49992V7.16659H15.0007C15.459 7.16659 15.8513 7.32978 16.1777 7.65617C16.5041 7.98256 16.6673 8.37492 16.6673 8.83325V17.1666C16.6673 17.6249 16.5041 18.0173 16.1777 18.3437C15.8513 18.6701 15.459 18.8333 15.0007 18.8333H5.00065C4.54232 18.8333 4.14996 18.6701 3.82357 18.3437C3.49718 18.0173 3.33398 17.6249 3.33398 17.1666V8.83325C3.33398 8.37492 3.49718 7.98256 3.82357 7.65617C4.14996 7.32978 4.54232 7.16659 5.00065 7.16659ZM5.00065 17.1666H15.0007V8.83325H5.00065V17.1666ZM10.0007 14.6666C10.459 14.6666 10.8513 14.5034 11.1777 14.177C11.5041 13.8506 11.6673 13.4583 11.6673 12.9999C11.6673 12.5416 11.5041 12.1492 11.1777 11.8228C10.8513 11.4964 10.459 11.3333 10.0007 11.3333C9.54232 11.3333 9.14996 11.4964 8.82357 11.8228C8.49718 12.1492 8.33398 12.5416 8.33398 12.9999C8.33398 13.4583 8.49718 13.8506 8.82357 14.177C9.14996 14.5034 9.54232 14.6666 10.0007 14.6666Z"
      fill={color}
    />
  </svg>
);

export default Unlock;
