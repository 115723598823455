import React from "react";

const Warning = ({ color = "#FFC107" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.02 3.29809C10.9169 3.11847 10.7682 2.96924 10.589 2.86546C10.4097 2.76168 10.2063 2.70703 9.99917 2.70703C9.79205 2.70703 9.58861 2.76168 9.40937 2.86546C9.23013 2.96924 9.08145 3.11847 8.97833 3.29809L1.83563 15.4512C1.35959 16.2616 1.93042 17.2918 2.85646 17.2918H17.1408C18.0669 17.2918 18.6388 16.2606 18.1617 15.4512L11.02 3.29809ZM9.99708 6.87517C10.5544 6.87517 10.9908 7.35642 10.9346 7.91163L10.57 11.5648C10.5578 11.7083 10.4921 11.842 10.386 11.9394C10.2799 12.0368 10.1411 12.0909 9.99708 12.0909C9.85305 12.0909 9.71426 12.0368 9.60817 11.9394C9.50208 11.842 9.43642 11.7083 9.42417 11.5648L9.05959 7.91163C9.04649 7.78063 9.06099 7.64833 9.10213 7.52326C9.14328 7.3982 9.21017 7.28314 9.29848 7.1855C9.3868 7.08786 9.4946 7.0098 9.61492 6.95635C9.73524 6.9029 9.86543 6.87525 9.99708 6.87517ZM9.99917 13.1252C10.2754 13.1252 10.5404 13.2349 10.7357 13.4303C10.9311 13.6256 11.0408 13.8906 11.0408 14.1668C11.0408 14.4431 10.9311 14.7081 10.7357 14.9034C10.5404 15.0988 10.2754 15.2085 9.99917 15.2085C9.7229 15.2085 9.45795 15.0988 9.2626 14.9034C9.06725 14.7081 8.9575 14.4431 8.9575 14.1668C8.9575 13.8906 9.06725 13.6256 9.2626 13.4303C9.45795 13.2349 9.7229 13.1252 9.99917 13.1252Z"
      fill={color}
    />
  </svg>
);

export default Warning;
