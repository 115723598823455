interface IProps {
  color?: string;
}

const Plus = ({ color = "#ffffff" }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g clipPath="url(#clip0_7682_16324)">
      <path
        d="M12.5007 8.66659H9.16732V11.9999C9.16732 12.3666 8.86732 12.6666 8.50065 12.6666C8.13398 12.6666 7.83398 12.3666 7.83398 11.9999V8.66659H4.50065C4.13398 8.66659 3.83398 8.36659 3.83398 7.99992C3.83398 7.63325 4.13398 7.33325 4.50065 7.33325H7.83398V3.99992C7.83398 3.63325 8.13398 3.33325 8.50065 3.33325C8.86732 3.33325 9.16732 3.63325 9.16732 3.99992V7.33325H12.5007C12.8673 7.33325 13.1673 7.63325 13.1673 7.99992C13.1673 8.36659 12.8673 8.66659 12.5007 8.66659Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_7682_16324">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Plus;
