import { Modal } from "antd";
import "./strategyType.scss";
import { useState } from "react";
import { ButtonStyled, RadioCustom } from "@shared/ui/components";
import {
  AlertTarget,
  AlertType,
  CurrentStep,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import useStrategy from "@shared/model/hooks/useStrategy";
import { htmlToString } from "@shared/utils";

const strategyArr = [
  {
    title: "Competition",
    description:
      "This strategy looks at what other stores are charging for the same products. It helps set prices that keep you competitive in the market.",
    checked: false,
    key: StrategyTypeEnum.COMPETITION,
  },
  {
    title: "Price Elasticity",
    description:
      "This strategy uses past sales data to understand how price changes affect customer buying behavior. It aims to find the best price that will increase profits, sales (units) or revenue.",
    checked: false,
    key: StrategyTypeEnum.ELASTICITY,
  },
];

const textError =
  "Settings reset due to Strategy Type changes. Please review and reconfigure the Strategy Rule.";

const StrategyType = () => {
  const strategyType = useGroupsStore((state) => state.strategy.type);

  const { setStrategyType, clearStrategyKey, addAlertToStore, updateStrategy } =
    useGroupsStore((state) => state);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<StrategyTypeEnum | null>(null);
  const { checkIsMoreStepThen } = useStrategy();

  const getActiveStyle = (type: StrategyTypeEnum) => {
    const activeStyle = strategyType === type ? "active" : "";
    return `strategy__content-item ${activeStyle}`;
  };

  const handleClick = async (key: StrategyTypeEnum) => {
    setStrategyType(key);

    clearStrategyKey(CurrentStep.STRATEGY_RULE);
    addAlertToStore(CurrentStep.STRATEGY_RULE, {
      type: AlertType.ERROR,
      message: htmlToString(<>{textError}</>),
      priority: 2,
      target: AlertTarget.STRATEGY_RULE__CHANGE_STRATEGY,
    });
    updateStrategy(CurrentStep.STRATEGY_TYPE);
    updateStrategy(CurrentStep.STRATEGY_RULE);
  };

  const handleOk = async () => {
    if (selectedKey) {
      handleClick(selectedKey);
    }

    setIsModalInfoOpen(false);
  };

  const handleCancel = () => {
    setIsModalInfoOpen(false);
  };

  const handleChange = (key: StrategyTypeEnum) => {
    if (key !== strategyType) {
      if (checkIsMoreStepThen(CurrentStep.PRICE_LIMITS)) {
        setSelectedKey(key);
        setIsModalInfoOpen(true);
      } else {
        setSelectedKey(key);
        setStrategyType(key);
        updateStrategy(CurrentStep.STRATEGY_TYPE);
      }
    }
  };

  const modalInfoRender = () => (
    <Modal
      title={<div className="modal__title">Change Strategy Type</div>}
      open={isModalInfoOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      closable={true}
      maskClosable={false}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={handleCancel}
            />

            <ButtonStyled
              type="default"
              // icon={<Delete />}
              text="Confirm"
              htmlType="button"
              fill="danger-300"
              className="w-84 strategy-delete__btn"
              onClick={handleOk}
            />
          </div>
        </div>,
      ]}
    >
      <div className="confirm-modal-body-info">
        <span>
          Changing the strategy type will reset your current settings in the
          "Strategy Rule" tab. You will need to review and reconfigure the
          "Strategy Rule" tab after this change.
        </span>
      </div>
    </Modal>
  );

  return (
    <>
      {modalInfoRender()}
      <div className="groups-strategy__wrapper content__container">
        <h3 className="groups-strategy__title">Pricing Strategy Type</h3>
        <h5 className="groups-strategy__subtitle">
          Select a pricing strategy type that best fits your needs
        </h5>
        <div className="groups-strategy__content">
          {strategyArr.map((el) => {
            return (
              <div
                key={el.key}
                className={getActiveStyle(el.key)}
                onClick={() => handleChange(el.key)}
              >
                <h2 className="strategy__content-item-title">{el.title}</h2>
                <h5 className="strategy__content-item-text">
                  {el.description}
                </h5>
                <div className="strategy__item-radio">
                  <RadioCustom checked={strategyType === el.key} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StrategyType;
