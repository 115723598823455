import { LayoutWithAside } from "@widgets/admin";
import { GroupsEditTitle, LayoutWithStepper } from "@widgets/admin/groups";

const CreateGroup = () => {
  return (
    <LayoutWithAside>
      <>
        <GroupsEditTitle />
        <LayoutWithStepper />
      </>
    </LayoutWithAside>
  );
};

export default CreateGroup;
