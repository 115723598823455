import "./SignupWelcomeWidget.scss";
import { ROUTES } from "@shared/interfaces";
import { SignupForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { FC } from "react";

const { Title, Link } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

const SignupWelcomeWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup__container">
      <Title level={1} className="signup__title">
        The Relu
      </Title>
      <Title level={1} className="signup__subtitle">
        Welcome!
      </Title>
      <Title level={4} className="signup__text">
        Enter your email to proceed
      </Title>
      <SignupForm onContinueClick={onContinueClick} />
      <Title level={4} className="signup-terms__text">
        By clicking continue, you agree to our
        <Link href={ROUTES.LOGIN}>Terms of Service</Link>
        and
        <Link href={ROUTES.LOGIN}>Privacy Policy</Link>
      </Title>

      <Title level={4} className="signup-terms__text signup-account__text">
        Have an account?
        <Link href={ROUTES.LOGIN}>Log in</Link>
      </Title>
    </div>
  );
};

export default SignupWelcomeWidget;
