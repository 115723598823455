import React, { FC } from "react";
import "./radioCustom.scss";

interface IProps {
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  disabled?: boolean;
}

const RadioCustom: FC<IProps> = ({
  checked,
  onChange,
  name,
  value,
  disabled = false,
}) => {
  return (
    <input
      type="radio"
      className="strategy__content-item-radio"
      checked={checked}
      disabled={disabled}
      onChange={(e) => onChange && onChange(e)}
    />
  );
};

export default RadioCustom;
