import { Select } from "antd";
import "./pagination.scss";
import { FC } from "react";
import { ArrowBorder } from "@shared/ui/assets";

const ITEMS_PER_PAGE = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
];

interface IProps {
  currentLimit: string;
  recordsCount: number;
  currentOffset: string;
  currentSelectLimit: string;
  onChange: (e: string) => void;
  prevPage: (currentOffset: string) => void;
  nextPage: (currentOffset: string) => void;
}

const Pagination: FC<IProps> = ({
  onChange,
  prevPage,
  nextPage,
  currentLimit,
  currentOffset,
  recordsCount,
  currentSelectLimit,
}: IProps) => {
  const arrowsClasses = ["pagination-arrows"];
  const isDisabledNext = +currentLimit >= +recordsCount;
  const isDisabledPrev = +currentOffset <= 1;

  if (isDisabledNext) {
    arrowsClasses.push("disabled-next");
  }

  if (isDisabledPrev) {
    arrowsClasses.push("disabled-prev");
  }

  return (
    <div className="df ai-center">
      <h5 className="pagination__text">Items per page</h5>
      <Select
        onChange={onChange}
        style={{ width: 76 }}
        options={ITEMS_PER_PAGE}
        suffixIcon={<ArrowBorder color="#212527" />}
        defaultValue={currentSelectLimit}
        className="custom-arrow"
      />
      <h5 className="pagination__text ml-32">
        {currentOffset} - {currentLimit} of {recordsCount}
      </h5>
      <div className={arrowsClasses.join(" ")}>
        <ArrowBorder
          color={!isDisabledPrev ? "#6C757D" : "#CED4DA"}
          onClick={() => prevPage(currentOffset)}
        />
        <ArrowBorder
          color={!isDisabledNext ? "#6C757D" : "#CED4DA"}
          onClick={() => nextPage(currentOffset)}
        />
      </div>
    </div>
  );
};

export default Pagination;
