import { CurrentStep, STEP_KEYS, useGroupsStore } from "@entities/groups";
import React from "react";

const useStrategy = () => {
  const { keyOfActiveStep } = useGroupsStore((state) => state);
  const checkIsMoreStepThen = (step: CurrentStep) => {
    const isStepWasted =
      STEP_KEYS.indexOf(step) <= STEP_KEYS.indexOf(keyOfActiveStep);

    return isStepWasted;
  };
  return { checkIsMoreStepThen };
};

export default useStrategy;
