import { Alert } from "antd";
import "./alertCustom.scss";
import React, { FC } from "react";

interface AlertCustomProps {
  type?: "info" | "success" | "error" | "warning" | "default";
  message: React.ReactNode;
  icon?: React.ReactNode;
  showIcon?: boolean;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
}

const AlertCustom: FC<AlertCustomProps> = ({
  type = "default",
  message = "This is a notification message.",
  icon,
  showIcon = true,
  className,
  closable = false,
  onClose,
}) => {
  return (
    <Alert
      showIcon={showIcon}
      icon={icon ?? null}
      closable={closable}
      onClose={onClose}
      className={`alert-custom ${type} ${className}`}
      message={message}
    />
  );
};

export default AlertCustom;
