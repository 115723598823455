export enum ROUTES {
  BASE = "/",
  SIGN_IN = "/sign_in",
  LOGIN = "/log_in",
  FORGOT_PASSWORD = "/forgot_password",
  CREATE_PASSWORD = "/create_password",
  ADMIN = "/admin",
  ADMIN_GROUPS = "/admin/groups",
  ADMIN_GROUPS_ID = "/admin/groups/:id",
  ADMIN_SETTINGS = "/admin/settings",
  ADMIN_KNOWLEDGE_BASE = "/admin/knowledge_base",
  ADMIN_PROFILE = "/admin/profile",
  NOT_FOUND = "*",

  ADMIN_GROUPS_CREATE = "/admin/groups/create",
  ADMIN_GROUPS_CREATE_ID = "/admin/groups/create/:id",
  ADMIN_GROUPS_EDIT = "/admin/groups/edit",
  ADMIN_GROUPS_EDIT_ID = "/admin/groups/edit/:id",
}

export interface IRoute {
  path: ROUTES;
  element: React.ReactElement;
}
