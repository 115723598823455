import { FC, useEffect, useRef, useState } from "react";
import "./itemInfo.scss";
import { Close } from "@shared/ui/assets";
import { Tabs } from "antd";
import ItemOverview from "./ItemOverview/ItemOverview";
import { useGroupsStore } from "@entities/groups";
import ItemMarket from "./ItemMarket/ItemMarket";
import ItemPriceElasticity from "./ItemPriceElasticity/ItemPriceElasticity";
import { BadgeCustom } from "@shared/ui/components";

interface IProps {
  onClose: (flag: boolean) => void;
  productId: string;
}

const ItemInfo: FC<IProps> = (props) => {
  const { onClose, productId } = props;
  const { productsInGroup } = useGroupsStore((state) => state);
  const currentProduct = productsInGroup.find(
    (product) => product.id === productId
  );

  const drawerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node)
    ) {
      onClose(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (!currentProduct) {
    return null;
  }
  const { name, article, brand, store, externalLink } = currentProduct;

  const tabOptions: { [key: string]: any }[] = [
    {
      label: <h4 className="tab-title default-tab"> Overview </h4>,
      key: "1",
      disabled: false,
      children: <ItemOverview productId={productId} />,
    },
    {
      label: <h4 className="tab-title default-tab">Market</h4>,
      key: "2",
      disabled: false,
      children: <ItemMarket productId={productId} />,
    },
    {
      label: <h4 className="tab-title default-tab">Price Elasticity</h4>,
      key: "3",
      disabled: false,
      children: <ItemPriceElasticity />,
    },
    {
      label: (
        <h4 className="tab-title default-tab">
          Discovery <BadgeCustom text="Beta" className="beta-badge" />
        </h4>
      ),
      key: "4",
      disabled: false,
      children: <>4</>,
    },
  ];

  const headerInfo = [
    {
      value: article,
      key: "SKU",
    },
    {
      value: brand,
      key: "Brand",
    },
    {
      value: store,
      key: "Store",
    },
  ];

  return (
    <div className="item-info__container" ref={drawerRef}>
      <div className="item-info__header-container">
        <div className="item-info__header">
          <span className="item-info__title">
            <a href={externalLink || "/*"} target="_blank">
              {name}
            </a>
          </span>
          <div onClick={() => onClose(false)} className="item-info-close__icon">
            <Close />
          </div>
        </div>
        <div>
          {headerInfo.map(({ value, key }) => {
            return (
              <div className="item-info__header-info" key={key}>
                <span className="item-info__header-info-key">{key}</span>
                <span className="item-info__header-info-value">{value}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <Tabs
          centered={true}
          items={tabOptions.map(({ label, key, children, disabled }, ind) => {
            return {
              disabled: disabled,
              label: label,
              key: ind.toString(),
              children: children,
            };
          })}
        />
      </div>
    </div>
  );
};

export default ItemInfo;
