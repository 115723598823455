import { FC } from "react";
import "./groupsOnCancelNotificationPopup.scss";
import { Divider, Modal } from "antd";
import { Typography } from "antd";
import { useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { ButtonStyled } from "@shared/ui/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { url } from "@shared/utils/url.utils";
import { IQuery, ROUTES } from "@shared/interfaces";
import { Close } from "@shared/ui/assets";

const { Title } = Typography;

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
}

const GroupsOnCancelNotificationPopup: FC<IProps> = ({
  flag,
  onClose,
  onOk,
}: IProps) => {
  const navigate = useNavigate();
  const { clearGroup } = useGroupsStore((state) => state);

  const onClickSaveAsDraft = () => {
    navigate(ROUTES.ADMIN_GROUPS);
    clearGroup();
  };

  return (
    <Modal
      open={flag}
      onOk={() => {}}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose()}
    >
      <div className="groups__form-create">
        <Title level={2} className="groups__form-title">
          Cancel Group Creation
        </Title>
        <Divider />
        <Title level={5} className="groups__form-suptitle">
          This action will permanently delete all changes made to the group
          configurations.
        </Title>
        <div onClick={() => onClose()} className="modal-close__icon">
          <Close />
        </div>
        <Divider />
        <div className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Save as a draft"
              htmlType="button"
              className="w-84 mr-16 reject__btn"
              onClick={onClickSaveAsDraft}
            />

            <ButtonStyled
              text="Cancel group creation"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-210"
              onClick={onOk}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupsOnCancelNotificationPopup;
