import { useEffect, useRef, useState } from "react";
import "./groupItems.scss";
import moment from "moment";
import { Divider, Select } from "antd";
import { FilterItem } from "@features/filterItem";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  SortParams,
  IFilterItem,
  ISortParams,
  IDataTransfer,
} from "@shared/interfaces";
import {
  InputCustom,
  LoaderItemsTable,
  MultipleSelect,
} from "@shared/ui/components";
import { generateTextColumn, Pagination, TableCustom } from "@shared/common";
import { useGroupsStore } from "@entities/groups";
import { IProduct } from "@entities/products";
import { useUserSettingsStore } from "@entities/userSettings";
import {
  BRAND_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  SEARCH_PARAM,
  GROUP_ID_PARAM,
  ORDER_BY_PARAM,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  DEFAULT_OFFSET_PAGINATION,
  DEFAULT_LIMIT_PAGINATION,
} from "@shared/constants";
import { ArrowBorder, Refresh, Search } from "@shared/ui/assets";
import { filteredCompareQueryObj } from "@shared/utils";
import Unlock from "@shared/ui/assets/Unlock";
import Lock from "@shared/ui/assets/Lock";
import { ItemInfo } from "../../ItemInfo";

const allDefault = "All";
//TODO
const filterItems: IFilterItem[] = [
  {
    name: allDefault,
    value: allDefault,
    //TODO
    // element: (
    //   <h4  className="filter__text filter__text-badge">
    //     {filtersGroupAll || ""}
    //   </h4>
    // ),
  },
  {
    name: "New price ready (5)",
    value: "NEW_REPRICE_READY",
    // element: (
    //   <h4  className="filter__text filter__text-badge">
    //     {filters?.groupInRepriceReady || 0}
    //   </h4>
    // ),
  },
];

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  BRAND = "brand",
  STORE = "store",
  STATE = "state",
  SUBCATEGORY = "subcategory",
  LAST_REPRICE = "last_reprice",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
}

const GroupItems = () => {
  const { id } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [widthOfScroll, setWidthOfScroll] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>(allDefault);
  const [currentBrand, setCurrentBrand] = useState<string[]>([]);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [isInfoDrawerShow, setShowInfoDrawer] = useState<boolean>(false);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);
  const recordsCount = useGroupsStore((state) => state.recordsCountInGroup);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const [tableWidth, setTableWidth] = useState<number>(500);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const {
    getProductsInGroup,
    getBrandsFromGroup,
    brands,
    fetchChangeLockProductMode,
    setProductsInGroup,
    isLoading,
    currentGroup,
  } = useGroupsStore((state) => state);
  const { asideCollapsed } = useUserSettingsStore((state) => state);

  const adaptedBrands = brands?.map((brand) => ({
    label: <>{brand.name}</>,
    value: brand.name,
  }));

  const brandsCompare = [...adaptedBrands];

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams;
  }>({
    key: "",
    direction: SortParams.ASC,
  });

  useEffect(() => {
    const selectedBrands = brandsCompare.map((option) => option.value);
    setCurrentBrand(selectedBrands);
  }, [brands]);

  useEffect(() => {
    const chooseActiveFilter = activeFilter === allDefault ? "" : activeFilter;

    if (currentBrand.length) {
      const data = {
        [GROUP_ID_PARAM]: id,
        [BRAND_PARAM]: currentBrand,
        [LIMIT_PARAM]: currentLimit,
        [SEARCH_PARAM]: searchValue,
        [OFFSET_PARAM]: currentOffset,
        [ORDER_BY_PARAM]: sortConfig.key,
        [DIRECTION_PARAM]: sortConfig.direction,
        [FILTER_BY_PARAM]: chooseActiveFilter,
      };

      const filteredData = filteredCompareQueryObj(data) as IDataTransfer;

      setShowInfoDrawer(false);
      getProductsInGroup(filteredData);
    }

    return () => {
      setProductsInGroup([]);
    };
  }, [
    sortConfig,
    currentLimit,
    currentOffset,
    currentBrand,
    searchValue,
    activeFilter,
  ]);

  useEffect(() => {
    getBrandsFromGroup();
  }, [currentGroup.id]);

  useEffect(() => {
    const drawerCloseWidth = 72;
    const drawerOpenWidth = 248;

    if (asideCollapsed) {
      setTableWidth(
        window.innerWidth - drawerOpenWidth - window.innerWidth / 100 - 8 - 26
      );
    } else {
      setTableWidth(window.innerWidth - drawerCloseWidth - 55);
    }
  }, [asideCollapsed, productsInGroup]);

  useEffect(() => {
    checkIfScrollable();
  }, [containerRef?.current, containerRef, tableWidth]);

  const handleClickOnLock = (productId: string, isLocked: boolean) => {
    fetchChangeLockProductMode(productId, isLocked);
  };

  function getRandomTwoDigitDecimal() {
    let number = (Math.random() * (99 - 10) + 10).toFixed(2);
    return parseFloat(number);
  }

  function getRandomTwoDigitDecimalWithSign() {
    let number = (Math.random() * (99 - 10) + 10).toFixed(2);
    let sign = Math.random() < 0.5 ? -1 : 1;
    return parseFloat(number) * sign;
  }

  const viewNewItemInfo = () => {
    const value = getRandomTwoDigitDecimalWithSign();
    const chooseClassBySing = value.toString().includes("-")
      ? "product__new-value--negative"
      : "product__new-value--positive";
    return (
      <div>
        <div className="product__current-value">
          ${getRandomTwoDigitDecimal()}
        </div>
        <div className={`${chooseClassBySing}`}>
          {value > 0 ? "+" : ""}
          {value}%
        </div>
      </div>
    );
  };

  const showItemInfoDrawer = (productId: string) => {
    setShowInfoDrawer(true);
    setActiveProductId(productId);
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const onChangeBrand = (values: string[]) => {
    setCurrentBrand(values);
  };

  const debounced = useDebouncedCallback(async (value) => {
    setSearchValue(value);
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    debounced(value);
  };

  const onChangeFilter = (value: string) => {
    setActiveFilter(value);
  };

  const onOpenDrover = (id: string, e: any) => {
    showItemInfoDrawer(id);
    e.stopPropagation();
  };

  const headDataTable = [
    // {
    //   content: (
    //     <Checkbox
    //       checked={isAllSelected && !!productsInGroup.length}
    //       onChange={(event) => {
    //         selectAllProductsInGroup(event.target.checked);
    //       }}
    //     ></Checkbox>
    //   ),
    //   className: "w-48",
    // },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380 sticky-table__th--left-name",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71 sticky-table__th--left-image",
    },
    {
      content: generateTextColumn({
        text: "Lock",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STATE === sortConfig.key,
        tooltipText: "Lock current price from repricing",
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.STATE,
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-130",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-141",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "New price",
        tooltipText: "New price after repricing",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-133",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "New margin",
        tooltipText: "Expected margin after repricing",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-144",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
    {
      content: generateTextColumn({
        text: "Last reprice",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.LAST_REPRICE === sortConfig.key,
      }),
      className: "table-w-161",

      sortKey: THEAD_SORT_KEYS.LAST_REPRICE,
    },
  ];

  const dataTableBodyCompare = [
    // TODO
    // {
    //   content: ({ isSelected, id }: IProduct) => (
    //     <Checkbox
    //       checked={productsInGroupSelectedIds.some((el) => el === id)}
    //       onChange={(event) => {
    //         selectProductInGroup(id, event?.target.checked);
    //       }}
    //     ></Checkbox>
    //   ),
    // },
    {
      content: ({ id, article, name }: IProduct) => {
        return (
          <div
            className="default-padding-blocks-table custom-height-group-items-name"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <h5 className="product__subtitle ">{article}</h5>
            <h5
              className="group_product__title"
              style={{ width: tableWidth * 0.3 }}
            >
              {name}
            </h5>
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-380 sticky-table__body--left-name  ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ imageUrl, id }: IProduct) => {
        return (
          <div
            className="image-block-table custom-height-group-items"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <img alt="img-url" className="product__img" src={imageUrl} />
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-71 sticky-table__body--left-image ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ state, id }: IProduct) => (
        <div
          className="default-padding-blocks-table"
          onClick={(e) => {
            handleClickOnLock(id, !state);
            e.stopPropagation();
          }}
        >
          {state ? <Lock /> : <Unlock />}
        </div>
      ),
      className: () => "table-w-109 ",
    },
    {
      content: ({ currentPrice, id }: IProduct) => (
        <h5
          className="default-padding-blocks-table ta-right custom-height-group-items"
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
        >
          ${currentPrice}
        </h5>
      ),
      className: () => "table-w-109 ",
    },
    {
      content: ({ currentMargin = "0", id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right default-padding-blocks-table custom-height-group-items"
        >
          {currentMargin}%
        </h5>
      ),
      className: () => "ta-right table-w-109",
    },
    {
      content: ({ currentMargin, state, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right default-padding-blocks-table custom-height-group-items"
        >
          {state ? "-" : viewNewItemInfo()}
        </h5>
      ),
      className: () => "table-w-90",
    },
    {
      content: ({ currentMargin, state, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right custom-height-group-items default-padding-blocks-table"
        >
          {state ? "-" : viewNewItemInfo()}
        </h5>
      ),
      className: () => "table-w-90",
    },
    {
      content: ({ cost, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right custom-height-group-items default-padding-blocks-table"
        >
          ${cost}
        </h5>
      ),
      className: () => "table-w-90",
    },

    {
      content: ({ brand, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          {brand}
        </h5>
      ),
      className: () => "table-w-120",
    },
    {
      content: ({ store, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          {store}
        </h5>
      ),
      className: () => "table-w-95",
    },
    {
      content: ({ created_at, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items default-padding-blocks-table"
        >
          {moment(created_at).format("MMM D, YYYY, HH:mm")}
        </h5>
      ),
      className: () => "table-w-157",
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: item.id === activeProductId,
      className: el?.className(item) || "",
      item: item,
    }));
  });

  const handleScroll = (event: any) => {
    const { scrollLeft } = event.target;
    const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;

    setWidthOfScroll(tableWidth - 460 - maxScrollLeft);

    setScrollLeft(scrollLeft);
  };

  const checkIfScrollable = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsScrollable(scrollWidth > clientWidth);
      const maxScrollLeft = scrollWidth - clientWidth;

      setWidthOfScroll(tableWidth - 450 - maxScrollLeft);
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0 });

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX,
    });
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging || !containerRef.current) return;

    const container = containerRef.current;
    const deltaX = e.clientX - offset.x;
    container.scrollLeft += deltaX;

    setOffset({ x: e.clientX });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div>
      {isInfoDrawerShow && (
        <ItemInfo
          onClose={(flag: boolean) => {
            setShowInfoDrawer(flag);
            setActiveProductId("");
          }}
          productId={activeProductId}
        />
      )}
      {isLoading ? <LoaderItemsTable /> : null}
      <div className="df mb-16">
        <div className="mr-32">
          <InputCustom
            type="text"
            value={inputValue}
            name="group-search"
            placeholder="Search for items"
            onChangeInput={onSearchChange}
            fill="gray-primary-900"
            iconRight={<Search />}
          />
        </div>
        <div className="group-items__filters">
          {filterItems.map((item) => (
            <FilterItem
              item={item}
              key={item.value}
              active={activeFilter}
              onClick={(value) => onChangeFilter(value)}
            />
          ))}
        </div>
        <div>
          <div className={`group-items-select__container`}>
            <h5 className="group-items-select__title">Brand</h5>
            <MultipleSelect
              selectedItems={currentBrand}
              options={brandsCompare}
              onChange={onChangeBrand}
              className={"multiselect_brands"}
            />
          </div>
        </div>
      </div>
      <div
        className={`hidden-scroll ${
          isLoading ? "clear-table-overflow" : "group-items__table"
        }`}
        style={{ width: tableWidth }}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <TableCustom
          headData={headDataTable}
          bodyData={dataTableBody}
          handleSort={handleSort}
        />
        {!currentBrand.length ? (
          <>
            <div className="group-items__no-results">
              No results found. Please select at least one brand to see data.
            </div>
            <Divider />
          </>
        ) : null}
        <div
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseMove={(e) => handleMouseMove(e)}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          className="custom-scrollbar-block"
          style={{ width: tableWidth - 460 }}
        >
          {isScrollable && !isLoading && (
            <div
              className="custom-scrollbar"
              style={{
                left: `${scrollLeft}px`,
                width: `${widthOfScroll}px`,
              }}
            ></div>
          )}
        </div>
      </div>
      {currentBrand.length ? (
        <>
          <div className="pagination__container group-items__pagination">
            <div></div>
            <Pagination
              prevPage={definePrefPage}
              nextPage={defineNextPage}
              onChange={onChangeItemsCount}
              recordsCount={definePageCount()}
              currentLimit={defineCurrentLimit()}
              currentSelectLimit={currentLimit.toString()}
              currentOffset={defineCurrentOffset()}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default GroupItems;
