import { IFormGroup, IValidationDataTransfer } from "@shared/interfaces";
import { Validation } from "@shared/utils/index";

const onGroupFormValid = <T>(formData: IFormGroup) => {
  let errorsState = {};

  const validationName = new Validation<IValidationDataTransfer>({
    field: formData.name,
    title: "Group",
  })
    .isRequired()
    .maxLength(64)
    .getErrors();

  if (validationName.length) {
    errorsState = {
      ...errorsState,
      name: validationName[0],
    };
  }

  return errorsState;
};

export default onGroupFormValid;
