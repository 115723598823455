import { ERROR_STATUS, ERROR_TYPE } from "@shared/interfaces";

export const errorHandler = {
  isResponseHandled: (error: any) => {
    const response = error.response;
    console.log("errorHandler", error.response);
    console.log("STATUS errorHandler", response?.status);
    console.log(error, "error");

    switch (response?.status) {
      case 404:
        return {
          type: ERROR_TYPE.SYSTEM,
          status: ERROR_STATUS.NOT_FOUND_404,
          isHandled: false,
          text: "Network error",
        };

      case 400:
        return {
          type: ERROR_TYPE.HANDLED,
          status: ERROR_STATUS.VALIDATION_400,
          text: response?.data.message,
          isHandled: true,
        };

      case 403:
        return {
          type: ERROR_TYPE.HANDLED,
          status: ERROR_STATUS.ACCESS_DENIED_403,
          text: response?.data.message,
          isHandled: true,
        };

      case 500:
        return {
          type: ERROR_TYPE.SYSTEM,
          status: ERROR_STATUS.SERVER_500,
          text: "Network error",
          isHandled: false,
        };
    }
  },
};
