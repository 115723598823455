import "./SignupSetPassWidget.scss";
import { FC } from "react";
import { SetPassForm } from "@widgets/auth/index";
import { Typography } from "antd";

const { Title } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

const SignupSetPassWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup-setpass__container">
      <Title level={1} className="signup-setpass__title">
        The Relu
      </Title>
      <Title level={1} className="signup-setpass__subtitle">
        Email verified!
      </Title>
      <Title level={4} className="signup-setpass__text">
        Create your password to proceed
      </Title>
      <SetPassForm onContinueClick={onContinueClick} />
    </div>
  );
};

export default SignupSetPassWidget;
