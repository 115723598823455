import React, { FC } from "react";
import "./badgeCustom.scss";

interface IProps {
  text: string;
  className?: string;
}

const BadgeCustom: FC<IProps> = ({ text, className }) => {
  return (
    <div
      className={`badge ${text.length <= 1 ? "round" : "oval"} ${className}`}
    >
      {text}
    </div>
  );
};

export default BadgeCustom;
