import { Arrow } from "@shared/ui/assets";
import moment from "moment";
import { FC } from "react";
import "./customGraphElasticity.scss";
import {
  ComposedChart,
  Line,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  XAxis,
} from "recharts";

const data = [
  { price: 50, sales: 60, revenue: 3000, profit: 500 },
  { price: 55, sales: 80, revenue: 4400, profit: 700 },
  { price: 60, sales: 100, revenue: 6000, profit: 1000 },
  { price: 65, sales: 120, revenue: 7800, profit: 1300 },
  { price: 69.99, sales: 150, revenue: 10500, profit: 2500 },
  { price: 75, sales: 140, revenue: 10500, profit: 2100 },
  { price: 79.99, sales: 130, revenue: 10399, profit: 2000 },
  { price: 85, sales: 100, revenue: 8500, profit: 1500 },
  { price: 90, sales: 60, revenue: 5400, profit: 1000 },
];

interface IProps {
  newPriceValue?: string;
}
const heightGraph = 300;
const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  priceSteps,
}: any) => {
  const filteredPayload = payload.filter(
    (entry: any) => entry.dataKey !== "yourCurrentPrice"
  );

  const maxPayload = Math.max(
    ...filteredPayload.map((entry: any) => entry.value)
  );

  const result = heightGraph - 200 - maxPayload / 100;

  return (
    <div
      style={{
        left: `${coordinate.x}px`,
        top: `${heightGraph + result - 370}px`,
      }}
      className="custom-tooltip-block"
    >
      <div className="tooltip-arrow"></div>
      <p className="tooltip-label">{moment(label).format("MMMM D")}</p>
      {filteredPayload.map((entry: any, index: number) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: entry.color,
            }}
            className="badge-item-color-box"
          ></div>
          <span className="tooltip-item">{`${entry.name}: $${entry.value}`}</span>
        </div>
      ))}
    </div>
  );
};

const CustomGraphElasticity: FC<IProps> = ({ newPriceValue }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <ComposedChart
        width={600}
        height={heightGraph}
        data={data}
        margin={{ top: 30, right: 20, left: 20, bottom: 20 }}
      >
        <CartesianGrid
          horizontal
          vertical={false}
          stroke="#e0e0e0"
          strokeDasharray="3 3"
        />

        {/* <YAxis
          yAxisId="left"
          orientation="left"
          label={{
            value: "Sales (units)",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <YAxis
          stroke="#000"
          yAxisId="right"
          orientation="right"
          x={50}
          label={{
            value: "Performance",
            angle: 90,
            position: "insideRight",
          }}
          tickFormatter={(value) => `$${value / 1000}k`}
        /> */}

        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[0, "auto"]}
          label={{
            value: "Sales (units)",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          // domain={'auto'} // auto [0, 3000]
          label={{
            value: "Performance",
            angle: 90,
            position: "insideRight",
          }}
        />

        <XAxis
          dataKey="price"
          tickFormatter={(value) => `$${Math.round(value)}`}
          label={{
            // value: "Price ($)",
            position: "insideBottom",
            offset: -10,
          }}
        />
        <Tooltip
          content={
            <CustomTooltip priceSteps={data.map((item) => item.price)} />
          }
        />
        <Legend />
        <Bar
          yAxisId="left"
          dataKey="sales"
          fill="#ccc"
          barSize={Math.max(700 / data.length - 10, 15)}
        />

        <Line
          yAxisId="right"
          type="monotone"
          dataKey="revenue"
          stroke="#82ca9d"
          dot={{ r: 5 }}
          strokeWidth={2}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="profit"
          stroke="#8884d8"
          dot={{ r: 5 }}
          strokeWidth={2}
        />
        <ReferenceLine
          yAxisId="right"
          x={69.99}
          stroke="gray"
          strokeDasharray="3 3"
          label={{
            value: "Current price: $69.99",
            position: "top",
            fill: "black",
          }}
        />

        {newPriceValue && (
          <ReferenceLine
            yAxisId="right"
            x={+newPriceValue}
            stroke="gray"
            strokeDasharray="3 3"
            label={{
              value: `New price: $${newPriceValue}`,
              position: "top",
              fill: "black",
            }}
          />
        )}
      </ComposedChart>
    </div>
  );
};

export default CustomGraphElasticity;
