interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const Info = ({ color = "#6C757D", width = 16, height = 17 }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
  >
    <g clipPath="url(#clip0_7873_6843)">
      <path
        d="M8.00065 1.83325C4.32065 1.83325 1.33398 4.81992 1.33398 8.49992C1.33398 12.1799 4.32065 15.1666 8.00065 15.1666C11.6807 15.1666 14.6673 12.1799 14.6673 8.49992C14.6673 4.81992 11.6807 1.83325 8.00065 1.83325ZM8.00065 11.8333C7.63398 11.8333 7.33398 11.5333 7.33398 11.1666V8.49992C7.33398 8.13325 7.63398 7.83325 8.00065 7.83325C8.36732 7.83325 8.66732 8.13325 8.66732 8.49992V11.1666C8.66732 11.5333 8.36732 11.8333 8.00065 11.8333ZM8.66732 6.49992H7.33398V5.16659H8.66732V6.49992Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_7873_6843">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Info;
