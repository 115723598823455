import React, { FC, useEffect, useState } from "react";
import "./ItemOverview.scss";
import {
  AutoRepricingEnum,
  IProductExceptionItem,
  Metric,
  MetricToGrow,
  MetricToMaintain,
  PriceFormulaType,
  PriceRoundingEnum,
  Rate,
  SelectLimitPricing,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import moment from "moment";
import { ArrowBorder, Close, Delete, Edit, Plus } from "@shared/ui/assets";
import { IProduct } from "@entities/products";
import { Divider, Form, Modal, Select } from "antd";
import {
  BadgeCustom,
  ButtonStyled,
  InputWithIcons,
} from "@shared/ui/components";
import { Formik, useFormik } from "formik";
import { Spiner } from "@features/spiner";
import { useDebouncedCallback } from "use-debounce";
import { validationSchema } from "./validationOverview";
import Unlock from "@shared/ui/assets/Unlock";
import Lock from "@shared/ui/assets/Lock";

interface IProps {
  productId: string;
}

const optionsSelect = [
  {
    label: "Current price",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
];

const limitOptions = [
  {
    text: "Min price (optional)",
    key: "min",
  },
  {
    text: "Max price (optional)",
    key: "max",
  },
];

const ItemOverview: FC<IProps> = (props) => {
  const { productId } = props;
  const currentProduct = useGroupsStore((state) => state.productsInGroup).find(
    (product) => product.id === productId
  );
  const productException = currentProduct?.exceptionLimits;
  const { modifyException, removeExceptionById, removeLocalExceptionById } =
    useGroupsStore((state) => state);

  const {
    currentGroup,
    recordsCountInGroup,
    strategy,
    setGroupName,
    setKeyOfActiveStep,
    validationErrors,
    exceptionProducts,
    getExceptions,
  } = useGroupsStore((state) => state);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [isShowSpinnerUpdate, setIsShowSpinnerUpdate] = useState(true);

  const initialValues = productException || {
    pricing: "",
    min: {
      value: "",
    },
    max: {
      value: "",
    },
  };
  const debouncedUpdate = useDebouncedCallback(() => {
    setIsShowSpinnerUpdate(false);
  }, 4000);

  useEffect(() => {
    setIsShowSpinnerUpdate(true);
    debouncedUpdate();
  }, [productId, debouncedUpdate]);

  useEffect(() => {
    setValues(initialValues);
    return () => {
      setValues(initialValues);
    };
  }, [productId, productException]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (currentProduct?.id) {
        modifyException(currentProduct?.id, values);
        onCloseModalException();
      }
    },
  });

  const {
    touched,
    errors,
    setFieldValue,
    isValid,
    values,
    handleBlur,
    setValues,
  } = formik;

  useEffect(() => {
    if (currentGroup.id) {
      getExceptions();
    }
  }, []);

  if (!currentProduct) {
    return null;
  }

  const renderPriceType = () => {
    if (strategy.type === StrategyTypeEnum.ELASTICITY) {
      return "Price Elasticity";
    }

    if (strategy.type === StrategyTypeEnum.COMPETITION) {
      return "Competition";
    }
  };

  const formatElasticityMetric = (metric: string) => {
    if (!metric) {
      return "";
    }

    switch (metric) {
      case MetricToGrow.PROFIT: {
        return "Profit";
      }
      case MetricToGrow.SALES: {
        return "Sales";
      }
      case MetricToGrow.REVENUE: {
        return "Revenue";
      }
    }
  };

  const renderStrategyRule = () => {
    const { strategyRule } = strategy;
    const { pricesFormula, competitors } = strategyRule;

    if (strategy.type === StrategyTypeEnum.ELASTICITY) {
      let text = "";

      if (pricesFormula?.metricToGrow) {
        text += `Grow: ${formatElasticityMetric(pricesFormula?.metricToGrow)}`;
      }

      if (
        pricesFormula?.metricToMaintain &&
        pricesFormula?.metricToMaintain !== MetricToMaintain.NONE
      ) {
        text += `${
          pricesFormula?.metricToGrow ? ", maintain" : "Maintain"
        }: ${formatElasticityMetric(pricesFormula?.metricToMaintain)}`;

        if (pricesFormula?.metricToMaintain === MetricToMaintain.SALES) {
          text += `(units))`;
        }
      }

      return text;
    }

    if (!pricesFormula.type) {
      return "N/A";
    }

    let rate = "";
    let priceFormulaType = "";

    switch (pricesFormula.type) {
      case PriceFormulaType.LOWEST: {
        priceFormulaType = "Lower than";
        break;
      }

      case PriceFormulaType.AVERAGE: {
        priceFormulaType = "Average to";
        break;
      }

      case PriceFormulaType.HIGHEST: {
        priceFormulaType = "Highest than";
        break;
      }
    }

    switch (pricesFormula.rate) {
      case Rate.DOLLAR: {
        rate = "$";
        break;
      }

      case Rate.PERCENTAGE: {
        rate = "%";
        break;
      }
    }

    let text = `${priceFormulaType} ${competitors.length} ${
      competitors.length > 1 ? "Competitors" : "Competitor"
    } `;

    if (pricesFormula.type !== PriceFormulaType.AVERAGE) {
      text += `${pricesFormula.metric === Metric.PLUS ? "+" : "-"} ${
        pricesFormula?.value || "0.00"
      }${rate} `;
    }

    return text;
  };

  const formatPricing = (pricing: any) => {
    if (!pricing) {
      return "";
    }

    switch (pricing) {
      case SelectLimitPricing.CURRENT_PRICE: {
        return "Current price";
      }

      case SelectLimitPricing.MARGIN: {
        return "Margin";
      }

      case SelectLimitPricing.MSRP: {
        return "MSRP";
      }
    }
  };

  const formatMetric = (metric: string) => {
    if (!metric) {
      return "";
    }

    if (metric === Metric.MINUS) {
      return "-";
    }

    return "+";
  };

  const renderPriceLimits = () => {
    const { priceLimits } = strategy;

    const isMinActive =
      priceLimits?.min?.active &&
      priceLimits?.min?.pricing &&
      priceLimits.min?.pricingValue;

    const isMaxActive =
      priceLimits?.max?.active &&
      priceLimits?.max?.pricing &&
      priceLimits.max?.pricingValue;

    let text = "";

    if (isMinActive) {
      text += `Min
            ${formatPricing(priceLimits.min?.pricing)}
            ${
              priceLimits.min.pricing === SelectLimitPricing.MSRP
                ? formatMetric(priceLimits.min?.metric || Metric.MINUS)
                : ""
            }
            ${
              priceLimits.min.pricing === SelectLimitPricing.CURRENT_PRICE
                ? "-"
                : ""
            }
            ${priceLimits.min.pricingValue}%`;
    }

    if (isMaxActive) {
      text += `${isMinActive ? ", max" : "Max"}
            ${formatPricing(priceLimits.max?.pricing)}
            ${
              priceLimits.max.pricing === SelectLimitPricing.MSRP
                ? formatMetric(priceLimits.max?.metric || Metric.MINUS)
                : ""
            }
                 ${
                   priceLimits.max.pricing === SelectLimitPricing.CURRENT_PRICE
                     ? "+"
                     : ""
                 }
            ${priceLimits.max.pricingValue}%`;
    }

    if (text === "") {
      return "N/A";
    }

    return text;
  };

  const renderPriceRounding = () => {
    if (!Object.keys(strategy.priceRounding).length) {
      return "N/A";
    }

    const decimal = strategy.priceRounding.decimal;
    const integer = strategy.priceRounding.integer;
    const metric = strategy.priceRounding.metric;

    let text = "";

    if (decimal?.active) {
      text += `Per decimals`;

      if (decimal?.value && decimal.value !== "") {
        text += ` (${decimal.value}), `;
      } else {
        text += " N/A, ";
      }
    }

    if (integer?.active) {
      text += `Per integer`;

      if (integer?.value && integer.value !== "") {
        text += ` (${integer.value}),  `;
      } else {
        text += " N/A, ";
      }
    }

    if (metric) {
      if (metric === PriceRoundingEnum.ROUND_UP) {
        text += " round up";
      }

      if (metric === PriceRoundingEnum.ROUND_DOWN) {
        text += " round down";
      }

      text += "";
    }

    return text;
  };

  const renderUpdatePolicy = () => {
    let text = "Auto repricing";

    if (
      !strategy.updatePolicy?.value ||
      strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_OFF
    ) {
      text += ` off`;
    }

    if (strategy.updatePolicy?.value === AutoRepricingEnum.AUTO_REPRICING_ON) {
      text += ` on`;
    }

    return text;
  };

  const { created_at, currentPrice, state } = currentProduct;

  const onCloseModalException = () => {
    setIsOpenModal(false);
    setValues(initialValues);
  };

  const onCloseModalRemoveException = () => {
    setIsOpenRemoveModal(false);
  };

  const chooseRenderMode = () => {
    return productException ? null : (
      <div className="add-exception-btn-block">
        <ButtonStyled
          icon={<Plus color="#212529" />}
          text="Add custom limit"
          htmlType="button"
          onClick={() => setIsOpenModal(true)}
        />
      </div>
    );
  };

  const onRemoveException = () => {
    removeExceptionById(productId);
    removeLocalExceptionById(productId);
    setIsOpenRemoveModal(false);
  };

  const compareTextException = () => {
    const minValue = productException?.min?.value;
    const maxValue = productException?.max?.value;
    const chooseSing =
      productException.pricing === SelectLimitPricing.CURRENT_PRICE ? "$" : "";
    const chooseSingMargin =
      productException.pricing === SelectLimitPricing.MARGIN ? "%" : "";

    const isMinActiveText = minValue
      ? `Min price ${chooseSing}${minValue}${chooseSingMargin}`
      : "";
    const isMaxActiveText = maxValue
      ? `Max price ${chooseSing}${maxValue}${chooseSingMargin}`
      : "";
    const isComma = minValue && maxValue ? ", " : "";

    return `${isMinActiveText}${isComma}${isMaxActiveText}`;
  };

  const renderPopupAddException = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          open={isOpenModal}
          centered={true}
          onOk={() => {}}
          closeIcon={false}
          maskClosable={false}
          footer={[]}
          width={452}
        >
          <form
            onSubmit={(e) => {
              formik.handleSubmit();
              e.preventDefault();
            }}
            className="modal__exception"
          >
            <div className="modal__header-exception">
              <div
                onClick={onCloseModalException}
                className="modal-close__icon"
              >
                <Close />
              </div>
              <h2 className="modal__title">Add Сustom Limit</h2>
              <Divider />
            </div>
            <div className="modal__exception-content">
              <div className="modal__exception-select">
                <div className="title-select">Custom price limit</div>
                <Select
                  onChange={(value) => {
                    setValues({
                      pricing: value,
                      max: { value: "" },
                      min: { value: "" },
                    });
                  }}
                  suffixIcon={<ArrowBorder />}
                  value={values.pricing || null}
                  optionFilterProp="label"
                  options={optionsSelect}
                  placeholder={"Select limit"}
                  className={`gray-primary-900  custom-arrow`}
                />
              </div>
              {values.pricing ? (
                <div className="df jc-sb">
                  {limitOptions.map(({ text, key }, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <div className="modal__exception-input ">
                          <div className="title-input">{text}</div>
                          <InputWithIcons
                            wrapperClassName={"input-label__wrapper"}
                            rightIcon={
                              values.pricing ===
                              SelectLimitPricing.CURRENT_PRICE ? (
                                <>$</>
                              ) : (
                                <>%</>
                              )
                            }
                            value={values[key]?.value || ""}
                            onChange={(value: string) => {
                              const pattern = /^\d*$/;

                              const isValid = pattern.test(value);
                              isValid && setFieldValue(`[${key}].value`, value);
                            }}
                          />
                        </div>
                        {ind === 0 && (
                          <div className="modal-divider">
                            <div>&nbsp;</div> <div>-</div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <Divider />
            <div key={1} className="groups__form-content">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={onCloseModalException}
                />
                <ButtonStyled
                  type="primary"
                  text={productException ? "Save" : "Add"}
                  htmlType="submit"
                  fill="gray-primary-900"
                  className="w-84"
                  disabled={!isValid}
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  };

  const renderPopupRemoveException = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          open={isOpenRemoveModal}
          centered={true}
          onOk={() => {}}
          closeIcon={false}
          maskClosable={false}
          footer={[]}
          width={500}
        >
          <div className="modal__exception">
            <div className="modal__header-exception">
              <div
                onClick={onCloseModalRemoveException}
                className="modal-close__icon"
              >
                <Close />
              </div>
              <h2 className="modal__title">Remove Custom Limit</h2>
              <Divider />
            </div>
            <div className="modal__exception-content">
              <div>
                {" "}
                Are you sure you want to remove the custom price limit?
              </div>
              <div>
                This item will follow the main price limit rule after removal.
              </div>
            </div>
            <Divider />
            <div key={1} className="groups__form-content">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={onCloseModalRemoveException}
                />
                <ButtonStyled
                  type="primary"
                  text="Remove"
                  htmlType="submit"
                  fill="red-600"
                  className="w-84"
                  onClick={onRemoveException}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <>
      {renderPopupAddException()}
      {renderPopupRemoveException()}
      <div className="item-overview">
        <section className="item-overview-repricing">
          <div className="df jc-sb mb-16">
            <h3 className="overview-title">Repricing</h3>
            <div>
              <span className="overview-last-reprice">Last reprice </span>
              <span className="overview-last-reprice-date">
                {moment(created_at).format("MMM D, YYYY, HH:mm")}
              </span>
            </div>
          </div>
          <div className="df overview-current-price-block">
            <div className="overview-current-price-item">
              <div className="overview-last-reprice">Current price</div>
              <div className="df">
                <h2 className="overview-current-price">${currentPrice} </h2>
                {state && (
                  <span className="overview-current-price-lock">
                    <Lock color="#6C757D" /> Locked
                  </span>
                )}
              </div>
            </div>
            <div className="overview-current-price-item">
              <div className="overview-last-reprice">New price</div>
              {state ? (
                <h2 className="overview-current-price-na">N/A</h2>
              ) : isShowSpinnerUpdate ? (
                <span className="overview-current-price-update">
                  <Spiner /> Updating. May take a few minutes
                </span>
              ) : (
                <h2 className="overview-new-reprice">
                  $799.99{" "}
                  <BadgeCustom text="+14.00%" className="badge-new-price" />
                </h2>
              )}
            </div>
          </div>
        </section>
        <section className="item-overview-configuration">
          <h3 className="overview-title">Group Configuration</h3>
          <div className="summary__overview">
            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Product Items</h5>
              <h5 className="summary__overview-value available-items">
                {recordsCountInGroup} items from{" "}
                {currentGroup.selectedCategory || "N/A"} category
              </h5>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Strategy Type</h5>
              <div className={`summary__overview-value ${strategy.type}`}>
                <span className="capitalize">{renderPriceType()}</span>
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Strategy Rule</h5>
              <div className="summary__overview-value">
                {renderStrategyRule()}
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Price Limits</h5>
              <div className="summary__overview-value">
                {renderPriceLimits()}
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Price Roundings </h5>
              <div className="summary__overview-value">
                {renderPriceRounding()}
              </div>
            </div>

            <div className="summary__overview-item">
              <h5 className="summary__overview-key">Update Policy</h5>
              <div className="summary__overview-value">
                {renderUpdatePolicy()}
              </div>
            </div>
          </div>
          <Divider />
          <div className="summary__overview-item mt-12">
            <h5 className="summary__overview-key">Custom Price Limit</h5>
            <div className="summary__overview-value df jc-sb ai-c exception-item ">
              {productException ? (
                <span className="mr-16">{compareTextException()}</span>
              ) : null}
              {productException ? (
                <div className="custom-limit-actions">
                  <div
                    onClick={() => setIsOpenModal(true)}
                    className="icon-action"
                  >
                    <Edit color="#6C757D" />
                  </div>
                  <div
                    onClick={() => setIsOpenRemoveModal(true)}
                    className="icon-action"
                  >
                    <Delete color="#6C757D" />
                  </div>
                </div>
              ) : (
                <>N/A</>
              )}
            </div>
          </div>
          {chooseRenderMode()}
        </section>
      </div>
    </>
  );
};

export default ItemOverview;
