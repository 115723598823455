import "./SignupVerifyWidget.scss";
import { VerifyForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { FC } from "react";

const { Title } = Typography;

interface IProps {
  onContinueClick: ({ type }: { type: string }) => void;
}

const SignupVerifyWidget: FC<IProps> = ({ onContinueClick }: IProps) => {
  return (
    <div className="signup-verify__container">
      <Title level={1} className="signup-verify__title">
        The Relu
      </Title>
      <Title level={1} className="signup-verify__subtitle">
        Check your email
      </Title>
      <Title level={4} className="signup-verify__text">
        We sent a verification code to
      </Title>
      <Title
        level={4}
        className="signup-verify__subtext verify__subtext-margin"
      >
        name@company.com
      </Title>
      <VerifyForm onContinueClick={onContinueClick} />
    </div>
  );
};

export default SignupVerifyWidget;
