import { ISccessToken, IUser } from "@entities/user";

export enum ERROR_TYPE {
  SYSTEM = "SYSTEM",
  HANDLED = "HANDLED",
}

export enum ERROR_STATUS {
  VALIDATION_400 = 400,
  UNAUTHORIZED_401 = 401,
  ACCESS_DENIED_403 = 403,
  NOT_FOUND_404 = 404,
  SERVER_500 = 500,
}

export enum SUCCESS_STATUS {
  SUCCESS_200 = 200,
  SUCCESS_204 = 204,
}

export interface IDataLoginResponse {
  user: IUser;
  token: string;
}
