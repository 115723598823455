import { FC } from "react";
import "./notificationsAside.scss";
import { INotification, useUserStore } from "@entities/user"
import { Button, Typography, Divider } from "antd";
import { NotificationItem } from "@features/index";
import { useShallow } from "zustand/react/shallow";
import { ButtonStyled } from "@shared/ui/components";

const { Title } = Typography;

const NotificationsAside: FC = () => {
  const notifications = useUserStore(useShallow((state) => state.notifications));

  const renderNotification = () => {
    if (!notifications.length) {
      return (<Title level={5} className="notification-aside__empty"> No notifications </Title>)
    }

    return notifications.map((notification, index )=> (
      <NotificationItem 
        key={`notif-${index}`}
        notification={notification} 
        isLast={index === notifications.length -1}
      />))
  }

  const renderNotificationLength = () => {

    if (!notifications.length) {
      return "";
    } 

    return `(${notifications.length})`;
  }

  return (
    <div className="notification-aside__container">
      <div className="notification-aside__info">
        <div>
          <Title level={1} className="notification-aside__title">Notifications {renderNotificationLength()}</Title>
          <Title level={5} className="notification-aside__suptitle"> From the last 30 days</Title>
        </div>
        <ButtonStyled
            htmlType="button"
            text="Mark all as read"
            className="disabled"
          />
      </div>
      <Divider />
      <div className="notification-aside__body">
      {renderNotification()}
      </div>
    </div>
  )
}

export default NotificationsAside;