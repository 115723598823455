const GroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clipPath="url(#clip0_7568_3028)">
  <path d="M11.1511 3.39992L7.4311 9.47992C7.0211 10.1399 7.5011 10.9999 8.2811 10.9999H15.7111C16.4911 10.9999 16.9711 10.1399 16.5611 9.47992L12.8511 3.39992C12.4611 2.75992 11.5411 2.75992 11.1511 3.39992Z" fill="#ADB5BD"/>
  <path d="M17.5 22C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13C15.0147 13 13 15.0147 13 17.5C13 19.9853 15.0147 22 17.5 22Z" fill="#ADB5BD"/>
  <path d="M4 21.5H10C10.55 21.5 11 21.05 11 20.5V14.5C11 13.95 10.55 13.5 10 13.5H4C3.45 13.5 3 13.95 3 14.5V20.5C3 21.05 3.45 21.5 4 21.5Z" fill="#ADB5BD"/>
  </g>
  <defs>
  <clipPath id="clip0_7568_3028">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

export default GroupIcon;
