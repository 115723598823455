import React from "react";
import "./loaderItemsTable.scss";
import { Spiner } from "@features/spiner";

const LoaderItemsTable = () => {
  return (
    <div className="loader-block">
      <Spiner />
    </div>
  );
};

export default LoaderItemsTable;
