import { Close, Warning } from "@shared/ui/assets";
import { AlertCustom, ButtonStyled } from "@shared/ui/components";
import { Divider, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import "./groupApplyNewPricesPopup.scss";
import { generateTextColumn, Pagination, TableCustom } from "@shared/common";
import { useGroupsStore } from "@entities/groups";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  GROUP_ID_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import { useParams } from "react-router-dom";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { IProduct } from "@entities/products";
import { filteredCompareQueryObj } from "@shared/utils";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  CURRENT_MARGIN = "currentMargin",
  NEW_MARGIN = "newMargin",
}

const GroupApplyNewPricesPopup: FC<IProps> = ({ flag, onClose, onOk }) => {
  const { id } = useParams();
  const { getProductsInGroup, productsInGroup } = useGroupsStore(
    (state) => state
  );
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams;
  }>({
    key: "",
    direction: SortParams.ASC,
  });
  const recordsCount = useGroupsStore((state) => state.recordsCountInGroup);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);

  useEffect(() => {
    // const chooseActiveFilter = activeFilter === allDefault ? "" : activeFilter;

    const data = {
      [GROUP_ID_PARAM]: id,
      // [BRAND_PARAM]: currentBrand,
      [LIMIT_PARAM]: currentLimit,
      // [SEARCH_PARAM]: searchValue,
      [OFFSET_PARAM]: currentOffset,
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      // [FILTER_BY_PARAM]: chooseActiveFilter,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;
    if (flag) {
      getProductsInGroup(filteredData);
    }
  }, [sortConfig, currentLimit, currentOffset, flag]);

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };
  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  function getRandomTwoDigitDecimal() {
    let number = (Math.random() * (99 - 10) + 10).toFixed(2);
    return parseFloat(number);
  }

  function getRandomTwoDigitDecimalWithSign() {
    let number = (Math.random() * (99 - 10) + 10).toFixed(2);
    let sign = Math.random() < 0.5 ? -1 : 1;
    return parseFloat(number) * sign;
  }

  const viewNewItemInfo = () => {
    const value = getRandomTwoDigitDecimalWithSign();
    const chooseClassBySing = value.toString().includes("-")
      ? "product__new-value--negative"
      : "product__new-value--positive";
    return (
      <div>
        <div className="product__current-value">
          ${getRandomTwoDigitDecimal()}
        </div>
        <div className={`${chooseClassBySing}`}>
          {value > 0 ? "+" : ""}
          {value}%
        </div>
      </div>
    );
  };

  const headDataTable = [
    // {
    //   content: (
    //     <Checkbox
    //       checked={isAllSelected && !!productsInGroup.length}
    //       onChange={(event) => {
    //         selectAllProductsInGroup(event.target.checked);
    //       }}
    //     ></Checkbox>
    //   ),
    //   className: "w-48",
    // },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-539",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71 ",
    },

    {
      content: generateTextColumn({
        text: "Current price",
      }),
      className: "table-w-116",
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-155",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "New price",
        tooltipText: "New price after repricing",
      }),
      className: "table-w-117",
    },
    {
      content: generateTextColumn({
        text: "New margin",
        tooltipText: "Expected margin after repricing",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NEW_MARGIN === sortConfig.key,
      }),
      className: "table-w-155",
      sortKey: THEAD_SORT_KEYS.NEW_MARGIN,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id, article, name }: IProduct) => {
        return (
          <div>
            <h5 className="item__subtitle">{article}</h5>
            <h5 className="item__title">{name}</h5>
          </div>
        );
      },
      className: ({ id }: IProduct) => `table-w-380`,
    },
    {
      content: ({ imageUrl, id }: IProduct) => {
        return (
          <div>
            <img alt="img-url" className="product__img" src={imageUrl} />
          </div>
        );
      },
      className: ({ id }: IProduct) => `table-w-71 custom-padding`,
    },

    {
      content: ({ currentPrice, id }: IProduct) => (
        <h5 className="ta-right ">${currentPrice}</h5>
      ),
      className: () => "table-w-109 ",
    },
    {
      content: ({ currentMargin = "0", id }: IProduct) => (
        <h5 className="ta-right ">{currentMargin}%</h5>
      ),
      className: () => "ta-right table-w-109",
    },
    {
      content: ({ currentMargin, state, id }: IProduct) => (
        <h5 className="ta-right custom-height-group-items">
          {viewNewItemInfo()}
        </h5>
      ),
      className: () => "table-w-90",
    },
    {
      content: ({ currentMargin, state }: IProduct) => (
        <h5 className="ta-right custom-height-group-items">
          {currentMargin + 3}%
        </h5>
      ),
      className: () => "table-w-90",
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      // isSelected: item.id === activeProductId,
      className: el?.className(item) || "",
      item: item,
    }));
  });

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <Modal
        width={1200}
        open={flag}
        onOk={() => {}}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => onClose()}
      >
        <>
          <div className="items-apply-new-prices__wrapper">
            <div onClick={onClose} className="modal-close__icon">
              <Close />
            </div>
            <h3 className="items-apply-new-prices__title">Apply New Prices</h3>
            <h5 className="items-apply-new-prices__subtitle">
              New prices will be applied to the following items
            </h5>
          </div>
          <Divider />
        </>
        <div className="items-apply-new-prices-body">
          <TableCustom
            headData={headDataTable}
            bodyData={dataTableBody}
            handleSort={handleSort}
          />
        </div>
        <div className="pagination__container group-items__pagination">
          <div>
            <AlertCustom
              message={
                <>
                  Applying new prices can take <b>up to 1 hour</b>
                </>
              }
              icon={<Warning color="#6C757D" />}
            />
          </div>
          <Pagination
            prevPage={definePrefPage}
            nextPage={defineNextPage}
            onChange={onChangeItemsCount}
            recordsCount={definePageCount()}
            currentLimit={defineCurrentLimit()}
            currentSelectLimit={currentLimit.toString()}
            currentOffset={defineCurrentOffset()}
          />
        </div>
        <Divider />
        <div className="modal__monitoring-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={() => onClose()}
            />

            <ButtonStyled
              text="Confirm new prices"
              type="primary"
              fill="gray-primary-900"
              htmlType="button"
              className="w-181"
              onClick={() => onOk()}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GroupApplyNewPricesPopup;
