import { FC, useEffect } from "react";
import "./groupsBottomBulkAction.scss";
import { useSearchParams } from "react-router-dom";
import { IGroup, useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { Typography } from "antd";
import { deepObjectClone, getItemWord } from "@shared/utils";
import { ButtonStyled } from "@shared/ui/components";
import { Delete, Edit, Export } from "@shared/ui/assets";
import { Pagination } from "@shared/common";
import { url } from "@shared/utils/url.utils";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  LIMIT_PARAM,
  OFFSET_PARAM,
} from "@shared/constants";

const { Title } = Typography;

const GroupsBulkAction: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const groups = useGroupsStore(useShallow((state) => state.groups));
  const recordsCount = useGroupsStore(
    useShallow((state) => state.recordsCount)
  );
  const selectedItems: IGroup[] = deepObjectClone(groups).filter(
    (item: IGroup) => item.isSelected
  );

  const searchObjectParams = url.getSearchParams(searchParams);
  const currentLimit = searchObjectParams?.limit || DEFAULT_LIMIT_PAGINATION;
  const currentOffset = searchObjectParams?.offset || DEFAULT_OFFSET_PAGINATION;

  const onChangeItemsCount = (value: string) => {
    searchParams.set(LIMIT_PARAM, value);
    searchParams.set(OFFSET_PARAM, "0");

    return setSearchParams(searchParams);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentOffset = () => {
    const correctedOffset = +currentOffset;

    if (!groups.length && correctedOffset === 0) {
      return "0";
    }

    if (correctedOffset < 0) {
      return "0";
    }

    return (correctedOffset + 1).toString();
  };

  const defineCurrentLimit = () => {
    let nextOffset = +currentOffset + +currentLimit;

    if (+nextOffset > recordsCount) {
      return recordsCount.toString();
    }

    return nextOffset.toString();
  };

  const definePrefPage = (offset: string) => {
    let prevOffset = +currentOffset - +currentLimit;

    if (prevOffset < 0) {
      prevOffset = 0;
    }

    searchParams.set(OFFSET_PARAM, prevOffset + "");
    setSearchParams(searchParams);
  };

  const defineNextPage = (offset: string) => {
    let nextOffset = +currentOffset + +currentLimit;

    searchParams.set(OFFSET_PARAM, nextOffset.toString());
    setSearchParams(searchParams);
  };

  return (
    <div className="table__margin groups-bottom-bulk__container jc-sb">
      <div className="df">
        {/* <div className="selected__block mr-24">
          <span className="selected__count">{selectedItems.length}</span>
          <Title level={4} className="selected__text">
            {getItemWord(selectedItems.length)} selected
          </Title>
        </div> */}

        {/* <ButtonStyled
          type="primary"
          text="Export as CSV"
          htmlType="button"
          fill="gray-primary-900"
          className="groups-export__btn mr-24 disabled"
          icon={<Export color="white" />}
          onClick={() => {}}
        /> */}

        {/* <ButtonStyled
          text="Rename group"
          htmlType="button"
          className="groups-export__btn mr-24 disabled"
          icon={<Edit color="#212529"/>}
          onClick={() => {}}
        /> */}

        {/* <ButtonStyled
          text="Delete"
          htmlType="button"
          className="groups-export__btn mr-24 disabled"
          fill="red-primary-300"
          icon={<Delete />}
          onClick={() => {}}
        /> */}
      </div>
      <Pagination
        prevPage={definePrefPage}
        nextPage={defineNextPage}
        onChange={onChangeItemsCount}
        recordsCount={definePageCount()}
        currentLimit={defineCurrentLimit()}
        currentSelectLimit={currentLimit}
        currentOffset={defineCurrentOffset()}
      />
    </div>
  );
};

export default GroupsBulkAction;
