interface IProps {
  color?: string;
}

const Delete = ({ color= '#DC3545' }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M4.00065 12.6667C4.00065 13.4 4.60065 14 5.33398 14H10.6673C11.4007 14 12.0007 13.4 12.0007 12.6667V6C12.0007 5.26667 11.4007 4.66667 10.6673 4.66667H5.33398C4.60065 4.66667 4.00065 5.26667 4.00065 6V12.6667ZM12.0007 2.66667H10.334L9.86065 2.19333C9.74065 2.07333 9.56732 2 9.39398 2H6.60732C6.43398 2 6.26065 2.07333 6.14065 2.19333L5.66732 2.66667H4.00065C3.63398 2.66667 3.33398 2.96667 3.33398 3.33333C3.33398 3.7 3.63398 4 4.00065 4H12.0007C12.3673 4 12.6673 3.7 12.6673 3.33333C12.6673 2.96667 12.3673 2.66667 12.0007 2.66667Z" fill={color}/>
  </svg>
);

export default Delete;