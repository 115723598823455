import { IFormLoginState, IValidationDataTransfer } from "@shared/interfaces";
import { Validation } from "@shared/utils/index";

const onLoginFormValid = <T>(formData: IFormLoginState) => {
  let errorsState = {};

  const validationEmail = new Validation<IValidationDataTransfer>({
    field: formData.email,
    title: "Email",
  })
    .isRequired()
    .isEmail()
    .maxLength(64)
    .getErrors();

  const validationPassword = new Validation<IValidationDataTransfer>({
    field: formData.password,
    title: "Password",
  })
    .isRequired()
    .minLength(8)
    .maxLength(32)
    .getErrors();

  if (validationEmail.length) {
    errorsState = {
      ...errorsState,
      email: validationEmail[0],
    };
  }

  if (validationPassword.length) {
    errorsState = {
      ...errorsState,
      password: validationPassword[0],
    };
  }

  return errorsState;
};

export default onLoginFormValid;
