import React from "react";
import "./inputWithIcons.scss";

interface IProps {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onChange: (str: string) => void;
  value: string;
  classNameInput?: string;
  wrapperClassName?: string;
}

const InputWithIcons = ({
  wrapperClassName,
  leftIcon,
  rightIcon,
  onChange,
  value,
  classNameInput,
  ...props
}: any) => (
  <div className={`input-wrapper ${wrapperClassName}`}>
    {leftIcon && (
      <div className="icon left-icon">
        <span className="left">{leftIcon}</span>
      </div>
    )}
    <input
      value={value}
      className={`"custom-input" ${classNameInput || ""}`}
      {...props}
      onChange={(e) => onChange(e.target.value)}
    />
    {rightIcon && (
      <div className="icon right-icon">
        <span className="right">{rightIcon}</span>
      </div>
    )}
  </div>
);

export default InputWithIcons;
