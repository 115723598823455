import { useEffect, useState } from "react";

const useForm = <T,>(formState: T, callback: () => void) => {
  const [formData, setFormData] = useState<any>(formState);

  useEffect(() => {
    setFormData(formState);
  }, [formState]);

  const onChangeInput = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const resetFormData = () => {
    setFormData({});
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    callback();
  };

  return {
    formData,
    onChangeInput,
    onSubmitForm,
    setFormData,
    resetFormData,
  };
};

export default useForm;
