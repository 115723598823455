import { ApiService, arrayToQueryParams } from "@shared/utils";
import { Period } from "../model/itemInfo.types";
import { url } from "@shared/utils/url.utils";
class ItemInfoService extends ApiService {
  baseURL: string;

  constructor() {
    super();

    this.baseURL = "/api/v1";
  }

  async getItemCompetitors(productId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/products/${productId}/competitors`
    );
  }
  async changeMonitoringStatus(id: number, monitoringStatus: boolean) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/products/competitors/monitoring/${id}`,
      {
        monitoringStatus,
      }
    );
  }
  async getDatesOfGraphCompetitors(
    key: string,
    productId: string,
    competitorsIds: any[]
  ) {
    const competitorsIdsString = arrayToQueryParams(
      competitorsIds,
      "competitorProductId"
    );

    let params = url.generateQueryString(competitorsIdsString);

    return await this.baseServiceInstance.get(
      `${this.baseURL}/competitors/trends?from=${key}&productId=${productId}&${params}`
    );
  }
}

const itemInfoService = new ItemInfoService();

export default itemInfoService;
