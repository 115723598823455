import { IProduct } from "@entities/products";
import { ArrowBorder, Close, Delete } from "@shared/ui/assets";
import { Divider, Modal, Select } from "antd";
import { FC, useEffect, useState } from "react";
import "./itemException.scss";
import {
  AlertTarget,
  CurrentStep,
  IAlert,
  IProductException,
  LimitMSRP,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import {
  ButtonStyled,
  InputWithIcons,
  SwitcherCustom,
} from "@shared/ui/components";
import { cutStringToLimit } from "@shared/utils";

interface IProps {
  product: IProductException;
}

const optionsExceptionSelect = [
  {
    label: "Current price",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
  // {
  //   label: "MSRP",
  //   value: SelectLimitPricing.MSRP,
  // },
];

// const msrpSwitchOptions = [
//   { key: LimitMSRP.PLUS, content: <>+</> },
//   { key: LimitMSRP.MINUS, content: <>-</> },
// ];

const ItemException: FC<IProps> = ({ product }) => {
  const [activeOption, setActiveOption] = useState(
    product?.exceptionLimits?.pricing || ""
  );
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    removeExceptionById,
    getExceptions,
    editException,
    saveExceptionChange,
    validationErrors,
    setValidationError,
    // getWarningInfoToLimits,
    strategy: { priceLimits },
  } = useGroupsStore((state) => state);
  const { getStrategy } = useGroupsStore((state) => state);

  const handleChangeExceptionsSelect = (el: SelectLimitPricing) => {
    editException(product.id, { pricing: el }, "select");
    setActiveOption(el);
    saveExceptionChange(product.id);
  };

  const setActiveClassName = () => {
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return "groups-price-limits__margin-select";
      case SelectLimitPricing.CURRENT_PRICE:
        return "groups-price-limits__current-price-select";
      // case SelectLimitPricing.MSRP:
      //   return "";
    }
  };
  // const msrpClassMinus = () => {
  //   if (activeOption === SelectLimitPricing.MSRP) {
  //     return "msrp-active-minus";
  //   }
  //   return "";
  // };

  // const indexMax = msrpSwitchOptions.findIndex(
  //   (el) => el.key === product?.exceptionLimits?.max?.metric
  // );

  // const indexMin = msrpSwitchOptions.findIndex(
  //   (el) => el.key === product?.exceptionLimits?.min?.metric
  // );

  const setActiveModeToChangeRender = () => {
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Min margin (optional)</div>
              <div className="df jc-c poz-r">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("min", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput={`margin-input-exception ml-8 `}
                  value={product?.exceptionLimits?.min?.value || ""}
                />
                {isErrorInput() && (
                  <div className=" error-validation-message-exception">
                    At least one option should be specified
                  </div>
                )}
              </div>
            </div>
            <div className="groups-price-limits__choose-block-minus">
              <div className="title">&nbsp;</div>
              <div>-</div>
            </div>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Max margin (optional) </div>
              <div className="df jc-c">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("max", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput="margin-input-exception ml-8"
                  value={product?.exceptionLimits?.max?.value || ""}
                />
              </div>
            </div>
          </>
        );
      case SelectLimitPricing.CURRENT_PRICE:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Min price (optional) </div>
              <div className="df poz-r">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("min", value)
                  }
                  rightIcon={<>$</>}
                  classNameInput="currentPrice-input-exception"
                  value={product?.exceptionLimits?.min?.value || ""}
                />
                {isErrorInput() && (
                  <div className=" error-validation-message-exception">
                    At least one option should be specified
                  </div>
                )}
              </div>
            </div>
            <div className="groups-price-limits__choose-block-minus">
              <div className="title">&nbsp;</div>
              <div>-</div>
            </div>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Max price (optional)</div>
              <div className="df">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("max", value)
                  }
                  rightIcon={<>$</>}
                  classNameInput="currentPrice-input-exception"
                  value={product?.exceptionLimits?.max?.value || ""}
                />
              </div>
            </div>
          </>
        );
      // case SelectLimitPricing.MSRP:
      //   return (
      //     <>
      //       <div className="groups-price-limits__choose-block-option">
      //         <div className="title df jc-se ">
      //           <div></div>
      //           <div className="ml-40">Min price (optional)</div>
      //         </div>
      //         <div className="df poz-r">
      //           <SwitcherCustom
      //             activeIndex={indexMin >= 0 ? indexMin : 0}
      //             data={msrpSwitchOptions}
      //             onClick={(value) => handleChangeSwitch("min", value)}
      //           />
      //           <InputWithIcons
      //             wrapperClassName={`${isErrorClassInput()}`}
      //             onChange={(value: string) =>
      //               handleChangeExceptionsInput("min", value)
      //             }
      //             rightIcon={<>%</>}
      //             classNameInput="msrp-input-exception ml-8"
      //             value={product?.exceptionLimits?.min?.value || ""}
      //           />
      //           {isErrorInput() && (
      //             <div className=" error-validation-message-exception-msrp">
      //               At least one option should be specified
      //             </div>
      //           )}
      //         </div>
      //       </div>
      //       <div
      //         className={`groups-price-limits__choose-block-minus ${msrpClassMinus()}`}
      //       >
      //         <div className="title">&nbsp;</div>
      //         <div>-</div>
      //       </div>
      //       <div className="groups-price-limits__choose-block-option">
      //         <div className="title df jc-se ">
      //           <div></div>
      //           <div className="ml-40">Max price (optional)</div>
      //         </div>
      //         <div className="df jc-se">
      //           <SwitcherCustom
      //             activeIndex={indexMax >= 0 ? indexMax : 0}
      //             data={msrpSwitchOptions}
      //             onClick={(value) => handleChangeSwitch("max", value)}
      //           />
      //           <InputWithIcons
      //             wrapperClassName={`${isErrorClassInput()}`}
      //             onChange={(value: string) =>
      //               handleChangeExceptionsInput("max", value)
      //             }
      //             rightIcon={<>%</>}
      //             classNameInput="msrp-input-exception ml-8"
      //             value={product?.exceptionLimits?.max?.value || ""}
      //           />
      //         </div>
      //       </div>
      //     </>
      //   );
    }
  };

  // const handleChangeSwitch = (key: string, value: string) => {
  //   editException(product.id, {
  //     [key]: { ...product?.exceptionLimits[key], metric: value },
  //   });
  // };

  const msrpClass = () => {
    if (activeOption === SelectLimitPricing.MSRP) {
      return "msrp-active";
    }
    return "";
  };

  const handleRemoveEvent = async () => {
    await removeExceptionById(product.id);
    await getExceptions();

    const currentValidationErrors =
      useGroupsStore.getState().validationErrors[CurrentStep.PRICE_LIMITS];

    const copyExceptionProducts = currentValidationErrors?.exceptionProducts;
    if (copyExceptionProducts) {
      delete copyExceptionProducts[product.id];
      if (!Object.keys(copyExceptionProducts).length) {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: null,
          },
        });
      } else {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: copyExceptionProducts,
          },
        });
      }
    }
    getStrategy();
  };

  const handleChangeExceptionsInput = (key: string, value: string) => {
    const isValidValue = /^\d{0,2}(\.\d{0,2})?$/.test(value);
    if (isValidValue) {
      editException(product.id, {
        [key]: { ...product?.exceptionLimits[key], value },
      });
    }
    saveExceptionChange(product.id);
  };

  const isErrorClassSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.pricing) {
      return "error-validation-select";
    }

    return "";
  };

  const isErrorClassInput = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.rangeValidation) {
      return "error-validation-input";
    }
    return "";
  };

  const isErrorInput = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.rangeValidation) {
      return true;
    }

    return false;
  };

  const isErrorSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (!exceptionProducts) {
      return false;
    }

    if (exceptionProducts[product.id]?.pricing) {
      return true;
    }
    return false;
  };

  const onClose = () => setIsOpenModal(false);

  const onOk = () => {
    handleRemoveEvent();
  };

  const renderConfirmModalRemoveException = () => {
    return (
      <Modal
        width={500}
        open={isOpenModal}
        onOk={() => {}}
        footer={null}
        maskClosable={false}
        closeIcon={false}
        centered={true}
        onCancel={() => onClose()}
      >
        <div>
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal-remove-competitor-title">Remove Custom Limit</h2>
          <Divider />
        </div>
        <div className="modal__remove-competitor-content ">
          <div>Are you sure you want to remove the custom price limit?</div>
          <div>
            This item will follow the main price limit rule after removal.
          </div>
        </div>
        <Divider />
        <div className="modal__remove-competitor-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={() => onClose()}
            />
            <ButtonStyled
              text="Remove"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={() => onOk()}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {renderConfirmModalRemoveException()}
      <div className="groups-price-limits__product-for-exception">
        <div className="trash-icon" onClick={() => setIsOpenModal(true)}>
          <Delete color="#6C757D" />
        </div>
        <div className="groups-price-limits__product">
          <div className="groups-price-limits__product-info">
            <div>
              {product.imageUrl && (
                <img
                  alt="img-url"
                  className="product__img"
                  src={product.imageUrl}
                />
              )}
            </div>
            <div className="groups-price-limits__product-name">
              <span className="groups-price-limits__article">
                {product.article}
              </span>
              <span className="groups-price-limits__name">
                {cutStringToLimit(product.name)}
              </span>
            </div>
          </div>

          <div className="groups-price-limits__product-actions ">
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">Cost</div>
              <div className="groups-price-limits__product-item-value">
                {`$${product.cost}`}
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Price
              </div>
              <div className="groups-price-limits__product-item-value">
                {`$${product.currentPrice}`}
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Margin
              </div>
              <div className="groups-price-limits__product-item-value">
                {`${product.currentMargin}%`}
              </div>
            </div>
          </div>
        </div>
        <div className="groups-price-limits__choose-block">
          <div
            className={`groups-price-limits__choose-block-limit ${msrpClass()}`}
          >
            <div className="title">Limit by</div>
            <div
              className={`select-limit-block-single ${setActiveClassName()}`}
            >
              <Select
                onChange={(el: SelectLimitPricing) =>
                  handleChangeExceptionsSelect(el)
                }
                value={activeOption || null}
                options={optionsExceptionSelect}
                suffixIcon={<ArrowBorder color="#212527" />}
                placeholder={"Select limit"}
                defaultValue={null}
                className={`custom-arrow  ${isErrorClassSelect()}`}
              />
              {isErrorSelect() && (
                <div className="error-validation-message mr-16 mt-4">
                  Please select a limit
                </div>
              )}
            </div>
          </div>
          <>{setActiveModeToChangeRender()}</>
        </div>
      </div>
    </div>
  );
};

export default ItemException;
